import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

import { AboutDialogComponent } from '@app/portal/about/about-dialog.component';

@NgModule({
    imports: [CommonModule, MatDialogModule, MatButtonModule],
    declarations: [AboutDialogComponent],
    providers: [],
    exports: [AboutDialogComponent]
})
export class AboutModule {}
