import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export interface ISearchResult {
    routerLink: string;
    uri: string;
    title: string;
    description: string;
}

export interface ISearchType {
    name: string;
    icon: string;
    tooltip: string;
    search: (searchText: string) => Observable<ISearchResult[]>;
}

@Injectable()
export class SearchService {
    private subject = new ReplaySubject<ISearchType>(1000);

    register(searchType: ISearchType) {
        this.subject.next(searchType);
    }

    getSearchTypes(): Observable<ISearchType> {
        return this.subject.asObservable();
    }
}
