import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { XAppInterceptor } from '@app/portal/interceptors/x-app.interceptor';
import { RedirectionModule } from '@app/portal/redirection/redirection.module';
import { ServicesUnavailableInterceptor } from '@app/portal/redirection/services-unavailable/services-unavailable.interceptor';
import { GlobalErrorHandler } from './analytics/global-error.handler';
import { DronaHttpInterceptor } from '@app/portal/dronaHttpInterceptor';

const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ServicesUnavailableInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DronaHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: XAppInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
];

@NgModule({
    declarations: [],
    exports: [RedirectionModule],
    imports: [RedirectionModule],
    providers: [...httpInterceptorProviders, provideHttpClient(withInterceptorsFromDi())]
})
export class CoreModule {
    /* make sure CoreModule is imported only by one NgModule the AppModule */
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}
