import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { eventsFeatureKey, reducer } from '@app/portal/notifications/+state/events/events.reducer';
import { EventsEffects } from '@app/portal/notifications/+state/events/events.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(eventsFeatureKey, reducer), EffectsModule.forFeature([EventsEffects])],
    declarations: [],
    exports: []
})
export class EventModule {}
