import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WebsocketStatusEnum, WebsocketStatusType } from '@shared/websocket-status';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { WebSocketSymbolsActions } from '@app/+shared-state/real-time-data/ws-symbol.actions';
import { from, mergeMap, Observable, of } from 'rxjs';
import { KeycloakService } from '@app/security/keycloak/keycloak.service';
import { WsSymbolService } from '@app/+shared-state/real-time-data/ws-symbol.service';
import { Store } from '@ngrx/store';
import {
    doesSubscriberExist,
    doesSymbolHaveOtherSubscribers,
    isSubscribedToSymbol,
    selectAllSubscriptions,
    selectRealTimeSymbolDataWebSocketStatus,
    selectWebSocketSubscribers
} from '@app/+shared-state/real-time-data/ws-symbol.selectors';
import { _doesSymbolHaveOtherSubscribers } from '@app/+shared-state/real-time-data/ws-symbol.reducer';

function waitForOpenWebSocketState<T>(socketStatusObservable: Observable<WebsocketStatusType>) {
    return (source$: Observable<T>) =>
        source$.pipe(
            mergeMap(source =>
                socketStatusObservable.pipe(
                    filter(status => status === WebsocketStatusEnum.open),
                    take(1),
                    map(() => source)
                )
            )
        );
}

@Injectable()
export class WsSymbolEffects {
    private actions$ = inject(Actions);
    private keycloak = inject(KeycloakService);
    private wsSymbolsService = inject(WsSymbolService);
    private store = inject(Store);

    subscribeToSymbol$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WebSocketSymbolsActions.subscribeToSymbol),
            waitForOpenWebSocketState(this.store.select(selectRealTimeSymbolDataWebSocketStatus)),
            mergeMap(({ subscriberId, symbol }) => {
                return from(this.keycloak.getToken()).pipe(
                    map(token => {
                        if (this.wsSymbolsService.isSocketOpen) {
                            const hasSymbolSubscription = this.store.selectSignal(isSubscribedToSymbol(symbol));
                            if (hasSymbolSubscription()) {
                                this.wsSymbolsService.socket.next({ action: 'subscribe', symbols: [symbol], token });
                                // TODO: handle potential subscription failures.
                                return WebSocketSymbolsActions.subscribeToSymbolSuccess({ symbol, subscriberId });
                            }
                            return WebSocketSymbolsActions.subscribeToSymbolSuccess({
                                symbol,
                                subscriberId
                            });
                        }
                        return WebSocketSymbolsActions.subscribeToSymbolError({
                            symbol,
                            subscriberId,
                            error: 'Websocket connection is not open.'
                        });
                    }),
                    catchError(error => {
                        return of(WebSocketSymbolsActions.subscribeToSymbolError({ symbol, subscriberId, error }));
                    })
                );
            })
        )
    );

    subscribeToSymbols$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WebSocketSymbolsActions.subscribeToSymbols),
            waitForOpenWebSocketState(this.store.select(selectRealTimeSymbolDataWebSocketStatus)),
            mergeMap(({ subscriberId, symbols }) => {
                return from(this.keycloak.getToken()).pipe(
                    map(token => {
                        if (this.wsSymbolsService.isSocketOpen) {
                            const symbolsWithoutSubscription = symbols.filter(symbol => {
                                const hasSymbolSubscription = this.store.selectSignal(isSubscribedToSymbol(symbol));
                                return !hasSymbolSubscription();
                            });

                            if (symbolsWithoutSubscription.length > 0) {
                                this.wsSymbolsService.socket.next({ action: 'subscribe', symbols: symbolsWithoutSubscription, token });
                                // TODO: handle potential subscription failures.
                            }
                            return WebSocketSymbolsActions.subscribeToSymbolsSuccess({ symbols, subscriberId });
                        } else {
                            return WebSocketSymbolsActions.subscribeToSymbolsError({
                                symbols,
                                subscriberId,
                                error: 'Websocket connection is not open.'
                            });
                        }
                    }),
                    catchError(error => {
                        return of(WebSocketSymbolsActions.subscribeToSymbolsError({ symbols, subscriberId, error }));
                    })
                );
            })
        )
    );

    unsubscribeFromSymbol$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WebSocketSymbolsActions.unsubscribeFromSymbol),
            waitForOpenWebSocketState(this.store.select(selectRealTimeSymbolDataWebSocketStatus)),
            mergeMap(({ symbol, subscriberId }) => {
                const _doesSubscriberExist = this.store.selectSignal(doesSubscriberExist(subscriberId));
                const _doesSymbolHaveOtherSubscribers = this.store.selectSignal(doesSymbolHaveOtherSubscribers(symbol, subscriberId));

                if (this.wsSymbolsService.isSocketOpen && _doesSubscriberExist() && !_doesSymbolHaveOtherSubscribers()) {
                    this.wsSymbolsService.socket.next({ action: 'unsubscribe', symbols: [symbol] });
                }

                return of(WebSocketSymbolsActions.unsubscribeFromSymbolSuccess({ symbol, subscriberId }));
            })
        )
    );
    unsubscribeFromSymbols$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WebSocketSymbolsActions.unsubscribeFromSymbols),
            waitForOpenWebSocketState(this.store.select(selectRealTimeSymbolDataWebSocketStatus)),
            mergeMap(({ symbols, subscriberId }) => {
                const _doesSubscriberExist = this.store.selectSignal(doesSubscriberExist(subscriberId));
                const allSubscribers = this.store.selectSignal(selectWebSocketSubscribers)();
                const symbolsSubscriptionsToRemove = symbols.filter(symbol => !_doesSymbolHaveOtherSubscribers(symbol, allSubscribers, subscriberId));

                if (this.wsSymbolsService.isSocketOpen && _doesSubscriberExist && symbolsSubscriptionsToRemove?.length > 0) {
                    this.wsSymbolsService.socket.next({ action: 'unsubscribe', symbols: symbolsSubscriptionsToRemove });
                }
                return of(WebSocketSymbolsActions.unsubscribeFromSymbolsSuccess({ symbols, subscriberId }));
            })
        )
    );

    unsubscribeFromAllSymbols$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(WebSocketSymbolsActions.unsubscribeFromAllSymbols),
                waitForOpenWebSocketState(this.store.select(selectRealTimeSymbolDataWebSocketStatus)),
                tap(() => {
                    const subscriptions = this.store.selectSignal(selectAllSubscriptions)();
                    const symbols = subscriptions.map(subscription => subscription.symbol);
                    this.wsSymbolsService.socket.next({ action: 'unsubscribe', symbols });
                })
            ),
        { dispatch: false }
    );
}
