import { NgModule } from '@angular/core';
import { SwUpdateComponent } from '@app/portal/sw-update/sw-update.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [SwUpdateComponent],
    imports: [CommonModule, MatDialogModule, MatButtonModule],
    exports: [SwUpdateComponent]
})
export class SwUpdateModule {}
