<!-- START: Search | Content Sidebar -->
<mat-sidenav-container class="app__search-wrapper">
    <mat-sidenav-content class="app__search-wrapper_content">
        <!-- START: NAV | Content Sidebar -->
        <mat-sidenav-container class="app__main-content">
            <!-- START: Search panel ||-| -->
            <mat-sidenav
                class="app__main-content_sidenav"
                [ngClass]="{ 'app--main-contant--sidenav--collapsed': collapsedSidenav }"
                mode="side"
                opened
                disableClose
            >
                <mat-sidenav-container class="app__sidebar-inner">
                    <div class="app__sidebar-inner__grid">
                        <div class="app__sidebar-inner_left">
                            <a routerLink="/" matTooltip="Home" matTooltipPosition="right">
                                <div class="app__sidebar-inner_top-logo"></div>
                            </a>
                            <div *ngIf="!(isShell$ | ngrxPush)" [style.text-align]="'center'" [@slideInOutV] data-pendo="apps">
                                <div class="app_sidebar-inner_divider app_sidebar-inner__link"></div>
                                <ng-container *ngFor="let app of getAllApps">
                                    <a
                                        *ngIf="app.data?.isAvailable"
                                        mat-icon-button
                                        class="app_sidebar-inner__link"
                                        [matTooltip]="app.data.name"
                                        matTooltipPosition="right"
                                        [routerLink]="['/' + app.path]"
                                        routerLinkActive="app_sidebar-inner__link__active"
                                        #rla="routerLinkActive"
                                    >
                                        <div
                                            *ngIf="app.data.logoSvg; else noLogo"
                                            class="app-sidebar-inner__link__logo"
                                            style="background-size: cover"
                                            [style.background-image]="
                                                rla.isActive ? (app.data.logoSvgActive | wrapFn: getLogo) : (app.data.logoSvg | wrapFn: getLogo)
                                            "
                                        ></div>
                                        <ng-template #noLogo>
                                            <mat-icon>settings</mat-icon>
                                        </ng-template>
                                    </a>
                                </ng-container>
                            </div>

                            <div class="spacer"></div>

                            <div class="app__notification-center-toggle" matTooltip="Notification center" matTooltipPosition="right">
                                <sphere-notification-center-toggle></sphere-notification-center-toggle>
                            </div>
                            <div class="app__help-center-toggle" matTooltip="Help center" matTooltipPosition="right">
                                <sphere-help-center-button />
                            </div>
                        </div>

                        <div class="app__sidebar-inner_right" [ngClass]="{ 'app--sidebar-inner--right--collapsed': collapsedSidenav }">
                            <sphere-sidebar-nav
                                class="app--sidebar-inner--right--navigation"
                                [header]="sideNavHeader"
                                [collapsedSidenav]="collapsedSidenav"
                            ></sphere-sidebar-nav>

                            <div class="app__sidebar-inner--right--footer">
                                <sphere-ws-status [onlyErrors]="true"></sphere-ws-status>
                                <button
                                    class="app--sidebar-inner--right--collapse-toggle"
                                    mat-icon-button
                                    (click)="toggleSidenavState()"
                                    matTooltipPosition="above"
                                    data-pendo="compact side nav"
                                    [matTooltip]="collapsedSidenav ? 'Expand side navigation' : 'Compact side navigation'"
                                >
                                    <mat-icon data-cy="expand" *ngIf="collapsedSidenav">keyboard_tab</mat-icon>
                                    <mat-icon data-cy="collapse" *ngIf="!collapsedSidenav">first_page</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-sidenav-container>
            </mat-sidenav>
            <!-- END: Search panel ||-| -->

            <!-- START: Main content wrapper -->
            <mat-sidenav-content class="app__main-content_content" [style.margin-left.px]="collapsedSidenav ? 120 : 311">
                <!-- START: Top bar -->
                <div class="app_main-content_topbar">
                    <div class="app_main-content_topbar_left">
                        <sphere-breadcrumbs></sphere-breadcrumbs>
                    </div>
                    <div class="app_main-content_topbar_right">
                        <sphere-greeting></sphere-greeting>
                    </div>
                </div>
                <!-- END: Top bar -->

                <div [@fadeInOut] class="app__router-navigation-spinner" *ngIf="routerNavigationLoading$ | ngrxPush">
                    <sphere-full-page-loading-placeholder></sphere-full-page-loading-placeholder>
                </div>

                <!-- START: Main content / router outlet -->
                <div class="app__router-outlet-wrapper">
                    <router-outlet></router-outlet>
                </div>
                <!-- END: Main content / router outlet -->
            </mat-sidenav-content>
            <!-- START: Main content wrapper -->
        </mat-sidenav-container>
        <!-- END: NAV | Content Sidebar -->
    </mat-sidenav-content>
</mat-sidenav-container>
<!-- END: Search | Content Sidebar -->
