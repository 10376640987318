import { Component } from '@angular/core';
import { KeycloakService } from '@app/security/keycloak/keycloak.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ContextSnackbarService } from '@shared/services/context-snackbar.service';
import { AboutDialogComponent } from '@app/portal/about/about-dialog.component';
import { EnvironmentUrlUtilsService, SphereUrlEnvironmentInfo } from '@shared/utils/environment-url-utils.service';
import { take } from 'rxjs/operators';
import { SettingsFullscreenDialogService } from '@app/portal/settings/settings-fullscreen-dialog/settings-fullscreen-dialog.service';
import { ResetUserPasswordService } from '@shared/reset-user-password-dialog/reset-user-password.service';
import { Principal } from '@app/user-manager/shared/api/authorization/generated';

@Component({
    selector: 'sphere-greeting',
    templateUrl: './greeting.component.html',
    styleUrls: ['./greeting.component.scss']
})
export class GreetingComponent {
    public isEnverusAuthentication = false;
    public environmentInfo: SphereUrlEnvironmentInfo;

    constructor(
        private keycloak: KeycloakService,
        private dialog: MatDialog,
        private contextSnackbar: ContextSnackbarService,
        private settingsDialog: SettingsFullscreenDialogService,
        private resetUserPasswordService: ResetUserPasswordService,
        private environmentUrlHelper: EnvironmentUrlUtilsService
    ) {
        this.isEnverusAuthentication = this.keycloak.currentUser?.authenticationType === Principal.AuthenticationTypeEnum.Enverus;
        this.environmentInfo = environmentUrlHelper.currentEnvironmentInfo;
    }

    public get username(): string {
        return this.keycloak.getPreferredName();
    }

    public logout() {
        this.keycloak.logout();
    }

    public resetPassword(): void {
        this.resetUserPasswordService.openResetPasswordDialog(this.keycloak.currentUser, this.keycloak.currentUser.companyCode);
    }

    public openAbout() {
        const aboutDialog = this.dialog.open(AboutDialogComponent, <MatDialogConfig>{});

        aboutDialog
            .afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                console.debug('[DEBUG] About dialog closed.');
            });
    }

    public openGlobalSettings() {
        this.settingsDialog.openSettings();
    }
}
