@if (isShell()) {
    <div></div>
} @else {
    <div class="navigation__wrapper app--menu" [ngClass]="{ 'SideNav--Collapsed': collapsedSidenav }" [@slideInOutB]>
        <mat-nav-list class="navigation__nav-list" *ngrxLet="state.appRoutes() | wrapFn: getVisibleRoutes; let routes">
            @for (route of routes; track route.path) {
                <a
                    *ngIf="isFeatureEnabled(route)"
                    [ngClass]="{ 'route-item__no-access': !hasAccess(route) }"
                    mat-list-item
                    class="route-item SideNav__MenuItem"
                    [routerLink]="[state.app().path, route.path]"
                    [skipNavigation]="rla.isActive && route.data?.skipSameUrlNavigation"
                    routerLinkActive="SideNav__MenuItem--Active"
                    #rla="routerLinkActive"
                    [matTooltip]="route.data.title"
                    matTooltipPosition="right"
                    [attr.data-cy]="route.data?.cy || 'sidebar-nav-menu-item'"
                    [attr.data-pendo]="route.data.pendoId ? route.data.pendoId : null"
                >
                    <h4 matLine class="text-overflow-ellipsis">
                        {{ route.data.title || (route | wrapFn: navigationService.getRouteDisplayName) }}
                        @if (route.data.beta || route.data.alpha) {
                            <sphere-experimental-tag
                                [featureStage]="route.data.beta ? 'beta' : route.data.alpha ? 'alpha' : undefined"
                            ></sphere-experimental-tag>
                        }
                    </h4>
                    @if (route.children && (route.children | wrapFn: getVisibleRoutes)?.length > 0) {
                        <button class="dense-compact" (click)="toggle($event, $index)" mat-icon-button [style.opacity]="0.5">
                            @if (expandableState[$index]) {
                                <mat-icon>expand_more</mat-icon>
                            } @else {
                                <mat-icon>chevron_left</mat-icon>
                            }
                        </button>
                    }
                </a>
            }
        </mat-nav-list>
    </div>
}
