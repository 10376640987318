import { ComponentRef, Injectable, Injector, OnDestroy } from '@angular/core';
import { NotificationsState, SphereNotification } from '@app/portal/notifications/+state/notifications/notification.reducer';
import { Store } from '@ngrx/store';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { NotificationContainerComponent } from '@app/portal/notifications/notification/notification-container.component';

export class NotificationContainerRef {
    constructor(private overlayRef: OverlayRef) {}

    close(): void {
        this.overlayRef.dispose();
    }
}

export class NotificationCenterRef {
    constructor(private overlayRef: OverlayRef) {}

    close(): void {
        this.overlayRef.dispose();
    }
}

@Injectable({
    providedIn: 'root'
})
export class NotificationsAlertService implements OnDestroy {
    public notificationContainer: NotificationContainerComponent;
    public notificationContainerRef: NotificationContainerRef;

    constructor(private store: Store<NotificationsState>, private overlay: Overlay, private injector: Injector) {}

    ngOnDestroy() {}

    open(notification: SphereNotification) {
        if (!this.notificationContainer) {
            const overlayRef = this.createNotificationContainerOverlay();
            this.notificationContainerRef = new NotificationContainerRef(overlayRef);
            this.notificationContainer = this.attachNotificationContainer(overlayRef, this.notificationContainerRef);
        }
    }

    attachNotificationContainer(overlayRef: OverlayRef, notificationContainerRef: NotificationContainerRef) {
        const injector = this.createInjector(notificationContainerRef);
        const containerPortal = new ComponentPortal(NotificationContainerComponent, null, injector);
        const containerRef: ComponentRef<NotificationContainerComponent> = overlayRef.attach(containerPortal);
        return containerRef.instance;
    }

    createNotificationContainerOverlay() {
        const overlayConfig = this.getNotificationContainerOverlayConfig();
        return this.overlay.create(overlayConfig);
    }

    createInjector(notificationContainerRef: NotificationContainerRef): Injector {
        return Injector.create({
            parent: this.injector,
            providers: [{ provide: NotificationContainerRef, useValue: notificationContainerRef }]
        });
    }

    getNotificationContainerOverlayConfig(): OverlayConfig {
        const positionStrategy = this.overlay.position().global().left('60px').bottom('50px');

        return new OverlayConfig({
            hasBackdrop: false,
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            positionStrategy
        });
    }
}
