import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Route } from '@angular/router';
import { AuthorizationService } from '@app/security/authorization.service';
import { AppRoute, AppRouteData } from '@app/app-routing.module';
import { ExperimentalFeatureService } from '@app/portal/experimental-features/experimental-feature.service';
import { KeycloakService } from '@app/security/keycloak/keycloak.service';
import { SpherePluginPermissionService } from '@app/security/sphere-plugin-permission.service';
import WorkspaceEnrichedAppRoute from '@app/workspaces/WorkspaceEnrichedAppRoute';
import { patchState, signalState } from '@ngrx/signals';
import { explicitEffect } from 'ngxtension/explicit-effect';
import { NavigationService } from '../navigation.service';

export type SidebarNavState = {
    app: Route;
    appRoutes: Route[];
};

@Component({
    selector: 'sphere-sidebar-nav',
    templateUrl: './sidebar-nav.component.html',
    styleUrls: ['./sidebar-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)', opacity: 0, position: 'absolute', width: '100%' }),
                animate('180ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ transform: 'translateX(0%)', opacity: 1 }))
            ]),
            transition(':leave', [animate('200ms cubic-bezier(0.4, 0.0, 0.6, 1)', style({ transform: 'translateX(-100%)', opacity: 0 }))])
        ]),
        trigger('slideInOutB', [
            transition(':enter', [
                style({ transform: 'translateX(100%)', opacity: 0, position: 'absolute', width: '100%' }),
                animate('180ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ transform: 'translateX(0%)', opacity: 1 }))
            ]),
            transition(':leave', [animate('200ms cubic-bezier(0.4, 0.0, 0.6, 1)', style({ transform: 'translateX(100%)', opacity: 0 }))])
        ]),
        trigger('fadeInOut2', [
            transition(':enter', [style({ opacity: 0 }), animate('180ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 1 }))]),
            transition(':leave', [animate('200ms cubic-bezier(0.4, 0.0, 0.6, 1)', style({ opacity: 0 }))])
        ])
    ],
    standalone: false
})
export class SidebarNavComponent {
    private readonly authorization = inject(AuthorizationService);

    @Input() public collapsedSidenav: boolean;

    public readonly state = signalState<SidebarNavState>({
        app: null,
        appRoutes: []
    });

    expandableState: boolean[] = [];

    public apps = this.authorization.getAccessibleSphereApps([WorkspaceEnrichedAppRoute, ...this.navigationService.apps]);
    public currentAppData = computed(() => {
        if (this.state.app()) {
            return this.state.app.data() as AppRouteData;
        }
        return null;
    });
    public currentAppName = computed(() => {
        if (this.currentAppData()) {
            return this.currentAppData().name;
        }
        return null;
    });

    private currentApp = this.navigationService.currentApp;
    public isShell = this.navigationService.isShell;

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        public navigationService: NavigationService,
        private sanitizer: DomSanitizer,
        private keycloak: KeycloakService,
        private experimentalFeatures: ExperimentalFeatureService,
        private sphereApps: SpherePluginPermissionService
    ) {
        patchState(this.state, { appRoutes: [] });

        explicitEffect([this.currentApp], ([currentApp]) => {
            patchState(this.state, {
                app: currentApp,
                appRoutes: this.navigationService.getRoutes(currentApp)
            });
        });
    }

    /**
     * Returns the list of visible routes.
     *
     */
    getVisibleRoutes = (routes: Route[]): Route[] => {
        return (
            routes &&
            routes
                .filter(route => !!route.data && !route.data['navHidden'])
                .filter(route => !route.data['superAdminRoute'] || this.keycloak.isSuperAdmin())
                .filter(route => (!!route.data['alpha'] ? this.experimentalFeatures.hasAlphaFeatureAccess : true))
                .filter(route => (!!route.data['beta'] ? this.experimentalFeatures.hasBetaFeatureAccess : true))
        );
    };

    getLogo = (logoUri: string): SafeStyle => {
        return this.sanitizer.bypassSecurityTrustStyle(`url("${logoUri}")`);
    };

    toggle(event: Event, index: number) {
        if (!this.collapsedSidenav) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.expandableState[index] = !this.expandableState[index];
        console.debug(`[DEBUG][SIDE-NAV] Expand state for INDEX [${index}]: ${this.expandableState[index]}`);
    }

    hasAccess(app: AppRoute) {
        return this.sphereApps.hasAppPermission(app);
    }

    isFeatureEnabled(app: AppRoute) {
        return this.sphereApps.isFeatureEnabled(app);
    }
}
