import { animate, AnimationTriggerMetadata, query, stagger, style, transition, trigger } from '@angular/animations';

export const slideInOutVerticalAnimation: AnimationTriggerMetadata = trigger('slideInOutV', [
    transition(':enter', [
        query('.app_sidebar-inner__link', [
            style({ transform: 'translateY(100%)', opacity: 0 }),
            stagger('70ms', [animate('180ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ transform: 'translateY(0%)', opacity: 1 }))])
        ])
    ]),
    transition(':leave', [
        query('.app_sidebar-inner__link', [
            stagger('-70ms', [animate('200ms cubic-bezier(0.4, 0.0, 0.6, 1)', style({ transform: 'translateY(100%)', opacity: 0 }))])
        ])
    ])
]);
