import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { CookieNoticeComponent } from '@app/portal/cookie-notice/cookie-notice.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [CommonModule, MatBottomSheetModule, MatButtonModule],
    declarations: [CookieNoticeComponent],
    exports: [CookieNoticeComponent]
})
export class CookieNoticeModule {}
