import { Injectable } from '@angular/core';
import { SphereNotificationContext, SphereNotificationContextEnum } from '@app/portal/notifications/+state/notifications/notification.reducer';

@Injectable({
    providedIn: 'root'
})
export class NotificationHelperService {
    getContextIcon(context: SphereNotificationContext): string {
        switch (context) {
            case 'error':
                return 'error';
            case 'success':
                return 'check_circle';
            case 'partial-success':
                return 'flaky';
            case 'warning':
                return 'warning';
            case 'in-progress':
                return 'autorenew';
            case 'info':
            default:
                return 'info';
        }
    }

    getContextClass(context: SphereNotificationContext): string {
        switch (context) {
            case 'error':
                return 'notification__icon--error';
            case 'success':
            case 'partial-success':
                return 'notification__icon--success';
            case 'warning':
                return 'notification__icon--warning';
            case 'in-progress':
                return 'notification__icon--in-progress rotating-loop';
            case 'info':
            default:
                return 'notification__icon--info';
        }
    }

    isInProgress(context: SphereNotificationContext): boolean {
        return context === SphereNotificationContextEnum.InProgress;
    }
}
