import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PreferredNameOption {
    key: string;
    value: string;
}

@Component({
    selector: 'sphere-greeting-dialog',
    templateUrl: './greeting-dialog.component.html'
})
export class GreetingDialogComponent {
    constructor(private dialogRef: MatDialogRef<GreetingDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { options: PreferredNameOption[] }) {}

    setPreferrence(option: PreferredNameOption) {
        this.dialogRef.close(option);
    }
}
