import { CurveBuildWebSocketEventData, WebSocketEventEntityType, WebSocketEventOperation } from '@app/portal/notifications/ws-event.service';
import { GlobalNotificationHandlerDefinition } from '@app/portal/notifications/global-handlers/global-handler';

export const curveCreatedHandler: GlobalNotificationHandlerDefinition<CurveBuildWebSocketEventData> = {
    entity: WebSocketEventEntityType.curve,
    operation: WebSocketEventOperation.curveCreated,
    isCustomNotification: true,
    notificationBuilder: event => {
        const { category, code, name, ondate, status } = event.data;
        const profile = event.data.profile ?? 'CURVE';
        const version = event.data.version ?? 'FINAL';
        const context = status === 'Success' ? 'success' : 'warning';
        const title = status === 'Success' ? 'Build successful' : 'Built with warnings';
        return {
            id: event.id,
            title,
            content: `${name} build has finished for ${ondate} with status "${status}"`,
            app: 'curvebuilder',
            url: `/curve-builder/curve-viewer/${category}/${code}/${profile}/${ondate}/${version}`,
            context
        };
    }
};

export const curveAllHandler: GlobalNotificationHandlerDefinition<CurveBuildWebSocketEventData> = {
    entity: WebSocketEventEntityType.curve,
    operation: WebSocketEventOperation.all,
    isCustomNotification: true,
    notificationBuilder: event => {
        const { category, code, name, ondate } = event.data;
        return {
            id: event.id,
            title: 'Build failed',
            content: `${name} build failed for ${ondate}.`,
            app: 'curvebuilder',
            context: 'error'
        };
    }
};
