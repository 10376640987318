import { DestroyRef, inject, Injectable, NgZone } from '@angular/core';
import { KeycloakService } from '@app/security/keycloak/keycloak.service';
import { AppConfigService } from '@app/service/app-config.service';
import { WebsocketStatusEnum } from '@shared/websocket-status';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { WebSocketSymbolsSubscription } from '@app/+shared-state/real-time-data/ws-symbol.reducer';
import { WebSocketSymbolsActions } from './ws-symbol.actions';
import { Store } from '@ngrx/store';
import { EMPTY, from, fromEvent, mergeMap, of, retry, switchMap, take, timer } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { createEffect } from 'ngxtension/create-effect';
import { finalize, tap } from 'rxjs/operators';
import { parseISO } from '@app/shared/utils/parseISO';

const PROTOCOL_NAME = 'sphere.tr.enverus.com' as const;

export type WebSocketMessage = {
    symbol: string;
    fields: Array<{ [key: string]: number }>;
    index: string;
};

type WebSocketStatusMessage = {
    status: number;
    message: string;
};

@Injectable({
    providedIn: 'root'
})
export class WsSymbolService {
    private socket$: WebSocketSubject<any>;
    private destroyRef = inject(DestroyRef);
    private store = inject(Store);
    private ngZone = inject(NgZone);
    private keycloak = inject(KeycloakService);
    private appConfig = inject(AppConfigService);

    public get socket() {
        return this.socket$;
    }

    init() {
        from(this.keycloak.getToken())
            .pipe(
                take(1),
                tap(token => {
                    this.socket$ = this.buildWsWrapper(this.appConfig.realtimeWsServerUrl, token);
                    this.listenForMessages();
                })
            )
            .subscribe();
    }

    /**
     * Returns true if the socket is not initialized or closed.
     */
    public get isSocketOpen() {
        return this.socket && !this.socket?.closed;
    }

    public set socket(wsInstance: WebSocketSubject<any>) {
        this.socket$ = wsInstance;
    }

    constructor() {
        this.ngZone.runOutsideAngular(() => {
            fromEvent(window, 'beforeunload')
                .pipe(
                    takeUntilDestroyed(this.destroyRef),
                    tap(() => this.store.dispatch(WebSocketSymbolsActions.unsubscribeFromAllSymbols()))
                )
                .subscribe();
        });
    }

    /**
     * Constructs the WebSocketSubjectConfig object, with open and close observers
     * to handle connection status.
     */
    buildWsWrapper = (url: string, token: string) => {
        this.store.dispatch(WebSocketSymbolsActions.updateWebsocketStatus({ websocketStatus: WebsocketStatusEnum.connecting }));
        return webSocket<WebSocketSymbolsSubscription>({
            url,
            protocol: [PROTOCOL_NAME, token],
            deserializer: ({ data: jsonStringData }) => {
                try {
                    const data = JSON.parse(jsonStringData);

                    // First message after connection, always status e.g. { status: 200, message: 'OK' }
                    if (this.isStatusMessage(data)) {
                        this.handleStatusMessage(data);
                        return;
                    } else if (this.isWebSocketMessage(data)) {
                        return data;
                    }
                } catch (e) {
                    console.error('Error parsing incoming message:', e);
                    return null;
                }
            },
            openObserver: {
                next: () => {
                    this.store.dispatch(WebSocketSymbolsActions.updateWebsocketStatus({ websocketStatus: WebsocketStatusEnum.open }));
                    console.debug('[Ws Symbols]: open');
                }
            },
            closeObserver: {
                next: () => {
                    this.store.dispatch(WebSocketSymbolsActions.updateWebsocketStatus({ websocketStatus: WebsocketStatusEnum.closed }));
                    console.debug('[Ws Symbols]: closed');
                }
            },
            closingObserver: {
                next: () => {
                    this.store.dispatch(WebSocketSymbolsActions.updateWebsocketStatus({ websocketStatus: WebsocketStatusEnum.closing }));
                    console.debug('[Ws Symbols]: closing');
                }
            }
        });
    };

    public retryStrategy =
        ({ scalingDuration = 500, maxDuration = 30000 }: { scalingDuration?: number; maxDuration?: number } = {}) =>
        (_error: any, _retryCount: number) => {
            return of({ retryCount: _retryCount }).pipe(
                mergeMap(({ retryCount }) => {
                    const retryAttempt = retryCount + 1;
                    if (window.navigator.onLine) {
                        // retry after 0.5s, 1s, 1.5s, etc till 30s...
                        const duration = Math.min(retryAttempt * scalingDuration, maxDuration);
                        return timer(duration);
                    } else {
                        return fromEvent(window, 'online').pipe(take(1));
                    }
                }),
                finalize(() => console.debug('[Websocket] Retry Done!'))
            );
        };

    public listenForMessages = createEffect<void>(trigger$ =>
        trigger$.pipe(
            switchMap(() => {
                if (!this.socket$) {
                    return EMPTY;
                }
                return this.socket$.pipe(
                    takeUntilDestroyed(this.destroyRef),
                    retry({ delay: this.retryStrategy() }),
                    tapResponse({
                        next: (message: WebSocketMessage) => {
                            if (message) {
                                this.store.dispatch(
                                    WebSocketSymbolsActions.updateRealTimeFieldsForSymbol({
                                        symbol: message.symbol,
                                        fields: message.fields,
                                        index: parseISO(message.index)
                                    })
                                );
                            }
                        },
                        error: err => console.error('WebSocket error:', err),
                        complete: () => console.debug('WebSocket connection closed')
                    })
                );
            })
        )
    );

    isWebSocketMessage(data: any): data is WebSocketMessage {
        return data && typeof data.symbol === 'string' && Array.isArray(data.fields);
    }

    isStatusMessage(data: any): data is WebSocketStatusMessage {
        return data && typeof data.status === 'number' && typeof data.message === 'string';
    }

    handleStatusMessage(data: WebSocketStatusMessage): void {
        if (data.status === 200) {
            console.debug(`[Ws Symbols]: Status: ${data.status}, Message: ${data.message}`);
        } else if (data.status >= 400) {
            throw new Error(`[Ws Symbols]: Status: ${data.status}, Message: ${data.message}`);
        }
    }
}
