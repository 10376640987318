import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HelpCenterButtonComponent } from '@app/portal/help-center-button/help-center-button.component';

@NgModule({
    imports: [MatIconModule, MatButtonModule, HelpCenterButtonComponent],
    declarations: [],
    exports: [HelpCenterButtonComponent]
})
export class HelpCenterButtonModule {}
