import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';
import { Route } from '@angular/router';
import { AppRouteData } from '@app/app-routing.module';
import { KeycloakService, Permission } from '@app/security/keycloak/keycloak.service';
import { SpherePluginPermissionService } from '@app/security/sphere-plugin-permission.service';

@Component({
    selector: 'sphere-app-card',
    templateUrl: './app-card.component.html',
    styleUrls: ['./app-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppCardComponent {
    app = input<Route>();
    disabled = computed(() => {
        return !this.sphereApps.hasAppPermission(this.app());
    });

    public cardHover: boolean;

    constructor(
        private sanitizer: DomSanitizer,
        private sphereApps: SpherePluginPermissionService
    ) {}

    sanitizeHTML = (html: string): SafeHtml => {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    };

    sanitizeCSS = (app: Route): SafeStyle => {
        return this.sanitizer.bypassSecurityTrustStyle(`url("${app.data['logoSvg']}")`);
    };
}
