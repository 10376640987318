import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { KeycloakService, ExcludedUrlRegex } from './keycloak.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class KeycloakTokenInterceptor implements HttpInterceptor {
    constructor(private keycloak: KeycloakService) {}

    private isUrlExcluded({ method, url }: HttpRequest<any>, { urlPattern, httpMethods }: ExcludedUrlRegex): boolean {
        const httpTest = httpMethods.length === 0 || httpMethods.join().indexOf(method.toUpperCase()) > -1;
        const urlTest = urlPattern.test(url);
        return httpTest && urlTest;
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.keycloak.enableBearerInterceptor) {
            return next.handle(request);
        }

        const pass: boolean = this.keycloak.excludedUrls.findIndex(url => this.isUrlExcluded(request, url)) > -1;

        if (pass) {
            return next.handle(request);
        }

        return this.keycloak.addTokenToHeader(request.headers).pipe(
            mergeMap(newHeaders => {
                const keycloakRequest = request.clone({ headers: newHeaders });
                return next.handle(keycloakRequest);
            })
        );
    }
}
