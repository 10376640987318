import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AppConfigService } from '@app/service/app-config.service';
import * as localforage from 'localforage';

enum CookieNoticeData {
    key = 'privacy-policy',
    value = 'accepted'
}

@Component({
    selector: 'sphere-cookie-notice',
    templateUrl: './cookie-notice.component.html',
    styleUrls: ['./cookie-notice.component.scss'],
    standalone: false
})
export class CookieNoticeComponent {
    public privacyPolicyUrl: string;

    constructor(
        private bottomSheetRef: MatBottomSheetRef<CookieNoticeComponent>,
        private appConfigService: AppConfigService
    ) {
        this.privacyPolicyUrl = this.appConfigService.getPrivacyPolicyUrl();
    }

    accept(): void {
        localforage
            .setItem(CookieNoticeData.key, CookieNoticeData.value)
            .then(_ => {
                this.bottomSheetRef.dismiss();
            })
            .catch(err => {
                console.debug(`localForage error ${err}`);
            });
    }
}
