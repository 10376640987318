import { Injectable } from '@angular/core';
import { ErrorEventsActions, WsEventsActions } from '@app/portal/notifications/+state/events/events.actions';
import { SphereWSEvent } from '@app/portal/notifications/+state/events/events.reducer';
import { UINotificationActions } from '@app/portal/notifications/+state/notifications/notification.actions';
import {
    NotificationsState,
    selectAllNotifications,
    selectNotificationCenter,
    SphereNotification
} from '@app/portal/notifications/+state/notifications/notification.reducer';
import { EventHandlersService } from '@app/portal/notifications/event-handlers.service';
import { NotificationsAlertService } from '@app/portal/notifications/notification-alert.service';
import { SphereNotificationUserSettingKey } from '@app/portal/settings/+state/settings.models';
import { selectNotificationUserSettings } from '@app/portal/settings/+state/settings.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class NotificationEffects {
    private readonly dismissNotificationTime$ = this.store
        .select(selectNotificationUserSettings)
        .pipe(map(settings => settings[SphereNotificationUserSettingKey.autoDismissTime]));

    createNotificationFromWSEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WsEventsActions.createEvent),
            filter(props => this.isCreateNotificationWSEvent(props.event)),
            map(props => {
                const notification = {
                    ...this.eventHandlers.buildNotification(props.event),
                    timestamp: new Date(),
                    visible: true,
                    new: true,
                    read: false
                };
                return UINotificationActions.createNotification({ notification: notification as SphereNotification });
            })
        )
    );

    updateNotificationFromWSEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WsEventsActions.createEvent),
            filter(props => this.isUpdateNotificationWSEvent(props.event)),
            withLatestFrom(this.store.select(selectAllNotifications)),
            map(([props, notifications]) => {
                return {
                    props,
                    previousNotification: notifications.find(notification => notification.id === props.event['correlation-id'])
                };
            }),
            filter(({ props, previousNotification }) => {
                return !!previousNotification;
            }),
            map(({ props, previousNotification: { autoDismiss } }) => {
                const notification = {
                    ...this.eventHandlers.buildNotification({
                        ...props.event
                    }),
                    timestamp: new Date(),
                    visible: true,
                    new: true,
                    read: false,
                    autoDismiss
                };
                return UINotificationActions.updateNotification({ notification: notification as SphereNotification });
            })
        )
    );

    createNotificationFromErrorEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ErrorEventsActions.createErrorEvent),
            map(props => {
                const timestamp = new Date();
                const notification: SphereNotification = {
                    id: timestamp.toISOString(),
                    title: props.error.title || 'Error',
                    content: props.error.message,
                    timestamp,
                    visible: true,
                    new: true,
                    read: false,
                    context: 'error'
                };
                return UINotificationActions.createNotification({ notification });
            })
        )
    );

    createNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UINotificationActions.createNotification),
            withLatestFrom(
                this.store.select(selectNotificationUserSettings).pipe(map(settings => settings[SphereNotificationUserSettingKey.autoDismissTime]))
            ),
            map(([props, autoDismiss]) => {
                return UINotificationActions.displayNotification({ notification: { ...props.notification, autoDismiss } });
            })
        )
    );

    displayNotification$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UINotificationActions.displayNotification),
                withLatestFrom(this.store.select(selectNotificationCenter)),
                tap(([props, notificationCenter]) => {
                    if (!notificationCenter.open && !notificationCenter.dnd) {
                        this.notifications.open(props.notification);
                    }
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private store: Store<NotificationsState>,
        private notifications: NotificationsAlertService,
        private eventHandlers: EventHandlersService
    ) {}

    private isCreateNotificationWSEvent(event: SphereWSEvent) {
        if (!event || this.eventHandlers.isCorrelationOnlyEvent(event['entity-type'], event.operation)) {
            return false;
        }
        return (
            this.eventHandlers.isSphereNotificationEvent(event) || this.eventHandlers.isCustomNotificationEvent(event['entity-type'], event.operation)
        );
    }

    private isUpdateNotificationWSEvent(event: SphereWSEvent) {
        if (!event) {
            return false;
        }
        return (
            this.eventHandlers.isCustomNotificationEvent(event['entity-type'], event.operation) &&
            this.eventHandlers.isCorrelationOnlyEvent(event['entity-type'], event.operation)
        );
    }
}
