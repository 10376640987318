<div class="about-dialog__container">
    <div class="about-dialog__header">
        <img class="about-dialog__header-logo" src="../../../assets/logos/enverus_sphere_TEMP.png" alt="Enverus Sphere" />
        <h4 class="about-dialog__header-subtitle">{{ state()?.platformVersion || 'Could not load version information' }}</h4>
    </div>
    @if (state()) {
        <mat-dialog-content>
            <div class="about-dialog__build-information">
                <p data-cy="trading-and-risk-platform">
                    <strong>Sphere Platform {{ state().platformVersion }}</strong>
                </p>
                <p data-cy="build-information">Build {{ state().appVersion }}.{{ state().build }}, built on {{ state().buildDate | date }}</p>
            </div>

            <div>
                <p class="about-dialog__enverus-line">CREATING THE FUTURE OF ENERGY TOGETHER</p>
                <p class="about-dialog__copyright">&copy; {{ currentYear }} Enverus</p>
            </div>
        </mat-dialog-content>
    }
</div>
