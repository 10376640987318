import { Component, OnInit } from '@angular/core';
import {
    NotificationsState,
    selectAllNotifications,
    selectNotificationCenter,
    SphereNotification
} from '@app/portal/notifications/+state/notifications/notification.reducer';
import { createSelector, Store } from '@ngrx/store';
import { NotificationCenterActions, UINotificationActions } from '@app/portal/notifications/+state/notifications/notification.actions';
import { NotificationCenterService } from '@app/portal/notifications/notification-center/notification-center.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { selectNotificationUserSettings } from '@app/portal/settings/+state/settings.selectors';
import { updateSetting } from '@app/portal/settings/+state/settings.actions';
import {
    NotificationUserSettings,
    sphereNotificationsUserSettingsGroup,
    SphereNotificationUserSettingKey
} from '@app/portal/settings/+state/settings.models';
import { Router } from '@angular/router';

const selectFilteredNotifications = createSelector(
    selectAllNotifications,
    selectNotificationCenter,
    selectNotificationUserSettings,
    (notifications, notificationCenter, settings) => {
        let filtered = notifications;
        if (notificationCenter.errorsOnly) {
            filtered = filtered.filter(n => n.context === 'error');
        }
        if (settings[SphereNotificationUserSettingKey.showUnreadOnly]) {
            filtered = filtered.filter(n => !n.read);
        }
        return filtered;
    }
);

@Component({
    selector: 'sphere-notification-center',
    templateUrl: './notification-center.component.html',
    styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit {
    public notificationCenter$ = this.store.select(selectNotificationCenter);
    public notifications$ = this.store.select(selectFilteredNotifications);
    public notificationSettings$: Observable<NotificationUserSettings> = this.store.select(selectNotificationUserSettings);

    constructor(private store: Store<NotificationsState>, private notificationCenter: NotificationCenterService, private router: Router) {}

    ngOnInit() {
        this.store.dispatch(UINotificationActions.markAllNotificationsAsNotNew());
    }

    notificationClicked(notification: SphereNotification) {
        if (notification.url) {
            this.notificationCenter.closeNotificationCenter();
            this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => this.router.navigate(notification.url.split('/'), { queryParamsHandling: 'merge', replaceUrl: true }));
        } else {
            // TODO: Expand notification to display error details.
        }
        this.store.dispatch(UINotificationActions.updateNotification({ notification: { ...notification, read: true } }));
    }

    notificationReadStatusChange(notification: SphereNotification, readStatus: boolean) {
        this.store.dispatch(UINotificationActions.updateNotification({ notification: { ...notification, read: readStatus } }));
    }

    setNotificationFilter(mode: 'all' | 'errors') {
        this.store.dispatch(NotificationCenterActions.setErrorsOnly({ errorsOnly: mode === 'errors' }));
    }

    get showMarkAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(map(ns => ns.length > 0));
    }

    markAllAsRead() {
        this.store.dispatch(UINotificationActions.markAllNotificationsAsRead());
    }

    unreadOnlyChange(event: MatSlideToggleChange) {
        this.store.dispatch(
            updateSetting({
                group: sphereNotificationsUserSettingsGroup,
                key: SphereNotificationUserSettingKey.showUnreadOnly,
                value: event.checked,
                optimistic: true
            })
        );
    }
}
