import { Component, Input, OnInit } from '@angular/core';
import { UINotificationActions } from '@app/portal/notifications/+state/notifications/notification.actions';
import { NotificationsState, SphereNotification } from '@app/portal/notifications/+state/notifications/notification.reducer';
import { NotificationHelperService } from '@app/portal/notifications/notification/notification-helper.service';
import { SphereDateService } from '@app/portal/settings/helpers/sphere-date.service';
import { Store } from '@ngrx/store';
import { timer } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Component({
    selector: `sphere-notification`,
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
    @Input() notification: SphereNotification;

    constructor(private store: Store<NotificationsState>, private date: SphereDateService, public helper: NotificationHelperService) {}

    ngOnInit() {
        const milliseconds = this.notification?.autoDismiss * 1000;
        timer(milliseconds || 8000)
            .pipe(
                take(1),
                tap(() => this.dismissVisible())
            )
            .subscribe();
    }

    dismissVisible() {
        this.store.dispatch(UINotificationActions.dismissVisibleNotification({ id: this.notification.id }));
    }

    markAsRead() {
        this.store.dispatch(UINotificationActions.markNotificationAsRead({ id: this.notification.id }));
        this.store.dispatch(UINotificationActions.markNotificationAsNotNew({ id: this.notification.id }));
    }

    formatTimestamp(date: Date): string {
        return this.date.format(date, 'time');
    }

    get contextIcon(): string {
        return this.helper.getContextIcon(this.notification.context);
    }

    get contextClass(): string {
        return this.helper.getContextClass(this.notification.context);
    }

    get isInProgress(): boolean {
        return this.helper.isInProgress(this.notification.context);
    }
}
