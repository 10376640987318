<div class="well hoverwell thumbnail">
    <strong [title]="searchType.tooltip"
        ><small>{{ searchType.name.toUpperCase() }}</small></strong
    >
    <mat-list dense>
        <mat-list-item *ngFor="let result of searchResults">
            {{ result.title }}
            <a mat-icon-button [routerLink]="result.routerLink">
                <div>
                    <small>({{ result.uri }})</small>
                </div>
            </a>
        </mat-list-item>
        <mat-list-item *ngIf="!(searchResults && searchResults.length)">
            No results
        </mat-list-item>
    </mat-list>
</div>
