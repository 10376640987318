import { NgModule } from '@angular/core';
import { SphereMonacoService } from '@shared/sphere-monaco/sphere-monaco.service';

@NgModule({
    imports: [],
    declarations: [],
    providers: [SphereMonacoService],
    exports: []
})
export class SphereMonacoModule {}
