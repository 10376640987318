import { AutocompleteFormlyComponent } from '@app/workflow/components/job-configuration/job-input-form/autocomplete-formly/autocomplete-formly.component';
import { FileNameInputComponent } from '@app/workflow/components/job-configuration/job-input-form/file-name-input/file-name-input.component';
import { InputNameComponent } from '@app/workflow/components/job-configuration/job-input-form/input-text/input-name.component';
import { TypeOption } from '@ngx-formly/core/lib/models';
import { TimezoneRegionsFormlyWrapperComponent } from '@shared/date-time-format-forms/timezone-regions-formly-wrapper/timezone-regions-formly-wrapper.component';
import { FormlyAutocompleteComponent } from '@shared/formly/autocomplete/formly-autocomplete.component';
import { DateFormatSelectorComponent } from '@shared/formly/date-time-format-selector/date-format-selector.component';
import { DateSelectorComponent } from '@shared/formly/date/date-selector.component';
import { FormlyFormArrayComponent } from '@shared/formly/formly-form-array/formly-form-array.component';
import { FormlyFormGroupComponent } from '@shared/formly/formly-form-group/formly-form-group.component';
import { SftpConfigComponent } from '@shared/formly/sftp-config/sftp-config.component';
import { TimeFormatSelectorComponent } from '@shared/formly/date-time-format-selector/time-format-selector.component';
import { TimeSelectorComponent } from '@shared/formly/time/time-selector.component';
import { UserSelectorComponent } from '@shared/formly/user-selector/user-selector.component';

export const formlyTypes: TypeOption[] = [
    {
        name: 'ondate',
        component: DateSelectorComponent
    },
    {
        name: 'date',
        component: DateSelectorComponent
    },
    {
        name: 'input-name',
        component: InputNameComponent
    },
    {
        name: 'autocomplete',
        component: AutocompleteFormlyComponent
    },
    {
        name: 'date-format',
        component: DateFormatSelectorComponent
    },
    {
        name: 'time-format',
        component: TimeFormatSelectorComponent
    },
    {
        name: 'timezone',
        component: TimezoneRegionsFormlyWrapperComponent
    },
    {
        name: 'file-name-input',
        component: FileNameInputComponent
    },
    {
        name: 'user-selector',
        component: UserSelectorComponent
    },
    {
        name: 'sftp-config',
        component: SftpConfigComponent
    },
    {
        name: 'time',
        component: TimeSelectorComponent
    },
    {
        name: 'formly-autocomplete',
        component: FormlyAutocompleteComponent
    },
    {
        name: 'form-array',
        component: FormlyFormArrayComponent
    },
    {
        name: 'form-group',
        component: FormlyFormGroupComponent
    }
];
