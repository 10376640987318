import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextSnackbarComponent } from '@shared/context-snackbar/context-snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [CommonModule, MatIconModule, MatButtonModule],
    declarations: [ContextSnackbarComponent],
    exports: [ContextSnackbarComponent]
})
export class ContextSnackbarModule {}
