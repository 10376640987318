import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, Input } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';

export type ExperimentalFeatureState = 'alpha' | 'beta';
export enum ExperimentalFeatureStateEnum {
    alpha = 'alpha',
    beta = 'beta'
}

@Component({
    selector: 'sphere-experimental-tag',
    template: `
        <span
            [matTooltip]="tooltip()"
            [matTooltipPosition]="tooltipPosition()"
            class="experimental-tag"
            [ngClass]="{
                'navigation-tag__alpha': isAlpha(),
                'navigation-tag__beta': isBeta()
            }"
        >
            @if (isAlpha()) {
                <ng-container>ALPHA</ng-container>
            } @else if (isBeta()) {
                <ng-container>BETA</ng-container>
            }
        </span>
    `,
    styles: [
        `
            .experimental-tag {
                margin-left: 15px;
                color: #ffffff;
                padding: 1px 8px;
                font-size: 10px;
                border-radius: 10px;
                font-weight: 500;
                overflow: visible;
                z-index: 5;
                height: 20px;
            }

            .navigation-tag__beta {
                background-color: #095616;
                border: 1px solid #298935;
            }

            .navigation-tag__alpha {
                background-color: #2c5876;
                border: 1px solid #4c91ad;
            }
        `
    ],
    imports: [NgClass, MatTooltip],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentalTagComponent {
    public readonly featureStages = ExperimentalFeatureStateEnum;

    readonly featureStage = input<ExperimentalFeatureState>(ExperimentalFeatureStateEnum.alpha);
    readonly isAlpha = computed(() => {
        return this.featureStage() === ExperimentalFeatureStateEnum.alpha;
    });
    readonly isBeta = computed(() => {
        return this.featureStage() === ExperimentalFeatureStateEnum.beta;
    });

    readonly tooltip = input<string>('This is an early access feature');
    readonly tooltipPosition = input<TooltipPosition>('after');
}
