import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbsV2Component } from '@app/portal/navigation/breadcrumbs/breadcrumbs-v2.component';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FunctionWrapperModule } from '@shared/pipes/function-wrapper/function-wrapper.module';

@NgModule({
    imports: [CommonModule, RouterModule, MatIconModule, MatButtonModule, FunctionWrapperModule],
    declarations: [BreadcrumbsComponent, BreadcrumbsV2Component],
    exports: [BreadcrumbsComponent, BreadcrumbsV2Component]
})
export class BreadcrumbsModule {}
