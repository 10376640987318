import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WebSocketEvent, WebSocketEventEntityType, WebSocketEventOperation } from '@app/portal/notifications/ws-event.service';
import { ErrorEventsActions, WsEventsActions } from '@app/portal/notifications/+state/events/events.actions';

export const eventsFeatureKey = 'sphere-events';

// WebSocket event
export interface SphereWSEvent<T = any> extends WebSocketEvent<T> {
    id: string;
}

// Error event
export interface SphereErrorEvent {
    id: string;
    title?: string;
    message: string;
    detail?: string;
    code?: number;
}

export const wsEventAdapter: EntityAdapter<SphereWSEvent> = createEntityAdapter<SphereWSEvent>({
    selectId: event => event.id
});

export const errorEventAdapter: EntityAdapter<SphereErrorEvent> = createEntityAdapter<SphereErrorEvent>();

export type WebsocketStatusType = 'open' | 'connecting' | 'closed' | 'closing';
export enum WebsocketStatusEnum {
    open = 'open',
    connecting = 'connecting',
    closed = 'closed',
    closing = 'closing'
}

export interface EventsState {
    websocketStatus: WebsocketStatusType;
    events: EntityState<SphereWSEvent>;
    errors: EntityState<SphereErrorEvent>;
}
const initialState: EventsState = {
    websocketStatus: 'closed',
    events: wsEventAdapter.getInitialState(),
    errors: errorEventAdapter.getInitialState()
};

export const reducer = createReducer<EventsState>(
    initialState,

    on(WsEventsActions.updateWebsocketStatus, (state, props) => ({
        ...state,
        websocketStatus: props.websocketStatus
    })),

    /**
     * EVENTS
     */
    on(WsEventsActions.createEvent, (state, props) => ({
        ...state,
        events: wsEventAdapter.addOne(props.event, state.events)
    })),
    on(WsEventsActions.updateEvent, (state, props) => ({
        ...state,
        events: wsEventAdapter.setOne(props.event, state.events)
    })),
    on(WsEventsActions.deleteEvent, (state, props) => ({
        ...state,
        events: wsEventAdapter.removeOne(props.id, state.events)
    })),
    on(WsEventsActions.deleteAllEvents, state => ({
        ...state,
        events: wsEventAdapter.getInitialState()
    })),

    /**
     * ERRORS
     */
    on(ErrorEventsActions.createErrorEvent, (state, props) => ({
        ...state,
        errors: errorEventAdapter.addOne(props.error, state.errors)
    })),
    on(ErrorEventsActions.updateErrorEvent, (state, props) => ({
        ...state,
        errors: errorEventAdapter.setOne(props.error, state.errors)
    })),
    on(ErrorEventsActions.deleteErrorEvent, (state, props) => ({
        ...state,
        errors: errorEventAdapter.removeOne(props.id, state.errors)
    })),
    on(ErrorEventsActions.deleteAllErrorEvents, state => ({
        ...state,
        errors: errorEventAdapter.getInitialState()
    }))
);

export const selectEventsFeature = createFeatureSelector<EventsState>(eventsFeatureKey);
export const selectWebsocketStatus = createSelector(selectEventsFeature, state => state.websocketStatus);

export const wsEventSelectors = wsEventAdapter.getSelectors();
export const selectWsEvents = createSelector(selectEventsFeature, state => state.events);
export const selectAllWsEvents = createSelector(selectWsEvents, wsEventSelectors.selectAll);
export const selectWsEventsForEntity = (entityType: WebSocketEventEntityType) =>
    createSelector(selectAllWsEvents, events => events.filter(e => e['entity-type'] === entityType));
export const selectWsEventsFor = (entityType: WebSocketEventEntityType, operation: WebSocketEventOperation) =>
    createSelector(selectAllWsEvents, events => events.filter(e => e['entity-type'] === entityType && e.operation === operation));
