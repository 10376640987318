import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    NotificationsState,
    selectHasNewErrorNotifications,
    selectHasNewNotifications,
    selectNewErrorNotificationCount,
    selectNewNotificationCount
} from '@app/portal/notifications/+state/notifications/notification.reducer';
import { NotificationCenterService } from '@app/portal/notifications/notification-center/notification-center.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'sphere-notification-center-toggle',
    template: `
        <button *ngrxLet="vm$; let vm" mat-icon-button (click)="notificationCenter.toggleNotificationCenter()">
            <mat-icon
                [matBadgeColor]="vm.hasErrorNotifications ? 'warn' : 'primary'"
                [matBadgeHidden]="!vm.hasNewNotifications"
                [matBadge]="vm.newNotificationCount > 9 ? '9+' : vm.newNotificationCount"
                >notifications</mat-icon
            >
        </button>
    `,
    styles: [``],
    standalone: false
})
export class NotificationCenterToggleComponent {
    private newNotificationCount$ = this.store.select(selectNewNotificationCount);
    private hasNewNotifications$ = this.store.select(selectHasNewNotifications);
    private newErrorNotificationCount$ = this.store.select(selectNewErrorNotificationCount);
    private hasErrorNotifications$ = this.store.select(selectHasNewErrorNotifications);

    public vm$ = combineLatest([
        this.newNotificationCount$,
        this.hasNewNotifications$,
        this.newErrorNotificationCount$,
        this.hasErrorNotifications$
    ]).pipe(
        map(([newNotificationCount, hasNewNotifications, newErrorNotificationCount, hasErrorNotifications]) => ({
            newNotificationCount,
            hasNewNotifications,
            newErrorNotificationCount,
            hasErrorNotifications
        }))
    );
    constructor(
        private store: Store<NotificationsState>,
        public notificationCenter: NotificationCenterService
    ) {}
}
