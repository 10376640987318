import { Component, Inject } from '@angular/core';
import { VersionEvent } from '@angular/service-worker';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SwUpdateDialogData {
    availableEvent: VersionEvent;
}

@Component({
    selector: 'sphere-sw-update',
    template: `
        <mat-dialog-content>
            <h2 mat-dialog-title>Update available</h2>
            <p>A new version of Sphere is available, please click below to update to the latest version. Updating will reload the page.</p>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Later</button>
            <button mat-button color="primary" [mat-dialog-close]="true">Update and reload</button>
        </mat-dialog-actions>
    `
})
export class SwUpdateComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: SwUpdateDialogData) {}
}
