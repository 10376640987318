import { Component, input, output, inject, OnInit, computed } from '@angular/core';
import { Route } from '@angular/router';
import { LocalStorageService } from '@app/portal/local-storage/local-storage.service';
import { patchState, signalState } from '@ngrx/signals';

import { fadeInOutAnimation } from '@shared/animations/fade-in-out';
import { slideInOutHorizontalAnimation } from '@shared/animations/slide-in-out-horizontal.animation';
import { slideInOutVerticalAnimation } from '@shared/animations/slide-in-out-vertical.animation';
import { IOutputAreaSizes, IOutputData } from 'angular-split/lib/interface';

type AppState = {
    sidenavSize: number;
    lockSidenavSize: boolean;
};

export const MINIMUM_SIDENAV_WIDTH_PX = 5;
export const MAXIMUM_SIDENAV_WIDTH_PX = 300;
export const DEFAULT_SIDENAV_WIDTH_PX = 200;
export const COLLAPSE_BREAKPOINT_SIDENAV_WIDTH_PX = 65;

@Component({
    selector: 'sphere-app-v2',
    templateUrl: './app-v2.component.html',
    styleUrls: ['./app.component.scss', './app-v2.component.scss'],
    animations: [slideInOutVerticalAnimation, slideInOutHorizontalAnimation, fadeInOutAnimation],
    standalone: false
})
export class AppV2Component implements OnInit {
    static readonly SideNavSizeKey = 'sphere-sidenav-size';
    readonly MINIMUM_SIDENAV_WIDTH_PX = MINIMUM_SIDENAV_WIDTH_PX;
    readonly MAXIMUM_SIDENAV_WIDTH_PX = MAXIMUM_SIDENAV_WIDTH_PX;
    readonly DEFAULT_SIDENAV_WIDTH_PX = DEFAULT_SIDENAV_WIDTH_PX;
    readonly COLLAPSE_BREAKPOINT_SIDENAV_WIDTH_PX = COLLAPSE_BREAKPOINT_SIDENAV_WIDTH_PX;

    private readonly localStorage = inject(LocalStorageService);

    public readonly isShell = input<boolean>(true);
    public readonly currentApp = input<Route>(null);
    public readonly noPaddingRoute = input<boolean>(false);
    public readonly routerNavigationLoading = input<boolean>(true);

    public readonly state = signalState<AppState>({
        sidenavSize: DEFAULT_SIDENAV_WIDTH_PX,
        lockSidenavSize: false
    });

    public readonly sidenavSize = computed(() => {
        return this.state.sidenavSize();
    });

    public readonly collapsedSidenav = computed(() => {
        return this.sidenavSize() <= MINIMUM_SIDENAV_WIDTH_PX;
    });
    public readonly collapsedSidenavButtonState = computed(() => {
        return this.sidenavSize() <= COLLAPSE_BREAKPOINT_SIDENAV_WIDTH_PX;
    });

    ngOnInit() {
        this.localStorage.get<number>('portal', AppV2Component.SideNavSizeKey, { type: 'number' }).subscribe(sidenavSize => {
            if (typeof sidenavSize === 'number') {
                patchState(this.state, {
                    sidenavSize
                });
            }
        });
    }

    _toggleSidenavState() {
        if (this.sidenavSize() <= COLLAPSE_BREAKPOINT_SIDENAV_WIDTH_PX) {
            patchState(this.state, {
                sidenavSize: DEFAULT_SIDENAV_WIDTH_PX
            });
        } else {
            patchState(this.state, {
                sidenavSize: MINIMUM_SIDENAV_WIDTH_PX
            });
        }
    }

    saveSidenavState(event: IOutputAreaSizes) {
        let [sidenavSize, contentSize] = event;
        if (this.isNumberSize(sidenavSize) && sidenavSize > MAXIMUM_SIDENAV_WIDTH_PX) {
            sidenavSize = MAXIMUM_SIDENAV_WIDTH_PX;
        } else if (this.isNumberSize(sidenavSize) && sidenavSize < COLLAPSE_BREAKPOINT_SIDENAV_WIDTH_PX) {
            sidenavSize = MINIMUM_SIDENAV_WIDTH_PX;
        } else if (!sidenavSize || !this.isNumberSize(sidenavSize)) {
            sidenavSize = DEFAULT_SIDENAV_WIDTH_PX;
        }
        patchState(this.state, {
            sidenavSize
        });
        this.localStorage.set('portal', AppV2Component.SideNavSizeKey, sidenavSize).subscribe();
    }

    private isNumberSize(size: number | string): size is number {
        return typeof size === 'number';
    }
}
