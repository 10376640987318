import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { AppConfigService } from '@app/service/app-config.service';
import { patchState, signalState } from '@ngrx/signals';
import {
    EnvironmentUrlUtilsService,
    SphereUrlEnvironmentInfo,
    SubEnvironmentName,
    SubEnvironmentUrlSegment
} from '@shared/utils/environment-url-utils.service';

export type BottomToolbarState = {
    environmentInfo: SphereUrlEnvironmentInfo;
    platformVersion: string;
    appVersion: string;
};

@Component({
    selector: 'shell-environment-indicator',
    templateUrl: './environment-indicator.component.html',
    styleUrls: ['./environment-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EnvironmentIndicatorComponent {
    public readonly state = signalState<BottomToolbarState>({
        environmentInfo: null,
        appVersion: '',
        platformVersion: ''
    });

    public readonly environmentColor = computed(() => {
        if (this.state.environmentInfo()?.subEnvironment) {
            switch (this.state.environmentInfo().subEnvironment) {
                case SubEnvironmentName.alpha:
                    return '#006cb8';
                case SubEnvironmentName.qa:
                    return '#b86e00';
                case SubEnvironmentName.staging:
                case SubEnvironmentName.dataqa:
                    return '#910c36';
                case SubEnvironmentName.ob:
                    return '#0c4771';
                case SubEnvironmentName.uat:
                    return '#aa8a13';
                case SubEnvironmentName.prod:
                    return '#2a5e16';
            }
        }
        return null;
    });

    constructor(environmentHelper: EnvironmentUrlUtilsService, appConfig: AppConfigService) {
        patchState(this.state, {
            environmentInfo: environmentHelper.currentEnvironmentInfo,
            platformVersion: appConfig.platformVersion,
            appVersion: appConfig.appVersion
        });
    }
}
