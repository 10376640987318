<mat-card [@grow]="animationState()" class="sphere-density__comfortable AppSwitcher" appearance="outlined">
    <mat-card-header>
        <button class="sphere-density__compact" mat-icon-button (click)="closeSelf()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-card-header>
    <mat-card-content class="AppSwitcherWrapper">
        <mat-nav-list>
            <a mat-list-item class="SwitcherApp" [routerLink]="['/', 'home']" (click)="closeSelf()">
                <div matListItemIcon>
                    <mat-icon>dashboard</mat-icon>
                </div>
                <span matListItemTitle> Home </span>
            </a>
            <!--            TODO:2025.1.0 - Requires alpha access policy for workspaces for now-->
            @if (hasAlphaAccess()) {
                <a mat-list-item class="SwitcherApp" [routerLink]="['/', 'workspaces']" (click)="closeSelf()">
                    <div matListItemIcon>
                        <mat-icon>workspaces</mat-icon>
                    </div>
                    <span matListItemTitle>Workspaces</span>
                    <span matListItemMeta>
                        <sphere-experimental-tag featureStage="alpha" />
                    </span>
                </a>
            }
        </mat-nav-list>
        @if (apps()?.length) {
            <mat-nav-list>
                @for (app of apps(); track $index) {
                    <a mat-list-item class="SwitcherApp" [routerLink]="['/', app.path]" (click)="closeSelf()">
                        <div matListItemIcon style="background-size: cover" [style.background-image]="getLogo(app.data['logoSvg'])"></div>
                        <span matListItemTitle>
                            {{ app.data?.name }}
                        </span>
                        @if (app.data?.alpha) {
                            <span matListItemMeta>
                                <sphere-experimental-tag featureStage="alpha" />
                            </span>
                        } @else if (app.data?.beta) {
                            <span matListItemMeta>
                                <sphere-experimental-tag featureStage="beta" />
                            </span>
                        }
                    </a>
                }
            </mat-nav-list>
        } @else {
            <p class="no-apps-message">We're sorry, you are not authorized to use any application. Please contact your system administrator.</p>
        }
    </mat-card-content>
</mat-card>
