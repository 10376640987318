import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatIconButton } from '@angular/material/button';
import { MatLine } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatListItem, MatListItemTitle, MatNavList } from '@angular/material/list';
import { AppConfigService } from '@app/service/app-config.service';

@Component({
    template: `
        <mat-nav-list class="sphere-density__comfortable">
            <a mat-list-item href="https://docs.tr.enverus.com/Content/Sphere_Home.htm" target="_blank" (click)="openLink($event)">
                <span matListItemTitle>Help center</span>
                <span matLine>Open the Enverus Sphere documentation site in a new tab.</span>
            </a>
            <a mat-list-item href="https://enverus.tfaforms.net/6" target="_blank" (click)="openLink($event)">
                <span matListItemTitle>Contact support</span>
                <span matLine>Contact the Enverus support team.</span>
            </a>
        </mat-nav-list>
    `,
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatNavList, MatListItem, MatListItemTitle, MatLine],
    standalone: true
})
class HelpCenterBottomSheetComponent {
    public appConfig = inject(AppConfigService);
    private _bottomSheetRef = inject<MatBottomSheetRef<HelpCenterBottomSheetComponent>>(MatBottomSheetRef);

    constructor() {}

    openLink(event: MouseEvent): void {
        this._bottomSheetRef.dismiss();
    }
}

@Component({
    selector: 'sphere-help-center-button',
    template: `
        <button mat-icon-button (click)="openHelpCenterOptions()">
            <mat-icon>help_outline</mat-icon>
        </button>
    `,
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatIcon, MatIconButton]
})
export class HelpCenterButtonComponent {
    constructor(private bottomSheet: MatBottomSheet) {}

    openHelpCenterOptions() {
        this.bottomSheet.open(HelpCenterBottomSheetComponent);
    }
}
