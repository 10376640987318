import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Route } from '@angular/router';
import { ExperimentalFeatureService } from '@app/portal/experimental-features/experimental-feature.service';
import { KeycloakService } from '@app/security/keycloak/keycloak.service';
import { SpherePluginPermissionService } from '@app/security/sphere-plugin-permission.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NavigationService } from '../navigation.service';

@Component({
    selector: 'sphere-sidebar-nav',
    templateUrl: './sidebar-nav.component.html',
    styleUrls: ['./sidebar-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)', opacity: 0, position: 'absolute', width: '100%' }),
                animate('180ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ transform: 'translateX(0%)', opacity: 1 }))
            ]),
            transition(':leave', [animate('200ms cubic-bezier(0.4, 0.0, 0.6, 1)', style({ transform: 'translateX(-100%)', opacity: 0 }))])
        ]),
        trigger('slideInOutB', [
            transition(':enter', [
                style({ transform: 'translateX(100%)', opacity: 0, position: 'absolute', width: '100%' }),
                animate('180ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ transform: 'translateX(0%)', opacity: 1 }))
            ]),
            transition(':leave', [animate('200ms cubic-bezier(0.4, 0.0, 0.6, 1)', style({ transform: 'translateX(100%)', opacity: 0 }))])
        ]),
        trigger('fadeInOut2', [
            transition(':enter', [style({ opacity: 0 }), animate('180ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 1 }))]),
            transition(':leave', [animate('200ms cubic-bezier(0.4, 0.0, 0.6, 1)', style({ opacity: 0 }))])
        ])
    ]
})
export class SidebarNavComponent implements OnInit {
    @Input() public header = 'Datamanager';
    @Input() public collapsedSidenav: boolean;

    isShell$: Observable<boolean> = this.navigationService.isShellSubject.asObservable();
    app: Route;
    appRoutes: Route[];
    expandableState: boolean[] = [];

    public getAllApps = this.keycloak.getUserApps(this.navigationService.apps);

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        public navigationService: NavigationService,
        private sanitizer: DomSanitizer,
        private keycloak: KeycloakService,
        private experimentalFeatures: ExperimentalFeatureService,
        private sphereApps: SpherePluginPermissionService
    ) {
        this.appRoutes = [];
    }

    ngOnInit() {
        this.navigationService.currentApp
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(route => {
                    this.app = route;
                    this.appRoutes = this.navigationService.getRoutes(route);
                })
            )
            .subscribe();
    }

    /**
     * Returns the list of visible routes.
     *
     */
    getVisibleRoutes = (routes: Route[]): Route[] => {
        return (
            routes &&
            routes
                .filter(route => !!route.data && !route.data['navHidden'])
                .filter(route => !route.data['superAdminRoute'] || this.keycloak.isSuperAdmin())
                .filter(route => (!!route.data['alpha'] ? this.experimentalFeatures.hasAlphaFeatureAccess : true))
                .filter(route => (!!route.data['beta'] ? this.experimentalFeatures.hasBetaFeatureAccess : true))
        );
    };

    getLogo = (logoUri: string): SafeStyle => {
        return this.sanitizer.bypassSecurityTrustStyle(`url("${logoUri}")`);
    };

    toggle(event: Event, index: number) {
        if (!this.collapsedSidenav) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.expandableState[index] = !this.expandableState[index];
        console.debug(`[DEBUG][SIDE-NAV] Expand state for INDEX [${index}]: ${this.expandableState[index]}`);
    }

    hasAccess(app: Route) {
        return this.sphereApps.hasAppPermission(app);
    }

    isFeatureEnabled(app: Route) {
        return this.sphereApps.isFeatureEnabled(app);
    }
}
