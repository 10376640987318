import { IRange, languages } from 'monaco-editor';
import CompletionItemInsertTextRule = languages.CompletionItemInsertTextRule;

export const LOGGER_CLASS: (range: IRange | languages.CompletionItemRanges) => languages.CompletionItem[] = range => [];
export const FORWARD_CONTRACT_CLASS: (range: IRange | languages.CompletionItemRanges) => languages.CompletionItem[] = range => [];

export const completionItemsMap = {
    LOGGER: LOGGER_CLASS,
    ForwardContract: FORWARD_CONTRACT_CLASS
};

export const globalCompletionItems: (range: IRange | languages.CompletionItemRanges) => languages.CompletionItem[] = range => [
    {
        label: 'ForwardContract',
        kind: languages.CompletionItemKind.Class,
        insertText: 'ForwardContract ${1:NAME} = new ForwardContract("$0", $1, $2)',
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'ForwardContract(String name, float value, boolean OnDate)',
        range
    },
    {
        label: 'DataProperty',
        kind: languages.CompletionItemKind.Class,
        insertText: 'DataProperty ${1:NAME} = DataProperty("")',
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
        range
    },
    {
        label: 'LOGGER.logMessage',
        kind: languages.CompletionItemKind.Function,
        insertText: 'LOGGER.logMessage("$0")',
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'LOGGER.logMessage(String message): void',
        range
    },
    {
        label: 'insert',
        kind: languages.CompletionItemKind.Function,
        insertText: 'insert("$0")',
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'insert(ForwardContract contract): void',
        range
    },
    {
        label: 'retract',
        kind: languages.CompletionItemKind.Function,
        insertText: 'retract("$0")',
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'retract(ForwardContract contract): void',
        range
    },
    {
        label: 'getValue()',
        kind: languages.CompletionItemKind.Function,
        insertText: 'getValue()',
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'ForwardContract.getValue(): double',
        range
    },
    {
        label: 'incrementPeriodCode()',
        kind: languages.CompletionItemKind.Function,
        insertText: 'incrementPeriodCode()',
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'ForwardContract.incrementPeriodCode(): void',
        range
    },
    {
        label: 'setSource()',
        kind: languages.CompletionItemKind.Function,
        insertText: 'setSource("${1:SOURCE}")',
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'ForwardContract.setSource(string SOURCE): void',
        range
    },
    {
        label: 'setTag()',
        kind: languages.CompletionItemKind.Function,
        insertText: 'setTag("${1:TAG}")',
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'ForwardContract.setTag(string TAG): void',
        range
    },
    {
        label: 'hoursInContract()',
        kind: languages.CompletionItemKind.Function,
        insertText: 'hoursInContract(${1:contract}, "${2:type}")',
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
        detail: 'hoursInContract(ForwardContract contract, String type)',
        range
    }
];

export const CurveRuleItems = (range: IRange) => ({
    ForwardContract: {
        kind: languages.CompletionItemKind.Class,
        constructors: <languages.CompletionItem[]>[
            {
                label: 'ForwardContract (common constructor)',
                kind: languages.CompletionItemKind.Constructor,
                insertText: 'ForwardContract ${1:NAME} = new ForwardContract("$0", $1, $2)',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'ForwardContract',
                documentation: {
                    isTrusted: true,
                    value: `
### Example
Creating a month ahead contract for reporting date 26th April 2020:
\`OnDate od = new OnDate(26, 4, 2020, "HENG:1", "MEOM:1");
ForwardContract fc = new ForwardContract("M01",51.5, od);\`
                `
                },
                range
            },
            {
                label: 'ForwardContract (with source constructor)',
                kind: languages.CompletionItemKind.Constructor,
                insertText: 'ForwardContract ${1:NAME} = new ForwardContract("$0", $1, $2, $3)',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'ForwardContract',
                documentation: {
                    isTrusted: true,
                    value: `
You can add a source description, by adding a fourth parameter to the common constructor.
### Example
Creating a month ahead contract for reporting date 26th April 2020:
\`OnDate od = new OnDate(26, 4, 2020, "HENG:1", "MEOM:1");
ForwardContract fc = new ForwardContract("M01", 51.5, od, "PRIMARY FEED");\`
                `
                },
                range
            },
            {
                label: 'ForwardContract (copy)',
                kind: languages.CompletionItemKind.Constructor,
                insertText: 'ForwardContract',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'ForwardContract(ForwardContract original)',
                documentation: {
                    isTrusted: true,
                    value: `
Copy constructor, allows you to clone a ForwardContract:
### Example
Creating a month ahead contract for reporting date 26th April 2020 and cloning it:
\`OnDate od = new OnDate(26, 4, 2020, "HENG:1", "MEOM:1");
ForwardContract fc = new ForwardContract("M01", 51.5, od);
ForwardContract fc2 = new ForwardContract(fc);\`
                `
                },
                range
            }
        ],
        methods: <languages.CompletionItem[]>[
            {
                label: 'getStartOfDelivery()',
                kind: languages.CompletionItemKind.Method,
                insertText: 'getStartOfDelivery()',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'OnDate',
                range
            },
            {
                label: 'getEndOfDelivery()',
                kind: languages.CompletionItemKind.Method,
                insertText: 'getEndOfDelivery()',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'OnDate',
                range
            },
            {
                label: 'toString()',
                kind: languages.CompletionItemKind.Method,
                insertText: 'toString()',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'String',
                documentation: {
                    isTrusted: true,
                    value: `
### Sample output
\`Tenor[M01 = 51.5 from PRIMARY FEED(04May2020 to 31May2020 quoting=6 actual=8)]\``
                },
                range
            },
            {
                label: 'asRelative()',
                kind: languages.CompletionItemKind.Method,
                insertText: 'asRelative()',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'ForwardContract',
                documentation: {
                    isTrusted: true,
                    value: `
Creates a relative forward contract from an absolute contract:
### Example
Creates a May 2020 contract and creates a relative forward contract from this using a report date of 26th April 2020:
\`OnDate od = new OnDate(26, 4, 2010, "HENG:1", "MEOM:1");
ForwardContract fcabs = new ForwardContract("2010M05", 51.5, od);
ForwardContract fcrel = fcabs.asRelative();\`
                `
                },
                range
            },
            {
                label: 'getRelativePeriodCode()',
                kind: languages.CompletionItemKind.Method,
                insertText: 'getRelativePeriodCode()',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'PeriodCode',
                documentation: {
                    isTrusted: true,
                    value: `
Returns the actual or possible relative period code from a ForwardContract
### Example
Creates a May 2020 contract and returns the relative PeriodCode object:
\`OnDate od = new OnDate(26, 4, 2020, "HENG:1", "MEOM:1");
ForwardContract fcabs = new ForwardContract("2020M05", 51.5, od);
PeriodCode relpc = fcabs.getRelativePeriodCode();\`
                `
                },
                range
            },
            {
                label: 'incrementPeriodCode()',
                kind: languages.CompletionItemKind.Method,
                insertText: 'incrementPeriodCode()',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'ForwardContract'
            },
            {
                label: 'asMonths()',
                kind: languages.CompletionItemKind.Method,
                insertText: 'asMonths()',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'ForwardContract[]'
            },
            {
                label: 'getTag()',
                kind: languages.CompletionItemKind.Method,
                insertText: 'getTag()',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'Object'
            },
            {
                label: 'setTag(Object tag)',
                kind: languages.CompletionItemKind.Method,
                insertText: 'setTag(${1:tag})',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'void'
            },
            {
                label: 'isEquals(ForwardContract fc)',
                kind: languages.CompletionItemKind.Method,
                insertText: 'isEquals(${1:fc})',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'boolean'
            },
            {
                label: 'isAfter(ForwardContract fc)',
                kind: languages.CompletionItemKind.Method,
                insertText: 'isAfter(${1:fc})',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'boolean'
            },
            {
                label: 'isImmediatelyAfter(ForwardContract fc)',
                kind: languages.CompletionItemKind.Method,
                insertText: 'isImmediatelyAfter(${1:fc})',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'boolean'
            },
            {
                label: 'isContains(ForwardContract fc)',
                kind: languages.CompletionItemKind.Method,
                insertText: 'isContains(${1:fc})',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'boolean'
            },
            {
                label: 'isContainedIn(ForwardContract fc)',
                kind: languages.CompletionItemKind.Method,
                insertText: 'isContainedIn(${1:fc})',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'boolean'
            },
            {
                label: 'isOverlaps(ForwardContract fc)',
                kind: languages.CompletionItemKind.Method,
                insertText: 'isOverlaps(${1:fc})',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'boolean'
            },
            {
                label: 'isBefore(ForwardContract fc)',
                kind: languages.CompletionItemKind.Method,
                insertText: 'isBefore(${1:fc})',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'boolean'
            },
            {
                label: 'getQuotingDayCount()',
                kind: languages.CompletionItemKind.Method,
                insertText: 'getQuotingDayCount()',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'int'
            },
            {
                label: 'getViewerDayCount()',
                kind: languages.CompletionItemKind.Method,
                insertText: 'getViewerDayCount()',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'int'
            },
            {
                label: 'getActualDays()',
                kind: languages.CompletionItemKind.Method,
                insertText: 'getActualDays()',
                insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
                detail: 'int'
            }
        ]
    }
});
