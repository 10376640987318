import { ModuleWithProviders, NgModule } from '@angular/core';
import { SearchService } from './search.service';

@NgModule({
    declarations: [],
    exports: []
})
export class CoreServicesModule {
    // this is here in case we decide to add some components or directives
    public static forRoot(): ModuleWithProviders<CoreServicesModule> {
        return {
            ngModule: CoreServicesModule,
            providers: [SearchService]
        };
    }
}
