import { NgModule } from '@angular/core';
import { StatefulPageComponentBase } from '@app/ngrx/page-ui-state/stateful-page.base';
import { CanDeactivatePageDialogComponent } from '@app/ngrx/page-ui-state/can-deactivate-page-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule, MatDialogModule, MatButtonModule],
    declarations: [StatefulPageComponentBase, CanDeactivatePageDialogComponent],
    exports: [StatefulPageComponentBase, CanDeactivatePageDialogComponent]
})
export class StatefulPageModule {}
