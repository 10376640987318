import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GreetingComponent } from './greeting.component';
import { GreetingDialogComponent } from './greeting-dialog/greeting-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ResetUserPasswordService } from '@shared/reset-user-password-dialog/reset-user-password.service';

@NgModule({
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatMenuModule, MatIconModule, RouterModule, MatListModule, MatTooltipModule],
    declarations: [GreetingComponent, GreetingDialogComponent],
    exports: [GreetingComponent],
    providers: [ResetUserPasswordService]
})
export class GreetingModule {}
