<div class="notification-center-item__icon" *ngIf="true; else inProgressIcon">
    <mat-icon class="{{ contextClass }}">{{ contextIcon }}</mat-icon>
</div>
<div [style.position]="'relative'">
    <div class="notification-center-item__read-indicator">
        <div
            class="read-indicator__wrapper"
            [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'"
            matTooltipPosition="left"
            (click)="$event.stopPropagation(); setReadStatus.emit(!notification.read)"
        >
            <div *ngIf="!notification.read" class="read-indicator__circle"></div>
        </div>
    </div>
    <section class="notification-center-item__title-wrapper">
        <div class="notification-center-item__title" [title]="notification.title">
            {{ notification.title }}
        </div>
        <div class="notification-center-item__timestamp text-subtle">
            <ng-container *ngIf="notification.app">
                <small>{{ notification.app | uppercase }}</small
                ><span class="dot"></span>
            </ng-container>
            <span [title]="formattedTimestamp">{{ fromNow }}</span>
        </div>
    </section>
    <section class="notification-center-item__body">
        <p class="notification-center-item__content" [title]="notification.content">
            {{ notification.content }}
        </p>
    </section>
    <section class="notification-center-item__progress" *ngIf="isInProgress">
        <mat-progress-bar mode="indeterminate" [style.height]="'1px !important'" color="accent"></mat-progress-bar>
    </section>
</div>

<ng-template #inProgressIcon>
    <div class="notification-center-item__pulsing-icon">
        <sphere-pulsing-indicator color="accent" animation="pulse" size="medium"></sphere-pulsing-indicator>
    </div>
</ng-template>
