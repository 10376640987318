import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ColorStatusIndicatorModule } from '@shared/color-status-indicator/color-status-indicator.module';
import { EnvironmentIndicatorComponent } from './environment-indicator.component';

@NgModule({
    imports: [CommonModule, MatTooltip, ColorStatusIndicatorModule],
    declarations: [EnvironmentIndicatorComponent],
    exports: [EnvironmentIndicatorComponent]
})
export class EnvironmentIndicatorModule {}
