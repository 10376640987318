import { Injectable, OnDestroy } from '@angular/core';
import { SphereNotification } from '@app/portal/notifications/+state/notifications/notification.reducer';
import {
    IWebSocketEventEntityType,
    IWebSocketEventOperation,
    WebSocketEvent,
    WebSocketEventEntityType,
    WebSocketEventOperation
} from '@app/portal/notifications/ws-event.service';
import { SphereWSEvent } from '@app/portal/notifications/+state/events/events.reducer';
import { curveAllHandler, curveCreatedHandler } from '@app/portal/notifications/global-handlers/curve-builder.handlers';
import {
    GlobalNotificationHandlerDefinition,
    SphereEventHandler,
    SphereNotificationBuilder
} from '@app/portal/notifications/global-handlers/global-handler';
import {
    workflowJobErrorHandler,
    workflowJobExecutionHandler,
    workflowJobHandler,
    workflowRemoveJobHandler
} from '@app/portal/notifications/global-handlers/workflow.handlers';
import { notificationAllHandler } from '@app/portal/notifications/global-handlers/sphere-notification.handlers';
import { importSymbolErrorHandler, importSymbolsHandler } from '@app/portal/notifications/global-handlers/data-management.handlers';

export interface SphereNotificationHandlerConfig {}

@Injectable({
    providedIn: 'root'
})
export class EventHandlersService implements OnDestroy {
    private registry = new Map<
        string,
        {
            isCustomNotification: boolean;
            correlationOnly: boolean;
            notificationBuilder?: SphereNotificationBuilder<any>;
            handlers?: SphereEventHandler[];
        }
    >();
    private sphereNotificationSystemHandler: GlobalNotificationHandlerDefinition<WebSocketEvent> = notificationAllHandler;

    constructor() {
        /**
         * Curve builder
         */
        this.registerHandler(
            curveCreatedHandler.entity,
            curveCreatedHandler.operation,
            curveCreatedHandler.isCustomNotification,
            curveCreatedHandler.correlationOnly,
            curveCreatedHandler.notificationBuilder
        );
        this.registerHandler(
            curveAllHandler.entity,
            curveAllHandler.operation,
            curveAllHandler.isCustomNotification,
            curveAllHandler.correlationOnly,
            curveAllHandler.notificationBuilder
        );

        /**
         * Workflow
         */
        this.registerHandler(
            workflowJobHandler.entity,
            workflowJobHandler.operation,
            workflowJobHandler.isCustomNotification,
            workflowJobHandler.correlationOnly,
            workflowJobHandler.notificationBuilder
        );
        this.registerHandler(
            workflowRemoveJobHandler.entity,
            workflowRemoveJobHandler.operation,
            workflowRemoveJobHandler.isCustomNotification,
            workflowRemoveJobHandler.correlationOnly,
            workflowRemoveJobHandler.notificationBuilder
        );
        this.registerHandler(
            workflowJobExecutionHandler.entity,
            workflowJobExecutionHandler.operation,
            workflowJobExecutionHandler.isCustomNotification,
            workflowJobExecutionHandler.correlationOnly,
            workflowJobExecutionHandler.notificationBuilder
        );
        this.registerHandler(
            workflowJobErrorHandler.entity,
            workflowJobErrorHandler.operation,
            workflowJobErrorHandler.isCustomNotification,
            workflowJobErrorHandler.correlationOnly,
            workflowJobErrorHandler.notificationBuilder
        );

        /**
         * Market data
         */
        this.registerHandler(
            importSymbolsHandler.entity,
            importSymbolsHandler.operation,
            importSymbolsHandler.isCustomNotification,
            importSymbolsHandler.correlationOnly,
            importSymbolsHandler.notificationBuilder
        );
        this.registerHandler(
            importSymbolErrorHandler.entity,
            importSymbolErrorHandler.operation,
            importSymbolErrorHandler.isCustomNotification,
            importSymbolErrorHandler.correlationOnly,
            importSymbolErrorHandler.notificationBuilder
        );

        /**
         * Sphere notifications
         */

        this.registerHandler(
            notificationAllHandler.entity,
            notificationAllHandler.operation,
            notificationAllHandler.isCustomNotification,
            notificationAllHandler.correlationOnly,
            notificationAllHandler.notificationBuilder
        );
    }

    ngOnDestroy() {
        this.registry.clear();
    }

    registerHandler<D = any>(
        entity: WebSocketEventEntityType | string,
        operations: WebSocketEventOperation[] | WebSocketEventOperation | string[] | string,
        isCustomNotification = false,
        correlationOnly = false,
        notificationBuilder?: SphereNotificationBuilder<D>,
        callback?: SphereEventHandler
    ) {
        if (!Array.isArray(operations)) {
            operations = [operations];
        }

        for (const operation of operations) {
            const entityOperation = `${entity}-${operation}`;
            if (this.registry.has(entityOperation)) {
                const current = this.registry.get(entityOperation);
                this.registry.set(entityOperation, { ...current, handlers: [...current.handlers, callback] });
            } else {
                this.registry.set(entityOperation, {
                    isCustomNotification: isCustomNotification,
                    correlationOnly,
                    notificationBuilder,
                    handlers: [callback]
                });
            }
        }
    }

    removeHandler(entity: WebSocketEventEntityType | string, operation: WebSocketEventOperation | string) {
        const event = `${entity}-${operation}`;
        if (this.registry.has(event)) {
            this.registry.delete(event);
        }
    }

    handle(entity: WebSocketEventEntityType | string, operation: WebSocketEventOperation | string, notification: SphereNotification) {
        const event = `${entity}-${operation}`;
        if (this.registry.has(event)) {
            for (const handler of this.registry.get(event).handlers) {
                handler(notification);
            }
        }
    }

    createNotificationHandler(
        entity: IWebSocketEventEntityType,
        operation: IWebSocketEventOperation,
        config?: SphereNotificationHandlerConfig
    ): SphereEventHandler {
        return notification => {
            // Custom notification generator.
        };
    }

    isCustomNotificationEvent(entity: IWebSocketEventEntityType, operation: IWebSocketEventOperation): boolean {
        const event = `${entity}-${operation}`,
            allEvent = `${entity}-${WebSocketEventOperation.all}`;
        if (this.registry.has(event)) {
            return this.registry.get(event).isCustomNotification;
        } else if (this.registry.has(allEvent)) {
            return this.registry.get(allEvent).isCustomNotification;
        }
        return false;
    }

    isSphereNotificationEvent(event: SphereWSEvent): boolean {
        return event.notification;
    }

    isCorrelationOnlyEvent(entity: IWebSocketEventEntityType, operation: IWebSocketEventOperation): boolean {
        const event = `${entity}-${operation}`,
            allEvent = `${entity}-${WebSocketEventOperation.all}`;
        if (this.registry.has(event)) {
            return this.registry.get(event).correlationOnly;
        } else if (this.registry.has(allEvent)) {
            return this.registry.get(allEvent).correlationOnly;
        }
        return false;
    }

    public buildNotification(event: SphereWSEvent) {
        if (event.notification) {
            return this.buildSphereNotification(event);
        }
        return this.buildCustomNotification(event);
    }

    private buildCustomNotification(event: SphereWSEvent) {
        const eventType = `${event['entity-type']}-${event.operation}`,
            allEventType = `${event['entity-type']}-${WebSocketEventOperation.all}`;

        if (this.registry.has(eventType) && !!this.registry.get(eventType).notificationBuilder) {
            return this.build(eventType, event);
        } else if (this.registry.has(allEventType) && !!this.registry.get(allEventType).notificationBuilder) {
            return this.build(allEventType, event);
        }
    }

    private buildSphereNotification(event: SphereWSEvent) {
        return this.sphereNotificationSystemHandler.notificationBuilder(event);
    }

    private build(eventName: string, event: SphereWSEvent) {
        return this.registry.get(eventName).notificationBuilder(event);
    }
}
