import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AnalyticsService } from '@app/analytics/analytics.service';
import { CompanySettingsService } from '@app/portal/company-settings/company-settings.service';
import { CookieNoticeComponent } from '@app/portal/cookie-notice/cookie-notice.component';
import { NavigationService } from '@app/portal/navigation/navigation.service';
import { NotificationSubscriptionsService } from '@app/portal/notification-subscriptions/notification-subscriptions.service';
import { WsEventService } from '@app/portal/notifications/ws-event.service';
import { ServiceWorkerUpdateService } from '@app/portal/sw-update/sw-update.service';
import { AppConfigService } from '@app/service/app-config.service';
import { fadeInOutAnimation } from '@shared/animations/fade-in-out';
import { slideInOutHorizontalAnimation } from '@shared/animations/slide-in-out-horizontal.animation';
import { slideInOutVerticalAnimation } from '@shared/animations/slide-in-out-vertical.animation';
import * as localforage from 'localforage';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppInsightsService } from './analytics/app-insights.service';
import { KeycloakService } from './security/keycloak/keycloak.service';
import { SystemSettingsService } from '@app/portal/system-settings/system-settings.service';

@Component({
    selector: 'sphere-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [slideInOutVerticalAnimation, slideInOutHorizontalAnimation, fadeInOutAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
    public collapsedSidenav = false;

    public isShell$ = this.navigationService.isShell$;

    public routerNavigationLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public routerNavigationLoading$ = this.routerNavigationLoading.asObservable();

    public sideNavHeader = 'Sphere';
    public username: string;

    getAllApps = this.keycloak.getUserApps(this.navigationService.apps);

    private readonly destroyRef = inject(DestroyRef);
    constructor(
        private swUpdate: ServiceWorkerUpdateService,
        private wsEvents: WsEventService,
        private navigationService: NavigationService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private keycloak: KeycloakService,
        private appInsightsService: AppInsightsService,
        appConfigService: AppConfigService,
        private bottomSheet: MatBottomSheet,
        private notificationSubscriptions: NotificationSubscriptionsService,
        private companySettings: CompanySettingsService,
        private systemSettings: SystemSettingsService,
        private analytics: AnalyticsService
    ) {
        wsEvents.init();
        swUpdate.checkForUpdates();
        router.events
            .pipe(
                tap(event => {
                    // TODO: every time we update URL changes, queryParams too, this is being fired, we should NOT rely on this.
                    // checkRouterEvent()

                    // Due to animation delay, the timeout wraps the check function to it is run on the next cycle
                    // of the VM, this way, the value is not changed before the lifecycle hook can perform the next check
                    // thus avoiding a NG0100: ExpressionChangedAfterItHasBeenCheckedError error.
                    setTimeout(() => {
                        this.checkRouterEvent(event);
                    }, 0);
                })
            )
            .subscribe();
        this.sideNavHeader = appConfigService.sideNavHeader;
        this.notificationSubscriptions.loadAllSubscriptions();
        this.companySettings.loadAllWellKnownCompanySettings();
        this.systemSettings.loadTimezones();
    }

    private checkRouterEvent(routerEvent: Event): void {
        if (routerEvent instanceof NavigationStart) {
            this.routerNavigationLoading.next(true);
        }

        if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
            this.routerNavigationLoading.next(false);
        }
    }

    ngOnInit(): void {
        this.checkPrivacyPolicyConsent();
        this.appInsightsService.logEvent('Application Loaded.');
        this.analytics.initialize();
    }

    ngOnDestroy(): void {
        this.routerNavigationLoading.complete();
    }

    async checkPrivacyPolicyConsent(): Promise<void> {
        try {
            const value = await localforage.getItem('privacy-policy');
            if (!value) {
                this.bottomSheet.open(CookieNoticeComponent);
            }
        } catch (err) {
            console.debug(`localForage error ${err}`);
        }
    }

    getLogo = (logoUri: string): SafeStyle => {
        return this.sanitizer.bypassSecurityTrustStyle(`url("${logoUri}")`);
    };

    // TODO: Persist the collapsed state setting as an application USER setting.
    toggleSidenavState() {
        this.collapsedSidenav = !this.collapsedSidenav;
    }
}
