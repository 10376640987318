import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationService } from './navigation.service';

@NgModule({
    imports: [RouterModule]
})
export class NavigationModule {
    static forRoot(): ModuleWithProviders<NavigationModule> {
        return {
            ngModule: NavigationModule,
            providers: [NavigationService]
        };
    }
}
