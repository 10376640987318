import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { selectNotificationUserSettings } from '@app/portal/settings/+state/settings.selectors';
import { SphereNotificationUserSettingKey } from '@app/portal/settings/+state/settings.models';
import { NotificationCenterActions, UINotificationActions } from '@app/portal/notifications/+state/notifications/notification.actions';

export const notificationsFeatureKey = 'notifications';

export type SphereNotificationContext = 'success' | 'info' | 'warning' | 'error' | 'partial-success' | 'in-progress' | string;
export enum SphereNotificationContextEnum {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
    PartialSuccess = 'partial-success',
    InProgress = 'in-progress'
}

// Notification object built from WS event or custom config.
export interface SphereNotification<T = any> {
    id: string;
    timestamp: Date;
    visible: boolean;
    read: boolean;
    new: boolean;
    context: SphereNotificationContext;
    title: string;
    content: string;
    url?: string;
    app?: string;
    clientNotification?: boolean;
    entityName?: string;
    autoDismiss?: number;
}

export const notificationAdapter: EntityAdapter<SphereNotification> = createEntityAdapter<SphereNotification>({
    selectId: notification => notification.id,
    sortComparer: (prev, next) => next.timestamp.getTime() - prev.timestamp.getTime()
});

export interface NotificationsState {
    notificationCenter: {
        open: boolean;
        dnd: boolean;
        errorsOnly: boolean;
        showUnreadOnly: boolean;
    };
    notifications: EntityState<SphereNotification>;
}
const initialState: NotificationsState = {
    notificationCenter: {
        open: false,
        dnd: false,
        errorsOnly: false,
        showUnreadOnly: false
    },
    notifications: notificationAdapter.getInitialState()
};

export const reducer = createReducer<NotificationsState>(
    initialState,

    /**
     * NOTIFICATIONS
     */
    on(UINotificationActions.displayNotification, (state, props) => ({
        ...state,
        notifications: notificationAdapter.addOne(props.notification, state.notifications)
    })),
    on(UINotificationActions.updateNotification, (state, props) => ({
        ...state,
        notifications: notificationAdapter.setOne(props.notification, state.notifications)
    })),
    on(UINotificationActions.deleteNotification, (state, props) => ({
        ...state,
        notifications: notificationAdapter.removeOne(props.id, state.notifications)
    })),
    on(UINotificationActions.deleteNotifications, state => ({
        ...state,
        notifications: notificationAdapter.getInitialState()
    })),
    on(UINotificationActions.dismissVisibleNotification, (state, props) => ({
        ...state,
        notifications: notificationAdapter.updateOne({ id: props.id, changes: { visible: false } }, state.notifications)
    })),
    on(UINotificationActions.markNotificationAsRead, (state, props) => ({
        ...state,
        notifications: notificationAdapter.updateOne({ id: props.id, changes: { read: true } }, state.notifications)
    })),
    on(UINotificationActions.markNotificationAsUnread, (state, props) => ({
        ...state,
        notifications: notificationAdapter.updateOne({ id: props.id, changes: { read: false } }, state.notifications)
    })),
    on(UINotificationActions.markNotificationAsNew, (state, props) => ({
        ...state,
        notifications: notificationAdapter.updateOne({ id: props.id, changes: { new: true } }, state.notifications)
    })),
    on(UINotificationActions.markNotificationAsNotNew, (state, props) => ({
        ...state,
        notifications: notificationAdapter.updateOne({ id: props.id, changes: { new: false } }, state.notifications)
    })),
    on(UINotificationActions.markAllNotificationsAsRead, state => ({
        ...state,
        notifications: notificationAdapter.map(n => ({ ...n, read: true }), state.notifications)
    })),
    on(UINotificationActions.markAllNotificationsAsUnread, state => ({
        ...state,
        notifications: notificationAdapter.map(n => ({ ...n, read: false }), state.notifications)
    })),
    on(UINotificationActions.markAllNotificationsAsNew, state => ({
        ...state,
        notifications: notificationAdapter.map(n => ({ ...n, new: true }), state.notifications)
    })),
    on(UINotificationActions.markAllNotificationsAsNotNew, state => ({
        ...state,
        notifications: notificationAdapter.map(n => ({ ...n, new: false }), state.notifications)
    })),
    on(UINotificationActions.markAllNotificationsAsNotVisible, state => ({
        ...state,
        notifications: notificationAdapter.map(n => ({ ...n, visible: false }), state.notifications)
    })),

    /**
     * NOTIFICATION CENTER
     */
    on(NotificationCenterActions.setNotificationCenterOpen, (state, props) => ({
        ...state,
        notificationCenter: {
            ...state.notificationCenter,
            open: props.open
        }
    })),
    on(NotificationCenterActions.setErrorsOnly, (state, props) => ({
        ...state,
        notificationCenter: {
            ...state.notificationCenter,
            errorsOnly: props.errorsOnly
        }
    })),
    on(NotificationCenterActions.setShowUnreadOnly, (state, props) => ({
        ...state,
        notificationCenter: {
            ...state.notificationCenter,
            showUnreadOnly: props.showUnreadOnly
        }
    })),
    on(NotificationCenterActions.setDoNotDisturb, (state, props) => ({
        ...state,
        notificationCenter: {
            ...state.notificationCenter,
            dnd: props.dnd
        }
    }))
);

const notificationSelectors = notificationAdapter.getSelectors();

export const selectNotificationsFeature = createFeatureSelector<NotificationsState>(notificationsFeatureKey);
export const selectNotifications = createSelector(selectNotificationsFeature, state => state.notifications);
export const selectAllNotifications = createSelector(selectNotifications, notificationSelectors.selectAll);
export const selectAllVisibleNotifications = createSelector(selectAllNotifications, state => state.filter(n => n.visible));
export const selectLastNVisibleNotifications = (last: number) =>
    createSelector(selectAllVisibleNotifications, selectNotificationUserSettings, (notifications, settings) => {
        let filtered = notifications;
        if (settings[SphereNotificationUserSettingKey.hideClientErrors]) {
            filtered = filtered.filter(n => !n.clientNotification);
        }
        return filtered.slice(0, last);
    });

export const selectUnreadNotifications = createSelector(selectAllNotifications, state => state.filter(n => !n.read));
export const selectUnreadNotificationCount = createSelector(selectUnreadNotifications, state => state.length);

export const selectNewNotifications = createSelector(selectAllNotifications, state => state.filter(n => n.new));
export const selectNewNotificationCount = createSelector(selectNewNotifications, state => state.length);
export const selectHasNewNotifications = createSelector(selectNewNotificationCount, state => state > 0);

export const selectNewErrorNotifications = createSelector(selectAllNotifications, state =>
    state.filter(n => n.new && n.context === SphereNotificationContextEnum.Error)
);
export const selectNewErrorNotificationCount = createSelector(selectNewErrorNotifications, state => state.length);
export const selectHasNewErrorNotifications = createSelector(selectNewErrorNotificationCount, state => state > 0);

export const selectNotificationCenter = createSelector(selectNotificationsFeature, state => state.notificationCenter);
