import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UserPrincipalActions } from '@app/+shared-state/user-principal/user-principal.actions';
import { AuthorizationServiceCurrentUserService } from '@app/user-manager/shared/api/authorization/generated';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UserPrincipalEffects {
    private actions$ = inject(Actions);
    private store = inject(Store);
    private user = inject(AuthorizationServiceCurrentUserService);

    fetchUserPrincipal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserPrincipalActions.fetchUserPrincipal),
            mergeMap(() => {
                return this.user.getCurrentUser1({}).pipe(
                    map(userPrincipal => UserPrincipalActions.fetchUserPrincipalSuccess({ principal: userPrincipal })),
                    catchError((error: HttpErrorResponse) => of(UserPrincipalActions.fetchUserPrincipalError({ error })))
                );
            })
        )
    );

    fetchUserGrantedAuthorities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserPrincipalActions.fetchGrantedAuthorities),
            mergeMap(() => {
                return this.user.getAuthorities().pipe(
                    map(grantedAuthorities => UserPrincipalActions.fetchGrantedAuthoritiesSuccess({ grantedAuthorities })),
                    catchError((error: HttpErrorResponse) => of(UserPrincipalActions.fetchGrantedAuthoritiesError({ error })))
                );
            })
        )
    );
}
