import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AppSwitcherService } from '@app/portal/app-switcher/app-switcher.service';

@Component({
    selector: 'sphere-app-switcher-button',
    template: `
        <button mat-icon-button (click)="toggleAppSwitcher()">
            <mat-icon>apps</mat-icon>
        </button>
    `,
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconButton, MatIcon],
    standalone: true
})
export class AppSwitcherButtonComponent {
    private appSwitcher = inject(AppSwitcherService);

    toggleAppSwitcher() {
        this.appSwitcher.toggleAppSwitcher();
    }
}
