import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdateComponent, SwUpdateDialogData } from '@app/portal/sw-update/sw-update.component';
import { exhaustMap, filter, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ServiceWorkerUpdateService {
    constructor(
        private updates: SwUpdate,
        private dialog: MatDialog,
        private snackbar: MatSnackBar
    ) {
        if (updates.isEnabled) {
            updates.versionUpdates
                .pipe(
                    tap(event => {
                        if (event.type === 'VERSION_DETECTED') {
                            snackbar.open('A new version of the application is being downloaded in the background.', null, { duration: 6000 });
                        }
                    }),
                    filter(event => event.type === 'VERSION_READY'),
                    exhaustMap(event => {
                        console.info(event);
                        return dialog
                            .open<SwUpdateComponent, SwUpdateDialogData, boolean>(SwUpdateComponent, {
                                disableClose: true,
                                data: { availableEvent: event }
                            })
                            .afterClosed();
                    }),
                    filter(dialogResponse => dialogResponse),
                    tap(reloadNow => {
                        if (reloadNow === true) {
                            console.info(`SW application update...`);
                            document.location.reload();
                        }
                    })
                )
                .subscribe();
        }
    }

    public checkForUpdates(): void {
        if (this.updates.isEnabled) {
            this.updates.versionUpdates
                .pipe(
                    tap(event => {
                        if (event.type === 'VERSION_DETECTED') {
                            this.snackbar.open('A new version of the application is being downloaded in the background.', null, { duration: 6000 });
                        }
                    }),
                    filter(event => event.type === 'VERSION_READY'),
                    exhaustMap(event => {
                        console.info(event);
                        return this.dialog
                            .open<SwUpdateComponent, SwUpdateDialogData, boolean>(SwUpdateComponent, {
                                disableClose: true,
                                data: { availableEvent: event }
                            })
                            .afterClosed();
                    }),
                    filter(dialogResponse => dialogResponse),
                    tap(reloadNow => {
                        if (reloadNow === true) {
                            console.info(`SW application update...`);
                            document.location.reload();
                        }
                    })
                )
                .subscribe();
        }
    }
}
