import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SearchTypeComponent } from './search-type/search-type.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { SearchService } from '@app/shared/services/search.service';

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, MatListModule, MatFormFieldModule, MatInputModule],
    declarations: [SearchTypeComponent, SearchResultComponent, SearchFormComponent],
    exports: [SearchTypeComponent, SearchResultComponent, SearchFormComponent],
    providers: [SearchService]
})
export class SearchModule {}
