import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { CURVE_FOLDERS_FEATURE_KEY, reducer } from '@shared/folders/folder-api.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CurveFolderApiEffects } from '@shared/folders/folder-api.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(CURVE_FOLDERS_FEATURE_KEY, reducer), EffectsModule.forFeature([CurveFolderApiEffects])],
    declarations: [],
    exports: []
})
export class FolderModule {}
