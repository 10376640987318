import { NgModule } from '@angular/core';
import { DataSupportAPIConfiguration } from '@app/critical-lists/shared/api/data-support/generated';
import { FinancialOptionsAPIConfiguration } from '@app/data-management/options/api';
import { DataManagementAPIConfiguration } from '@shared/api/data-management/generated';
import { KplerAPIConfiguration } from '@app/kpler/shared/api/generated';
import { AuthorizationAPIConfiguration } from '@app/user-manager/shared/api/authorization/generated';
import { WorkflowAPIConfiguration } from '@app/workflow/shared/api/workflow/generated';
import { CurveBuildRequestAPIConfiguration } from '@curve-builder/shared/api/curve-build/generated';
import { CurveSupportAPIConfiguration } from '@curve-builder/shared/api/curve-support/generated';
import { CurveAPIConfiguration } from '@curve-builder/shared/api/curve/generated';
import { CurveDefinitionAPIConfiguration } from '@curve-builder/shared/api/definition/generated';
import { FormulaAPIConfiguration } from '@curve-builder/shared/api/formula/generated';
import { UnitCurrencyAPIConfiguration } from '@curve-builder/shared/api/unit-currency/generated';
import { CalendarAPIConfiguration } from '@shared/api/calendar-service/api/generated';
import { CurveFolderAPIConfiguration } from '@shared/api/folder/generated';
import { NotificationSubscriptionsAPIConfiguration } from '@shared/api/notification-subscriptions/generated';
import { PriceAlertAPIConfiguration } from '@shared/api/pricealert/generated';
import { SettingsAPIConfiguration } from '@shared/settings/api/generated';

@NgModule({
    providers: [
        {
            provide: AuthorizationAPIConfiguration,
            useValue: new AuthorizationAPIConfiguration({
                basePath: '/api/authorization'
            })
        },
        {
            provide: CalendarAPIConfiguration,
            useValue: new CalendarAPIConfiguration({
                basePath: '/api/calendar'
            })
        },
        {
            provide: CurveAPIConfiguration,
            useValue: new CurveAPIConfiguration({
                basePath: '/api/curve'
            })
        },
        {
            provide: CurveBuildRequestAPIConfiguration,
            useValue: new CurveBuildRequestAPIConfiguration({
                basePath: '/api/curvebuild'
            })
        },
        {
            provide: CurveDefinitionAPIConfiguration,
            useValue: new CurveDefinitionAPIConfiguration({
                basePath: '/api/curve-definition'
            })
        },
        {
            provide: CurveFolderAPIConfiguration,
            useValue: new CurveFolderAPIConfiguration({
                basePath: '/api/folder'
            })
        },
        {
            provide: FormulaAPIConfiguration,
            useValue: new FormulaAPIConfiguration({
                basePath: '/api/formula-builder'
            })
        },
        {
            provide: DataManagementAPIConfiguration,
            useValue: new DataManagementAPIConfiguration({
                basePath: '/api/data-management'
            })
        },
        {
            provide: SettingsAPIConfiguration,
            useValue: new SettingsAPIConfiguration({
                basePath: '/api/setting'
            })
        },
        {
            provide: UnitCurrencyAPIConfiguration,
            useValue: new UnitCurrencyAPIConfiguration({
                basePath: '/api/unit-currency'
            })
        },
        {
            provide: WorkflowAPIConfiguration,
            useValue: new WorkflowAPIConfiguration({
                basePath: ''
            })
        },
        {
            provide: DataSupportAPIConfiguration,
            useValue: new DataSupportAPIConfiguration({
                basePath: '/api/data-support'
            })
        },
        {
            provide: CurveSupportAPIConfiguration,
            useValue: new CurveSupportAPIConfiguration({
                basePath: '/api/curve-support'
            })
        },
        {
            provide: NotificationSubscriptionsAPIConfiguration,
            useValue: new NotificationSubscriptionsAPIConfiguration({
                basePath: '/api/notification'
            })
        },
        {
            provide: PriceAlertAPIConfiguration,
            useValue: new PriceAlertAPIConfiguration({
                basePath: ''
            })
        },
        {
            provide: KplerAPIConfiguration,
            useValue: new KplerAPIConfiguration({
                basePath: '/api/kpler'
            })
        },
        {
            provide: FinancialOptionsAPIConfiguration,
            useValue: new FinancialOptionsAPIConfiguration({
                basePath: ''
            })
        }
    ]
})
export class GlobalApiConfigurationModule {}
