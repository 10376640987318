import { Injectable } from '@angular/core';
import { CompanySettingsActions } from '@app/portal/company-settings/+state/company-settings.actions';
import { wellKnownCompanySettings } from '@app/portal/company-settings/well-known-company-setting-ids';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})
export class CompanySettingsService {
    constructor(private store: Store) {}

    loadCompanySetting(group: string, key: string) {
        this.store.dispatch(CompanySettingsActions.loadCompanySetting({ group, key }));
    }

    loadAllWellKnownCompanySettings() {
        for (const { group, key } of wellKnownCompanySettings) {
            this.store.dispatch(CompanySettingsActions.loadCompanySetting({ group, key }));
        }
    }
}
