<div data-pendo="account">
    <span class="user-label" [matMenuTriggerFor]="menu">
        <small>{{ username }}</small> <mat-icon>keyboard_arrow_down</mat-icon>
    </span>
    <mat-menu #menu="matMenu" [overlapTrigger]="false" xPosition="before">
        <button mat-menu-item *ngIf="false">
            <mat-icon>account_circle</mat-icon>
            <span>Profile</span>
        </button>
        <a mat-menu-item (click)="openAbout()" data-cy="account-info">
            <mat-icon>info</mat-icon>
            <span>About</span>
        </a>
        <a mat-menu-item (click)="openGlobalSettings()" data-cy="portal-settings">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
        </a>
        <a *ngIf="isEnverusAuthentication" mat-menu-item (click)="resetPassword()" data-cy="reset-password-settings">
            <mat-icon>vpn_key</mat-icon>
            <span>Reset password</span>
        </a>
        <a mat-menu-item [routerLink]="['privacy-policy']" *ngIf="false">
            <mat-icon>security</mat-icon>
            <span>Privacy Policy</span>
        </a>
        <button mat-menu-item (click)="logout()" data-cy="logout">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
        </button>
        <ng-container *ngIf="environmentInfo">
            <mat-divider></mat-divider>
            <div matSubheader>Environment</div>
            <p matSubheader class="text-subtle">{{ environmentInfo.subEnvironment }}</p>
        </ng-container>
    </mat-menu>
</div>
