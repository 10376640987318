<div
    class="navigation__wrapper portal--menu"
    [ngClass]="{ 'navigation--nav-list--collapsed': collapsedSidenav }"
    data-pendo="apps"
    *ngIf="isShell$ | ngrxPush; else isPlugin"
    [@slideInOut]
>
    <h2 *ngIf="!collapsedSidenav">{{ header }}</h2>

    <mat-nav-list class="navigation__nav-list">
        <a
            mat-list-item
            [routerLink]="[]"
            routerLinkActive="active-nav"
            [matTooltip]="collapsedSidenav ? 'App dashboard' : null"
            matTooltipPosition="right"
            data-pendo="dashboard"
        >
            <mat-icon matListItemIcon>dashboard</mat-icon>
            <h4 matLine>Dashboard</h4>
        </a>
        <ng-container *ngIf="getAllApps?.length > 0">
            <a
                mat-list-item
                *ngFor="let next of getAllApps"
                [routerLink]="[next?.path]"
                routerLinkActive="active-nav"
                [matTooltip]="collapsedSidenav ? next.data['name'] : null"
                matTooltipPosition="right"
                [ngClass]="{ 'app--disabled': !hasAccess(next) }"
            >
                <div matListItemIcon style="background-size: cover" [style.background-image]="next.data['logoSvg'] | wrapFn: getLogo"></div>
                <h4 matLine>{{ next.data['name'] }}</h4>
            </a>
        </ng-container>
    </mat-nav-list>
</div>

<!-- INSIDE AN APP */ -->
<ng-template #isPlugin>
    <div class="navigation__wrapper app--menu" [ngClass]="{ 'navigation--nav-list--collapsed': collapsedSidenav }" [@slideInOutB]>
        <mat-grid-list cols="10" rowHeight="40" gutterSize="0" [style.margin-bottom.px]="7" *ngIf="!collapsedSidenav">
            <mat-grid-tile [colspan]="1" [matTooltip]="collapsedSidenav ? 'App selection' : null" matTooltipPosition="right"> </mat-grid-tile>

            <mat-grid-tile colspan="9" *ngIf="!collapsedSidenav">
                <h2 [style.width.%]="100" [style.height.%]="100" [style.margin.px]="0" [style.padding.px]="0" [style.line-height.px]="37">
                    {{ (app?.data)['name'] }}
                </h2>
            </mat-grid-tile>
        </mat-grid-list>

        <mat-nav-list class="navigation__nav-list" *ngrxLet="appRoutes | wrapFn: getVisibleRoutes; let routes">
            <ng-container *ngFor="let route of routes; let i = index">
                <a
                    *ngIf="isFeatureEnabled(route)"
                    [ngClass]="{ 'route-item__no-access': !hasAccess(route) }"
                    mat-list-item
                    class="route-item"
                    [routerLink]="[app.path, route.path]"
                    [skipNavigation]="rla.isActive && route.data?.skipSameUrlNavigation"
                    routerLinkActive="active-nav"
                    #rla="routerLinkActive"
                    [matTooltip]="collapsedSidenav ? route.data.title : null"
                    matTooltipPosition="right"
                    [attr.data-cy]="route.data?.cy || 'sidebar-nav-menu-item'"
                    [attr.data-pendo]="route.data.pendoId ? route.data.pendoId : null"
                >
                    <mat-icon matListItemIcon>{{ route.data.matIcon }}</mat-icon>
                    <h4 matLine>
                        {{ route.data.title || (route | wrapFn: navigationService.getRouteDisplayName)
                        }}<sphere-experimental-tag
                            *ngIf="route.data.beta || route.data.alpha"
                            [featureStage]="route.data.beta ? 'beta' : route.data.alpha ? 'alpha' : undefined"
                        ></sphere-experimental-tag>
                    </h4>
                    <button
                        class="dense-compact"
                        (click)="toggle($event, i)"
                        mat-icon-button
                        [style.opacity]="0.5"
                        *ngIf="route.children && (route.children | wrapFn: getVisibleRoutes)?.length > 0"
                    >
                        <mat-icon *ngIf="expandableState[i]">expand_more</mat-icon>
                        <mat-icon *ngIf="!expandableState[i]">chevron_left</mat-icon>
                    </button>
                </a>
            </ng-container>
        </mat-nav-list>
    </div>
</ng-template>
