import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeycloakService } from './keycloak/keycloak.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakTokenInterceptor } from './keycloak/keycloak-token.interceptor';
import { AuthGuard } from './auth.guard';
import { RoleGuard } from './role.guard';
import { PrincipalService } from '@app/security/principal.service';

@NgModule({
    imports: [CommonModule],
    providers: [
        KeycloakService,
        PrincipalService,
        AuthGuard,
        RoleGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KeycloakTokenInterceptor,
            multi: true
        }
    ]
})
export class SecurityModule {}
