import { ComponentRef, Injectable, Injector, OnDestroy } from '@angular/core';
import { NotificationsState } from '@app/portal/notifications/+state/notifications/notification.reducer';
import { Store } from '@ngrx/store';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { NotificationCenterComponent } from '@app/portal/notifications/notification-center/notification-center.component';
import { NotificationCenterActions, UINotificationActions } from '@app/portal/notifications/+state/notifications/notification.actions';

export class NotificationContainerRef {
    constructor(private overlayRef: OverlayRef) {}

    close(): void {
        this.overlayRef.dispose();
    }
}

export class NotificationCenterRef {
    constructor(private overlayRef: OverlayRef) {}

    close(): void {
        this.overlayRef.dispose();
    }
}

@Injectable({
    providedIn: 'root'
})
export class NotificationCenterService implements OnDestroy {
    public notificationCenter: NotificationCenterComponent;
    public notificationCenterRef: NotificationCenterRef;
    constructor(
        private store: Store<NotificationsState>,
        private overlay: Overlay,
        private injector: Injector
    ) {}

    ngOnDestroy() {}

    toggleNotificationCenter() {
        if (!this.notificationCenter) {
            this.openNotificationCenter();
        } else {
            this.closeNotificationCenter();
        }
    }

    openNotificationCenter() {
        const overlayRef = this.createNotificationCenterOverlay();
        this.notificationCenterRef = new NotificationCenterRef(overlayRef);
        this.notificationCenter = this.attachNotificationCenter(overlayRef, this.notificationCenterRef);
        overlayRef.backdropClick().subscribe(() => this.closeNotificationCenter());
        this.store.dispatch(NotificationCenterActions.setNotificationCenterOpen({ open: true }));
        this.store.dispatch(UINotificationActions.markAllNotificationsAsNotVisible());
    }

    closeNotificationCenter() {
        this.notificationCenterRef?.close();
        this.notificationCenter = null;
        this.notificationCenterRef = null;
        this.store.dispatch(NotificationCenterActions.setNotificationCenterOpen({ open: false }));
    }

    attachNotificationCenter(overlayRef: OverlayRef, notificationCenterRef: NotificationCenterRef) {
        const injector = this.createNotificationCenterInjector(notificationCenterRef);
        const containerPortal = new ComponentPortal(NotificationCenterComponent, null, injector);
        const containerRef: ComponentRef<NotificationCenterComponent> = overlayRef.attach(containerPortal);
        return containerRef.instance;
    }

    createNotificationCenterOverlay() {
        const overlayConfig = this.getNotificationCenterOverlayConfig();
        return this.overlay.create(overlayConfig);
    }

    createNotificationCenterInjector(notificationCenterRef: NotificationCenterRef): Injector {
        return Injector.create({
            parent: this.injector,
            providers: [{ provide: NotificationCenterRef, useValue: notificationCenterRef }]
        });
    }

    getNotificationCenterOverlayConfig(): OverlayConfig {
        const positionStrategy = this.overlay.position().global().right('55px').top('50px');

        return new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            positionStrategy
        });
    }
}
