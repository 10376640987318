import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectWebsocketStatus } from '@app/portal/notifications/+state/events/events.reducer';
import { map } from 'rxjs/operators';
import { WebsocketStatusEnum } from '@shared/websocket-status';

@Component({
    selector: 'sphere-ws-status',
    template: `
        <div class="ws-status__container" *ngIf="!onlyErrors || (isNonIdealStatus$ | ngrxPush)">
            <sphere-pulsing-indicator
                size="xsmall"
                [color]="wsStatusColor$ | ngrxPush"
                shape="circle"
                [animation]="wsStatusAnimation$ | ngrxPush"
            ></sphere-pulsing-indicator>
            <p class="ws-status__label">{{ wsStatusLabel$ | ngrxPush }}</p>
        </div>
    `,
    styles: [
        `
            .ws-status__container {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .ws-status__label {
                font-family: var(--font-family-roboto);
                font-weight: 500;
                font-size: 9px;
                color: #979797;
                margin-left: -3px;
            }
        `
    ],
    standalone: false
})
export class WsStatusComponent {
    @Input() onlyErrors = false;

    public wsStatus$ = this.store.select(selectWebsocketStatus);
    public wsStatusColor$ = this.wsStatus$.pipe(
        map(status => {
            switch (status) {
                case WebsocketStatusEnum.open:
                    return 'green';
                case WebsocketStatusEnum.connecting:
                    return 'yellow';
                case WebsocketStatusEnum.closed:
                    return 'red';
                case WebsocketStatusEnum.closing:
                    return 'orange';
                default:
                    return 'darkgray';
            }
        })
    );
    public wsStatusAnimation$ = this.wsStatus$.pipe(
        map(status => {
            switch (status) {
                case WebsocketStatusEnum.open:
                case WebsocketStatusEnum.connecting:
                case WebsocketStatusEnum.closing:
                    return 'pulse';
                case WebsocketStatusEnum.closed:
                default:
                    return null;
            }
        })
    );
    public wsStatusLabel$ = this.wsStatus$.pipe(
        map(status => {
            switch (status) {
                case WebsocketStatusEnum.open:
                    return 'Real time data available';
                case WebsocketStatusEnum.connecting:
                    return 'Connecting to real time data';
                case WebsocketStatusEnum.closed:
                    return 'Offline';
                case WebsocketStatusEnum.closing:
                    return 'Disconnecting from real time data';
                default:
                    return 'Unknown';
            }
        })
    );
    public isNonIdealStatus$ = this.wsStatus$.pipe(
        map(status => {
            switch (status) {
                case WebsocketStatusEnum.closed:
                case WebsocketStatusEnum.closing:
                case WebsocketStatusEnum.connecting:
                    return true;
                case WebsocketStatusEnum.open:
                    return false;
            }
        })
    );

    constructor(private store: Store) {}
}
