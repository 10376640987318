<mat-card
    appearance="outlined"
    class="app-card"
    [ngClass]="{ 'app-card--disabled': disabled() }"
    matRipple
    [routerLink]="['/' + app().path]"
    (mouseover)="cardHover = true"
    (mouseleave)="cardHover = false"
>
    <mat-card-header>
        <div
            *ngrxLet="app() | wrapFn: sanitizeCSS; let css"
            mat-card-avatar
            [ngStyle]="{ backgroundColor: cardHover ? app().data['cardBackground'] : null }"
            [style.background-size]="'cover'"
            [style.background-image]="css"
        ></div>
        <mat-card-title>
            <span *ngrxLet="app().data['name'] | wrapFn: sanitizeHTML; let html" [innerHTML]="html" [style.font-weight]="400"></span>
        </mat-card-title>
        <mat-card-subtitle>{{ app().data['tagLine'] | uppercase }}</mat-card-subtitle>
    </mat-card-header>
</mat-card>
