import { GlobalNotificationHandlerDefinition } from '@app/portal/notifications/global-handlers/global-handler';
import { IWebSocketEventOperation, WebSocketEventEntityType, WebSocketEventOperation } from '@app/portal/notifications/ws-event.service';
import { Job, WorkflowExecution } from '@app/workflow/shared/api/workflow/generated';
import { SphereNotificationContext, SphereNotificationContextEnum } from '@app/portal/notifications/+state/notifications/notification.reducer';
import { WF_JOB_EXECUTIONS_PATH, WF_JOBS_PATH, WORKFLOW_PATH } from '@app/workflow/workflow.paths';

/**
 * Workflow jobs.
 */
export const workflowJobHandler: GlobalNotificationHandlerDefinition<Job> = {
    entity: WebSocketEventEntityType.job,
    operation: [WebSocketEventOperation.created, WebSocketEventOperation.updated],
    isCustomNotification: true,
    correlationOnly: true,
    notificationBuilder: event => {
        const { category, workflowId, id, name, schedule, holidayCalendar, input, lastModified, description } = event.data;
        const operationString = event.operation.toString().toLowerCase();
        const title = `${name} ${operationString}`;
        const content = `Task ${name} was ${operationString} successfully.`;
        const url =
            event.operation === WebSocketEventOperation.created || event.operation === WebSocketEventOperation.updated
                ? `/${WORKFLOW_PATH}/${WF_JOBS_PATH}/${id}`
                : null;

        return {
            id: event['correlation-id'] || event.id,
            title,
            content,
            app: 'workflows',
            url,
            context: 'success'
        };
    }
};

export const workflowJobErrorHandler: GlobalNotificationHandlerDefinition<Job> = {
    entity: WebSocketEventEntityType.job,
    operation: [WebSocketEventOperation.failed],
    isCustomNotification: true,
    correlationOnly: true,
    notificationBuilder: event => {
        const { name } = event.data;
        const title = `${name} operation failed`;
        const content = `An error happened: ${event.message || 'Unknown error'}`;
        return {
            id: event['correlation-id'] || event.id,
            title,
            content,
            app: 'workflows',
            context: 'error'
        };
    }
};

export const workflowRemoveJobHandler: GlobalNotificationHandlerDefinition<Job & { entityName?: string }> = {
    entity: WebSocketEventEntityType.job,
    operation: WebSocketEventOperation.removed,
    isCustomNotification: true,
    correlationOnly: true,
    notificationBuilder: event => {
        const { id, name } = event.data;
        const operationString = event.operation.toString().toLowerCase();
        const title = `${name} ${operationString}`;
        const content = `Task ${name} was ${operationString} successfully.`;
        const url =
            event.operation === WebSocketEventOperation.created || event.operation === WebSocketEventOperation.updated
                ? `/${WORKFLOW_PATH}/${WF_JOBS_PATH}/${id}`
                : null;

        return {
            id: event['correlation-id'] || event.id,
            title,
            content,
            app: 'workflows',
            url,
            context: 'success'
        };
    }
};

/**
 * Workflow executions.
 */
const toOperationString = (operation: IWebSocketEventOperation) => operation.toString().toLowerCase();
const getTitleForExecution = (name: string, operation: IWebSocketEventOperation) => {
    const operationString = toOperationString(operation),
        operationTitleCase = `${operationString[0]?.toUpperCase()}${operationString.slice(1)}`;
    switch (operation) {
        case WebSocketEventOperation.executionRunning:
            return `${operationTitleCase} ${name}`;
        case WebSocketEventOperation.executionSucceeded:
        case WebSocketEventOperation.executionFailed:
            return `${name} execution ${operationString}`;
        default:
            return `${operationTitleCase} - ${name}`;
    }
};
const getContentForExecution = (executionId: string, name: string, operation: IWebSocketEventOperation) => {
    const operationString = toOperationString(operation);
    const statusJoinText = operation === WebSocketEventOperation.executionRunning ? 'is' : 'has';
    return `Task execution ${executionId} for ${name} ${statusJoinText} ${operationString}`;
};

export const workflowJobExecutionHandler: GlobalNotificationHandlerDefinition<WorkflowExecution> = {
    entity: WebSocketEventEntityType.jobExecution,
    operation: [WebSocketEventOperation.executionRunning, WebSocketEventOperation.executionSucceeded, WebSocketEventOperation.executionFailed],
    isCustomNotification: true,
    correlationOnly: true,
    notificationBuilder: event => {
        const { id, jobId, status, category, jobName, workflowName } = event.data;
        const name = jobName || workflowName || 'Unknown task';

        const url = `/${WORKFLOW_PATH}/${WF_JOB_EXECUTIONS_PATH}/${id}`;
        let context: SphereNotificationContext;
        switch (event.operation) {
            case WebSocketEventOperation.executionRunning:
                context = SphereNotificationContextEnum.InProgress;
                break;
            case WebSocketEventOperation.executionSucceeded:
                context = SphereNotificationContextEnum.Success;
                break;
            case WebSocketEventOperation.executionFailed:
                context = SphereNotificationContextEnum.Error;
        }

        return {
            id: event['correlation-id'] || event.id,
            title: getTitleForExecution(name, event.operation),
            content: getContentForExecution(id, name, event.operation),
            app: 'workflows',
            url,
            context
        };
    }
};
