import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CurveSymbolActions } from '@app/+shared-state/curve-symbol/curve-symbol.actions';
import {
    selectCurveDefinitionForSymbol,
    selectCurveFieldsForSymbol,
    selectCurveOnDatesForSymbol,
    selectCurveVersionsForSymbol
} from '@app/+shared-state/curve-symbol/curve-symbol.selectors';
import { SymbolActions } from '@app/+shared-state/symbol/symbol.actions';
import { Symbol } from '@app/data-management/symbols';
import { CurveService } from '@curve-builder/shared/api/curve/generated';
import { CurveDefinitionService } from '@curve-builder/shared/api/definition/generated';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class CurveSymbolEffects {
    private actions$ = inject(Actions);
    private store = inject(Store);
    private curveDefinitions = inject(CurveDefinitionService);
    private curves = inject(CurveService);

    loadCurveFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CurveSymbolActions.fetchCurveFields),
            concatLatestFrom(({ symbol }) => this.store.select(selectCurveFieldsForSymbol(symbol))),
            filter(([{ force }, curveFields]) => force || !curveFields?.loaded || curveFields?.loadingError),
            mergeMap(([{ symbol }]) => {
                const [category, code] = Symbol.curveSymbolToCategoryAndCode(symbol);
                return this.curves.getCurveProfiles({ category, code }).pipe(
                    map(curveFields => CurveSymbolActions.fetchCurveFieldsSuccess({ symbol, fields: curveFields })),
                    catchError((error: HttpErrorResponse) => {
                        return of(CurveSymbolActions.fetchCurveFieldsError({ symbol, error }));
                    })
                );
            })
        )
    );

    loadCurveOnDates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CurveSymbolActions.fetchCurveOnDates),
            concatLatestFrom(({ symbol, field }) => this.store.select(selectCurveOnDatesForSymbol(symbol, field))),
            filter(([{ force }, curveOnDates]) => force || !curveOnDates?.loaded || curveOnDates?.loadingError),
            mergeMap(([{ symbol, field }]) => {
                field = field || 'CURVE';
                const [category, code] = Symbol.curveSymbolToCategoryAndCode(symbol);
                return this.curves.getCurveOndates({ category, code, profile: field }).pipe(
                    map(curveOnDates => CurveSymbolActions.fetchCurveOnDatesSuccess({ symbol, field, onDates: curveOnDates })),
                    catchError((error: HttpErrorResponse) => {
                        return of(CurveSymbolActions.fetchCurveOnDatesError({ symbol, field, error }));
                    })
                );
            })
        )
    );

    loadCurveVersions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CurveSymbolActions.fetchCurveVersions),
            concatLatestFrom(({ symbol, field, onDate }) => this.store.select(selectCurveVersionsForSymbol(symbol, field, onDate))),
            filter(([{ force }, curveVersions]) => force || !curveVersions?.loaded || curveVersions?.loadingError),
            mergeMap(([{ symbol, field, onDate }]) => {
                field = field || 'CURVE';
                const [category, code] = Symbol.curveSymbolToCategoryAndCode(symbol);
                return this.curves.getCurveVersions({ category, code, profile: field, ondate: onDate }).pipe(
                    map(curveVersions => CurveSymbolActions.fetchCurveVersionsSuccess({ symbol, field, onDate, versions: curveVersions })),
                    catchError((error: HttpErrorResponse) => {
                        return of(CurveSymbolActions.fetchCurveVersionsError({ symbol, field, onDate, error }));
                    })
                );
            })
        )
    );

    loadCurveDefinition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CurveSymbolActions.fetchCurveDefinition),
            concatLatestFrom(({ symbol }) => this.store.select(selectCurveDefinitionForSymbol(symbol))),
            filter(([{ force }, curveDefinition]) => force || !curveDefinition?.loaded || curveDefinition?.loadingError),
            mergeMap(([{ symbol }]) => {
                const [category, code] = Symbol.curveSymbolToCategoryAndCode(symbol);
                return this.curveDefinitions.getCurveDefinition({ category, code }).pipe(
                    map(curveDefinition => CurveSymbolActions.fetchCurveDefinitionSuccess({ symbol, curveDefinition })),
                    catchError((error: HttpErrorResponse) => {
                        return of(CurveSymbolActions.fetchCurveDefinitionError({ symbol, error }));
                    })
                );
            })
        )
    );
}
