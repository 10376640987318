import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GreetingComponent } from './greeting/greeting.component';
import { GreetingModule } from './greeting/greeting.module';

@NgModule({
    imports: [CommonModule, GreetingModule],
    exports: [GreetingComponent]
})
export class SecurityUIModule {}
