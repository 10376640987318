import { GlobalNotificationHandlerDefinition } from '@app/portal/notifications/global-handlers/global-handler';
import { WebSocketEventEntityType, WebSocketEventOperation } from '@app/portal/notifications/ws-event.service';

export const importSymbolsHandler: GlobalNotificationHandlerDefinition = {
    entity: WebSocketEventEntityType.symbolFileImport,
    operation: [WebSocketEventOperation.symbolImportSuccess],
    isCustomNotification: true,
    correlationOnly: true,
    notificationBuilder: event => {
        return {
            id: event['correlation-id'] || event.id,
            title: 'Imported symbol(s)',
            content: 'Symbol(s) were imported successfully.',
            app: 'data-management',
            context: 'success'
        };
    }
};

export const importSymbolErrorHandler: GlobalNotificationHandlerDefinition = {
    entity: WebSocketEventEntityType.symbolFileImport,
    operation: [WebSocketEventOperation.symbolImportFailure, WebSocketEventOperation.symbolImportPartial],
    isCustomNotification: true,
    correlationOnly: true,
    notificationBuilder: event => {
        return {
            id: event['correlation-id'] || event.id,
            title:
                event['operation'] === WebSocketEventOperation.symbolImportFailure
                    ? 'Import operation failed'
                    : 'Import operation partially successful',
            content:
                event['operation'] === WebSocketEventOperation.symbolImportFailure
                    ? `An error happened: ${event.data?.message || 'Unknown error'}`
                    : 'The import operation was only partially completed. Please check the details and try again',
            app: 'data-management',
            context: 'error'
        };
    }
};
