import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FunctionWrapperModule } from '@shared/pipes/function-wrapper/function-wrapper.module';

@NgModule({
    imports: [CommonModule, RouterModule, MatIconModule, MatButtonModule, FunctionWrapperModule],
    declarations: [BreadcrumbsComponent],
    exports: [BreadcrumbsComponent]
})
export class BreadcrumbsModule {}
