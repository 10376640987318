<ng-container *ngrxLet="notificationCenter$; let notificationCenter; let e = $error; let c = $complete">
    <div class="notification-center mat-elevation-z4">
        <div class="notification-center__header">
            <h3 class="notification-center__title">Notifications</h3>
            <div class="spacer"></div>
            <mat-slide-toggle
                labelPosition="before"
                [checked]="(notificationSettings$ | async).showUnreadOnly"
                (change)="unreadOnlyChange($event)"
                color="primary"
                class="notification-center__unread-only-toggle"
                >Unread only</mat-slide-toggle
            >
        </div>
        <section class="notification-center__tabs-wrapper">
            <div class="notification-center__tabs">
                <p
                    class="notification-center__tab"
                    (click)="setNotificationFilter('all')"
                    [ngClass]="{ 'notification-center__tab--active': !notificationCenter.errorsOnly }"
                >
                    All
                </p>
                <p
                    class="notification-center__tab"
                    (click)="setNotificationFilter('errors')"
                    [ngClass]="{ 'notification-center__tab--active': notificationCenter.errorsOnly }"
                    [style.margin-left.px]="10"
                >
                    Errors only
                </p>
                <div class="spacer"></div>
                <p *ngIf="showMarkAllAsRead | async" class="notification-center__tab" (click)="markAllAsRead()">Mark all as read</p>
            </div>
            <mat-divider></mat-divider>
        </section>
        <section class="notification-center__notifications">
            <ng-container *ngIf="notifications$ | async as notifications">
                <sphere-notification-center-item
                    matRipple
                    *ngFor="let notification of notifications"
                    [notification]="notification"
                    class="notification-center__notification-item"
                    (click)="notificationClicked(notification)"
                    (setReadStatus)="notificationReadStatusChange(notification, $event)"
                >
                </sphere-notification-center-item>
                <div *ngIf="!notifications || notifications.length === 0" class="notification-center__no-notifications">
                    <mat-icon class="notification-center__no-notifications-icon">inbox</mat-icon>
                    <p class="notification-center__no-notifications-text text-subtle">No notifications</p>
                </div>
            </ng-container>
        </section>
    </div>
</ng-container>
