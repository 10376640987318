import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, inject, Injectable, Injector } from '@angular/core';
import { AppSwitcherComponent } from '@app/portal/app-switcher/app-switcher.component';

export class AppSwitcherRef {
    constructor(private overlayRef: OverlayRef) {}

    close(): void {
        this.overlayRef.dispose();
    }
}

@Injectable({
    providedIn: 'root'
})
export class AppSwitcherService {
    public appSwitcher: AppSwitcherComponent;
    public appSwitcherRef: AppSwitcherRef;

    private readonly overlay = inject(Overlay);
    private readonly injector = inject(Injector);

    openAppSwitcher() {
        const overlayRef = this.createAppSwitcherOverlay();
        this.appSwitcherRef = new AppSwitcherRef(overlayRef);
        this.appSwitcher = this.attachAppSwitcher(overlayRef, this.appSwitcherRef);
        overlayRef.backdropClick().subscribe(() => {
            this.appSwitcher.closeSelf();
            // this.closeAppSwitcher();
        });
    }
    closeAppSwitcher() {
        this.appSwitcherRef?.close();
        this.appSwitcher = null;
        this.appSwitcherRef = null;
    }

    toggleAppSwitcher() {
        if (!this.appSwitcher) {
            this.openAppSwitcher();
        } else {
            this.closeAppSwitcher();
        }
    }

    private createAppSwitcherOverlay() {
        const overlayConfig = this.getAppSwitcherOverlayConfig();
        return this.overlay.create(overlayConfig);
    }
    private attachAppSwitcher(overlayRef: OverlayRef, appSwitcherRef: AppSwitcherRef) {
        const injector = this.createAppSwitcherInjector(appSwitcherRef);
        const containerPostal = new ComponentPortal(AppSwitcherComponent, null, injector);
        const containerRef: ComponentRef<AppSwitcherComponent> = overlayRef.attach(containerPostal);
        return containerRef.instance;
    }

    private createAppSwitcherInjector(appSwitcherRef: AppSwitcherRef): Injector {
        return Injector.create({
            parent: this.injector,
            providers: [{ provide: AppSwitcherRef, useValue: appSwitcherRef }]
        });
    }

    private getAppSwitcherOverlayConfig(): OverlayConfig {
        const positionStrategy = this.overlay.position().global().left('3px').top('3px');
        return new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            positionStrategy
        });
    }
}
