import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { indexOf } from 'lodash';
import { EventsState } from '@app/portal/notifications/+state/events/events.reducer';
import { Store } from '@ngrx/store';
import { SphereNotification } from '@app/portal/notifications/+state/notifications/notification.reducer';
import { UINotificationActions } from '@app/portal/notifications/+state/notifications/notification.actions';

@Injectable()
export class ServicesUnavailableInterceptor implements HttpInterceptor {
    private statusCodes = [500, 502, 503, 504];

    constructor(private router: Router, private store: Store<EventsState>) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    if (indexOf(this.statusCodes, error.status) >= 0) {
                        if (error.status !== 503) {
                            const errorNotification: SphereNotification = {
                                id: Date.now().toString(),
                                title: `Server error`,
                                content: `Service call has encountered an issue. Please try again later.`,
                                context: 'error',
                                clientNotification: true,
                                timestamp: new Date(),
                                new: true,
                                read: false,
                                app: 'sphere',
                                visible: false
                            };
                            this.store.dispatch(UINotificationActions.createNotification({ notification: errorNotification }));
                        } else {
                            console.debug(`Service response returned a 503 status error.`);
                        }
                    }
                }
                return throwError(error);
            })
        );
    }
}
