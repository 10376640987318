import { GlobalNotificationHandlerDefinition } from '@app/portal/notifications/global-handlers/global-handler';
import { WebSocketEventEntityType, WebSocketEventOperation } from '@app/portal/notifications/ws-event.service';

export const notificationAllHandler: GlobalNotificationHandlerDefinition<undefined> = {
    entity: WebSocketEventEntityType.all,
    operation: WebSocketEventOperation.all,
    isCustomNotification: false,
    notificationBuilder: event => {
        const { operation, ['display-data']: displayData, ['entity-type']: entityType, user, company, reference } = event;
        return {
            id: event.id,
            title: displayData?.title || `${entityType} ${operation}`,
            content: displayData?.message || `${entityType} ${reference} has been ${operation} by ${user} (at company ${company})`,
            context: 'info'
        };
    }
};
