import { Component } from '@angular/core';
import { NotificationsState, selectLastNVisibleNotifications } from '@app/portal/notifications/+state/notifications/notification.reducer';
import { Store } from '@ngrx/store';
import { fadeInAnimation } from '@shared/animations/fade-in-out';
import { selectNotificationUserSettings } from '@app/portal/settings/+state/settings.selectors';
import { map } from 'rxjs/operators';
import { SphereNotificationUserSettingKey } from '@app/portal/settings/+state/settings.models';

@Component({
    selector: `sphere-notification-wrapper`,
    template: `
        <div class="notification-container">
            <ng-container *ngIf="!(dnd$ | async)">
                <ng-container *ngFor="let notification of notifications$ | async">
                    <sphere-notification [@fadeIn] [style.margin-bottom.px]="10" [notification]="notification"></sphere-notification>
                </ng-container>
            </ng-container>
        </div>
    `,
    styles: [
        `
            .notification-container {
            }
        `
    ],
    animations: [fadeInAnimation],
    standalone: false
})
export class NotificationContainerComponent {
    public notifications$ = this.store.select(selectLastNVisibleNotifications(3));
    public dnd$ = this.store.select(selectNotificationUserSettings).pipe(map(settings => settings[SphereNotificationUserSettingKey.dnd]));

    constructor(private store: Store<NotificationsState>) {}
}
