import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { effect, inject, Injectable, signal } from '@angular/core';
import { AppConfigService } from '@app/service/app-config.service';
import { environment } from '@env/environment';
import { explicitEffect } from 'ngxtension/explicit-effect';
import { Observable } from 'rxjs';

@Injectable()
export class XAppInterceptor implements HttpInterceptor {
    private readonly config = inject(AppConfigService);
    public readonly xAppHeaderVersion = signal<string>(null);
    public includedUrls = signal<string[]>([]);

    constructor() {
        this.xAppHeaderVersion.set(`sphere-web:${environment.build?.version}`);
        explicitEffect([this.config.ready], ([configReady]) => {
            if (configReady) {
                this.includedUrls.set(['/api', this.config.apiGatewayUrl + 'api', this.config.apiGatewayUrl + '/api']);
            }
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let nextReq: HttpRequest<any> = req;
        if (this.xAppHeaderVersion() && this.includeUrl(req.url)) {
            nextReq = req.clone({
                setHeaders: {
                    'X-App': this.xAppHeaderVersion()
                }
            });
        }

        return next.handle(nextReq);
    }

    private includeUrl(url: string): boolean {
        return this.includedUrls().some(includedUrl => url.startsWith(includedUrl));
    }
}
