import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SymbolActions } from '@app/+shared-state/symbol/symbol.actions';
import { selectSymbolFieldsForSymbol, selectSymbolInformationForSymbol } from '@app/+shared-state/symbol/symbol.selectors';
import { inferSymbolSource } from '@app/data-management/symbols';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ProductService, SymbolInformationService } from '@shared/api/data-management/generated';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class SymbolEffects {
    private actions$ = inject(Actions);
    private store = inject(Store);
    private products = inject(ProductService);
    private symbolInformation = inject(SymbolInformationService);

    loadSymbolFields$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SymbolActions.fetchSymbolFields),
            concatLatestFrom(({ symbol }) => this.store.select(selectSymbolFieldsForSymbol(symbol))),
            filter(([{ force }, symbolFields]) => force || !symbolFields?.loaded || symbolFields?.loadingError),
            mergeMap(([{ symbol }]) => {
                const source = inferSymbolSource(symbol);
                return this.products.getFields({ source, productCode: symbol }).pipe(
                    map(symbolFields => SymbolActions.fetchSymbolFieldsSuccess({ symbol, fields: symbolFields })),
                    catchError((error: HttpErrorResponse) => {
                        return of(SymbolActions.fetchSymbolFieldsError({ symbol, error }));
                    })
                );
            })
        )
    );

    loadSymbolInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SymbolActions.fetchSymbolInformation),
            concatLatestFrom(({ symbol }) => this.store.select(selectSymbolInformationForSymbol(symbol))),
            filter(([{ force }, symbolInformation]) => force || !symbolInformation),
            mergeMap(([{ symbol }]) => {
                return this.symbolInformation.getSymbolInformation({ requestBody: [symbol] }).pipe(
                    map(([symbolInformation]) => SymbolActions.fetchSymbolInformationSuccess({ symbol, symbolInformation })),
                    catchError((error: HttpErrorResponse) => {
                        return of(SymbolActions.fetchSymbolInformationError({ symbol, error }));
                    })
                );
            })
        )
    );
}
