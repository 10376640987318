import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router, UrlSegment } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { RouterStateService } from '@app/service/router-state.service';

@Component({
    selector: 'sphere-breadcrumbs-v2',
    templateUrl: './breadcrumbs-v2.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    standalone: false
})
export class BreadcrumbsV2Component implements OnInit, OnDestroy {
    private ROOT_SEGMENT = new UrlSegment('/', {});
    private eventsSubscription: Subscription;
    routes: ActivatedRoute[];
    urlSegments: UrlSegment[];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private routerState: RouterStateService
    ) {}

    ngOnInit() {
        this.eventsSubscription = this.router.events
            .pipe(
                filter(next => next instanceof NavigationEnd),
                map(() => this.activatedRoute)
            )
            .subscribe(route => {
                this.routes = []; // Ignore root route with path ''
                while (route.firstChild) {
                    route = route.firstChild;
                    this.routes.push(route);
                }
                const urlTree = this.router.parseUrl(this.router.url);
                this.urlSegments = [this.ROOT_SEGMENT, ...urlTree.root.children[PRIMARY_OUTLET].segments];
            });
    }

    /* istanbul ignore next */
    ngOnDestroy() {
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
        }
    }

    hideSegment = (index: number): boolean => {
        const route = this.routes[index];
        return route && route.snapshot.data['hideBreadcrumb'] === true;
    };

    getCommand = (index: number): string[] => {
        const route = this.routes[index];
        // If disable breadcrumb is set to true, return empty array, hence no router navigation will be performed.
        if (route?.snapshot?.data?.['disableBreadcrumbs']) {
            return [];
        }

        const segments = this.urlSegments.slice(0, index + 1);
        return segments.map(s => s.path);
    };

    getLabel = (index: number): string => {
        const route = this.routes[index];
        if (route && route.snapshot.data['title']) {
            return route.snapshot.data['title'];
        } else if (route) {
            return route.snapshot.url[0].path.charAt(0).toUpperCase() + route.snapshot.url[0].path.slice(1);
        } else {
            const lastIndex = this.routes.length - 1;
            const effectiveIndex = index - lastIndex;
            const lastIndexSnapshot = this.routes[lastIndex].snapshot;
            return lastIndexSnapshot.data['source']?.toUpperCase() ?? lastIndexSnapshot.url[effectiveIndex].path;
        }
    };

    public back(): void {
        this.routerState.back();
    }
}
