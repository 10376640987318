import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CoreDirectivesModule } from '@shared/directives/core-directives.module';
import { NavigationModule } from '../navigation.module';
import { SidebarNavComponent } from './sidebar-nav.component';
import { FunctionWrapperModule } from '@shared/pipes/function-wrapper/function-wrapper.module';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ExperimentalFeatureModule } from '@app/portal/experimental-features/experimental-feature.module';

@NgModule({
    imports: [
        /** Angular Modules **/
        CommonModule,
        RouterModule,

        /** DG Modules **/
        NavigationModule,

        /** Material Component Modules **/
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatTooltipModule,
        FunctionWrapperModule,
        LetDirective,
        ExperimentalFeatureModule,
        CoreDirectivesModule,
        PushPipe
    ],
    declarations: [SidebarNavComponent],
    exports: [SidebarNavComponent]
})
export class SidebarNavModule {}
