<div data-pendo="account">
    <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Logged in as {{ username }}">
        <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu" [overlapTrigger]="false" xPosition="before">
        <button mat-menu-item *ngIf="false">
            <mat-icon>account_circle</mat-icon>
            <span>Profile</span>
        </button>
        <a mat-menu-item (click)="openAbout()" data-cy="account-info">
            <mat-icon>info</mat-icon>
            <span>About</span>
        </a>
        <a mat-menu-item (click)="openGlobalSettings()" data-cy="portal-settings">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
        </a>
        <a *ngIf="state.isEnverusAuthentication()" mat-menu-item (click)="resetPassword()" data-cy="reset-password-settings">
            <mat-icon>vpn_key</mat-icon>
            <span>Reset password</span>
        </a>
        <a mat-menu-item [routerLink]="['privacy-policy']" *ngIf="false">
            <mat-icon>security</mat-icon>
            <span>Privacy Policy</span>
        </a>
        <button mat-menu-item (click)="logout()" data-cy="logout">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
        </button>
    </mat-menu>
</div>
