import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanySettingsActions } from '@app/portal/company-settings/+state/company-settings.actions';
import { TimeSeriesNotation, timeSeriesNotationGroup, timeSeriesNotationKey } from '@app/portal/company-settings/well-known-company-setting-ids';
import * as SettingActions from '@app/portal/settings/+state/settings.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CompanySettingsService, Setting } from '@shared/settings/api/generated';
import { mergeMap, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

@Injectable()
export class CompanySettingsEffects {
    loadCompanySetting$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanySettingsActions.loadCompanySetting),
            mergeMap(({ group, key }) => {
                return this.companySettings.get1({ group, key }).pipe(
                    map(setting => CompanySettingsActions.loadCompanySettingSuccess({ group, key, setting })),
                    catchError((error: HttpErrorResponse) => of(CompanySettingsActions.loadCompanySettingError({ group, key, error })))
                );
            })
        )
    );

    updateCompanySetting$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanySettingsActions.setCompanySetting),
            mergeMap(({ group, key, value }) => {
                const setting: Setting = { group, key, value };
                return this.companySettings.update1({ group, key, setting }).pipe(
                    map(_ => CompanySettingsActions.setCompanySettingSuccess({ group, key, value })),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 404) {
                            return this.companySettings.insert({ setting }).pipe(
                                map(_ => CompanySettingsActions.setCompanySettingSuccess({ group, key, value })),
                                catchError((error: HttpErrorResponse) => of(SettingActions.updateSettingFailure({ error: error.message })))
                            );
                        }
                        return of(CompanySettingsActions.setCompanySettingError({ group, key, value, error }));
                    })
                );
            })
        )
    );

    loadTimeSeriesNotationErrorHandler$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanySettingsActions.loadCompanySettingError),
            filter(({ group, key, error }) => group === timeSeriesNotationGroup && key === timeSeriesNotationKey),
            map(({ group, key }) =>
                CompanySettingsActions.loadCompanySettingSuccess({ group, key, setting: { key, group, value: TimeSeriesNotation.MV as any } })
            )
        )
    );
    constructor(
        private actions$: Actions,
        private companySettings: CompanySettingsService
    ) {}
}
