import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SystemService } from '@shared/settings/api/generated';

import { SystemSettingsActions } from '@app/portal/system-settings/+state/system-settings.actions';

@Injectable()
export class SystemSettingsEffects {
    loadTimezones$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SystemSettingsActions.loadTimezones),
            mergeMap(() => {
                return this.systemSettings.getAll1().pipe(
                    map(timezones => SystemSettingsActions.loadTimezonesSuccess({ timezones })),
                    catchError((error: HttpErrorResponse) => of(SystemSettingsActions.loadTimezonesError({ error })))
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private systemSettings: SystemService
    ) {}
}
