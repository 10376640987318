import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ISearchType, SearchService } from '@app/shared/services/search.service';

@Component({
    selector: 'sphere-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    searchTypes: ISearchType[] = [];
    searchText: string;

    constructor(private searchService: SearchService) {}

    ngOnInit() {
        this.subscription = this.searchService.getSearchTypes().subscribe(n => this.searchTypes.push(n));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    search(searchText: string) {
        console.debug(`SearchResultComponent: Searching for ${this.searchText}`);
        this.searchText = searchText;
    }
}
