import { ModuleWithProviders, NgModule } from '@angular/core';
import { CoreServicesModule } from '@shared/services/core-services.module';
import { CalendarService } from '@shared/api/calendar-service/api/generated';

@NgModule({
    declarations: [],
    exports: []
})
export class SharedApiServicesModule {
    public static forRoot(): ModuleWithProviders<CoreServicesModule> {
        return {
            ngModule: SharedApiServicesModule,
            providers: [CalendarService] // Add shared services here
        };
    }
}
