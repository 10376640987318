import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from './error.service';
import { AppInsightsService } from './app-insights.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    constructor(private injector: Injector) {
        super();
    }

    handleError(error: Error | HttpErrorResponse) {
        const errorService = this.injector.get(ErrorService);
        const appInsightsService = this.injector.get(AppInsightsService);

        let message: string;

        if (error instanceof HttpErrorResponse) {
            // Server error
            message = errorService.getServerErrorMessage(error);
        } else {
            // Client Error
            message = errorService.getClientErrorMessage(error);
        }
        super.handleError(error);
        // Send error to AI
        appInsightsService.logException(error, message);
    }
}
