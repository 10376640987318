import { Component, inject, input, OnInit, output, signal } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Route } from '@angular/router';
import { AppRoute } from '@app/app-routing.module';
import { AuthorizationService } from '@app/security/authorization.service';
import { NavigationService } from '@app/portal/navigation/navigation.service';
import WorkspaceEnrichedAppRoute from '@app/workspaces/WorkspaceEnrichedAppRoute';
import { fadeInOutAnimation } from '@shared/animations/fade-in-out';
import { slideInOutHorizontalAnimation } from '@shared/animations/slide-in-out-horizontal.animation';
import { slideInOutVerticalAnimation } from '@shared/animations/slide-in-out-vertical.animation';

@Component({
    selector: 'sphere-app-v1',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [slideInOutVerticalAnimation, slideInOutHorizontalAnimation, fadeInOutAnimation],
    standalone: false
})
export class AppComponent {
    private sanitizer = inject(DomSanitizer);
    private navigationService = inject(NavigationService);
    private authorization = inject(AuthorizationService);

    public readonly isShell = input<boolean>(true);
    public readonly currentApp = input<Route>(null);
    public readonly noPaddingRoute = input<boolean>(false);
    public readonly collapsedSidenav = input<boolean>(true);
    public readonly routerNavigationLoading = input<boolean>(true);
    public readonly toggleSidenavState = output<void>();

    readonly getAllApps = this.authorization.getAccessibleSphereApps([WorkspaceEnrichedAppRoute, ...this.navigationService.apps]);

    _toggleSidenavState() {
        this.toggleSidenavState.emit();
    }

    getLogo = (logoUri: string): SafeStyle => {
        return this.sanitizer.bypassSecurityTrustStyle(`url("${logoUri}")`);
    };
}
