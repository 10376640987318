<mat-toolbar class="AppToolbar sphere-density__compact">
    <sphere-app-switcher-button [style.height.px]="28" />
    <div class="AppToolbarLogo">
        <a routerLink="/" matTooltip="Home" matTooltipPosition="right">
            <div class="AppToolbarLogoIcon"></div>
        </a>
        <h2 class="AppToolbarTitle">SPHERE</h2>
    </div>

    @if (!isShell()) {
        <div class="dot" [style.margin-left.px]="10" [style.margin-right.px]="10"></div>
        <div class="AppToolbarModule">
            <p class="AppToolbarModuleTitle">{{ currentApp()?.data?.title }}</p>
        </div>
    }

    <div class="app_main-content_topbar_left">
        <sphere-breadcrumbs-v2 />
    </div>
    <div class="spacer"></div>
    <sphere-ws-status [onlyErrors]="true"></sphere-ws-status>
    <shell-environment-indicator />
    <mat-divider vertical [style.height.%]="80" [style.margin-left.px]="15" />
    <div class="AppToolbar__Right app_main-content_topbar_right">
        <div class="app__help-center-toggle" matTooltip="Help center" matTooltipPosition="below" [style.height.px]="28">
            <sphere-help-center-button />
        </div>
        <div class="app__notification-center-toggle" matTooltip="Notification center" matTooltipPosition="below" [style.height.px]="28">
            <sphere-notification-center-toggle></sphere-notification-center-toggle>
        </div>
        <sphere-greeting [style.height.px]="28" />
    </div>
</mat-toolbar>

<div class="AppContainer">
    @if (isShell()) {
        <ng-container [ngTemplateOutlet]="appContent"></ng-container>
    } @else {
        <as-split
            [useTransition]="true"
            direction="horizontal"
            [gutterSize]="5"
            unit="pixel"
            (gutterClick)="_toggleSidenavState()"
            (dragEnd)="saveSidenavState($event.sizes)"
            (transitionEnd)="saveSidenavState($event)"
        >
            <as-split-area
                class="AppSidenav__SplitArea"
                [size]="sidenavSize()"
                [minSize]="MINIMUM_SIDENAV_WIDTH_PX"
                [maxSize]="MAXIMUM_SIDENAV_WIDTH_PX"
            >
                <aside class="AppSidenav" [ngClass]="{ 'AppSidenav--Collapsed': collapsedSidenav() }">
                    <sphere-sidebar-nav-v2
                        class="app--sidebar-inner--right--navigation"
                        [collapsedSidenav]="collapsedSidenav()"
                    ></sphere-sidebar-nav-v2>
                </aside>
                <button
                    class="AppSidenavCollapseExpandButton"
                    mat-mini-fab
                    (click)="_toggleSidenavState()"
                    matTooltipPosition="above"
                    data-pendo="compact side nav"
                    [matTooltip]="collapsedSidenavButtonState() ? 'Expand side navigation' : 'Compact side navigation'"
                >
                    @if (collapsedSidenavButtonState()) {
                        <mat-icon data-cy="expand">chevron_right</mat-icon>
                    } @else {
                        <mat-icon data-cy="collapse">chevron_left</mat-icon>
                    }
                </button>
            </as-split-area>
            <as-split-area size="*">
                <ng-container [ngTemplateOutlet]="appContent"></ng-container>
            </as-split-area>
        </as-split>
    }
</div>

<ng-template #appContent>
    <main class="AppContent">
        @if (routerNavigationLoading()) {
            <div [@fadeInOut] class="app__router-navigation-spinner">
                <sphere-full-page-loading-placeholder />
            </div>
        }
        <!-- START: Main content / router outlet -->
        <div class="AppMainRouterOutlet app__router-outlet-wrapper" [ngClass]="{ 'app__router-outlet-wrapper--no-padding': noPaddingRoute() }">
            <router-outlet></router-outlet>
        </div>
    </main>
</ng-template>
