@if (isShell()) {
    <div
        class="sphere-density__comfortable navigation__wrapper portal--menu"
        [ngClass]="{ 'navigation--nav-list--collapsed': collapsedSidenav }"
        data-pendo="apps"
        [@slideInOut]
    >
        @if (!collapsedSidenav) {
            <div class="navigation__logo">
                <img src="assets/logos/EnverusLogo_white_green_RGB.png" />
                <h2 class="navigation__title">SPHERE</h2>
            </div>
        }

        <mat-nav-list class="navigation__nav-list">
            <a
                mat-list-item
                [routerLink]="['/', 'home']"
                routerLinkActive="active-nav"
                [matTooltip]="collapsedSidenav ? 'Home page' : null"
                matTooltipPosition="right"
            >
                <mat-icon matListItemIcon>dashboard</mat-icon>
                <h4 matLine>Home</h4>
            </a>

            @if (apps()?.length > 0) {
                @for (next of apps(); track next.path) {
                    <a
                        mat-list-item
                        [routerLink]="[next?.path]"
                        routerLinkActive="active-nav"
                        [matTooltip]="collapsedSidenav ? next.data['name'] : null"
                        matTooltipPosition="right"
                        [ngClass]="{ 'app--disabled': !hasAccess(next) }"
                    >
                        <div matListItemIcon style="background-size: cover" [style.background-image]="next.data['logoSvg'] | wrapFn: getLogo"></div>
                        <h4 matLine>{{ next.data['name'] }}</h4>
                    </a>
                }
            }
        </mat-nav-list>
    </div>
} @else {
    <div
        class="sphere-density__comfortable navigation__wrapper app--menu"
        [ngClass]="{ 'navigation--nav-list--collapsed': collapsedSidenav }"
        [@slideInOutB]
    >
        <mat-grid-list cols="10" rowHeight="40" gutterSize="0" [style.margin-bottom.px]="7" *ngIf="!collapsedSidenav">
            <mat-grid-tile [colspan]="1" [matTooltip]="collapsedSidenav ? 'App selection' : null" matTooltipPosition="right"> </mat-grid-tile>
            @if (!collapsedSidenav) {
                <mat-grid-tile colspan="9">
                    <h2 [style.width.%]="100" [style.height.%]="100" [style.margin.px]="0" [style.padding.px]="0" [style.line-height.px]="37">
                        {{ currentAppName() }}
                    </h2>
                </mat-grid-tile>
            }
        </mat-grid-list>

        <mat-nav-list class="navigation__nav-list" *ngrxLet="state.appRoutes() | wrapFn: getVisibleRoutes; let routes">
            @for (route of routes; track route.path) {
                <a
                    *ngIf="isFeatureEnabled(route)"
                    [ngClass]="{ 'route-item__no-access': !hasAccess(route) }"
                    mat-list-item
                    class="route-item"
                    [routerLink]="[state.app().path, route.path]"
                    [skipNavigation]="rla.isActive && route.data?.skipSameUrlNavigation"
                    routerLinkActive="active-nav"
                    #rla="routerLinkActive"
                    [matTooltip]="collapsedSidenav ? route.data.title : null"
                    matTooltipPosition="right"
                    [attr.data-cy]="route.data?.cy || 'sidebar-nav-menu-item'"
                    [attr.data-pendo]="route.data.pendoId ? route.data.pendoId : null"
                >
                    <mat-icon matListItemIcon>{{ route.data.matIcon }}</mat-icon>

                    <h4 matLine>
                        {{ route.data.title || (route | wrapFn: navigationService.getRouteDisplayName) }}
                        @if (route.data.beta || route.data.alpha) {
                            <sphere-experimental-tag
                                [featureStage]="route.data.beta ? 'beta' : route.data.alpha ? 'alpha' : undefined"
                            ></sphere-experimental-tag>
                        }
                    </h4>
                    @if (route.children && (route.children | wrapFn: getVisibleRoutes)?.length > 0) {
                        <button class="dense-compact" (click)="toggle($event, $index)" mat-icon-button [style.opacity]="0.5">
                            @if (expandableState[$index]) {
                                <mat-icon>expand_more</mat-icon>
                            } @else {
                                <mat-icon>chevron_left</mat-icon>
                            }
                        </button>
                    }
                </a>
            }
        </mat-nav-list>
    </div>
}
