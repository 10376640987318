import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppConfigService } from '@app/service/app-config.service';
import { patchState, signalState } from '@ngrx/signals';

export interface AboutDialogData {
    platformVersion: string;
    appVersion: string;
    build: string;
    buildDate?: Date;
}
@Component({
    selector: 'sphere-about',
    templateUrl: './about-dialog.component.html',
    styleUrls: ['./about-dialog.component.scss'],
    standalone: false
})
export class AboutDialogComponent implements OnInit {
    public readonly state = signalState<AboutDialogData | null>(null);

    constructor(
        private dialogRef: MatDialogRef<AboutDialogComponent>,
        private config: AppConfigService
    ) {}

    public ngOnInit(): void {
        this.init();
    }

    public get currentYear(): number {
        return new Date().getFullYear();
    }

    public close(): void {
        this.dialogRef.close();
    }

    private init(): void {
        patchState(this.state, {
            platformVersion: this.config.platformVersion,
            appVersion: this.config.appVersion,
            build: this.config.build,
            buildDate: this.config.buildDate
        });
    }
}
