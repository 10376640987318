import { HttpClient } from '@angular/common/http';
import { EnvironmentProviders, inject, NgModule, provideAppInitializer } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CurveSymbolEffects } from '@app/+shared-state/curve-symbol/curve-symbol.effects';
import { curveSymbolFeature } from '@app/+shared-state/curve-symbol/curve-symbol.reducer';
import { SymbolEffects } from '@app/+shared-state/symbol/symbol.effects';
import { symbolFeature } from '@app/+shared-state/symbol/symbol.reducer';
import { UserPrincipalEffects } from '@app/+shared-state/user-principal/user-principal.effects';
import { userPrincipalFeature } from '@app/+shared-state/user-principal/user-principal.reducer';
import { AnalyticsService } from '@app/analytics/analytics.service';
import { APP_ROUTES, AppRoutingModule } from '@app/app-routing.module';
import { AppV2Component } from '@app/app-v2.component';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core.module';
import { StatefulPageModule } from '@app/ngrx/page-ui-state/stateful-page.module';
import 'ag-grid-enterprise';
import { AppSwitcherButtonComponent } from '@app/portal/app-switcher/app-switcher-button.component';
import { EnvironmentIndicatorModule } from '@app/portal/environment-indicator/environment-indicator.module';
import { CompanySettingsModule } from '@app/portal/company-settings/company-settings.module';
import { HelpCenterButtonModule } from '@app/portal/help-center-button/help-center-button.module';
import { SphereRootContainer } from '@app/sphere-root.container';
import { CurveBuilderSharedStoreModule } from '@curve-builder/curve-builder-shared-store.module';
import { MtxNativeDatetimeModule } from '@ng-matero/extensions/core';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { NotificationSubscriptionsModule } from '@app/portal/notification-subscriptions/notification-subscriptions.module';
import { NotificationModule } from '@app/portal/notifications/notification.module';
import { SettingsFullscreenDialogModule } from '@app/portal/settings/settings-fullscreen-dialog/settings-fullscreen-dialog.module';
import { metaReducers } from '@app/reducers/meta-reducers';
import { SecurityModule } from '@app/security/security.module';
import { AppConfigService, AppConfiguration } from '@app/service/app-config.service';
import { entityMetadata } from '@app/user-manager/shared/api/entity-definitions';
import { CompanyDataService } from '@app/user-manager/shared/api/services/company-data.service';
import { PolicyDataService } from '@app/user-manager/shared/api/services/policy-data.service';
import { RoleDataService } from '@app/user-manager/shared/api/services/role-data.service';
import { UserDataService } from '@app/user-manager/shared/api/services/user-data.service';
import { CurveBrowserDialogModule } from '@curve-builder/shared/curve-browser-dialog/curve-browser-dialog.module';

import { environment } from '@env/environment';
import { PushPipe } from '@ngrx/component';
import { EntityDataModule, EntityDataService, EntityDefinitionService } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { GryphonAgGridModule } from '@shared/ag-grid/gryphon-ag-grid.module';
import { GlobalApiConfigurationModule } from '@shared/api/global-api-configuration.module';
import { SharedApiServicesModule } from '@shared/api/shared-api-services.module';
import { EnverusLoadingSvgModule } from '@shared/enverus-loading-svg/enverus-loading-svg.module';
import { FolderModule } from '@shared/folders/folder.module';
import { formlyTypes } from '@shared/formly/formly-type-map';
import { SphereFormlyModule } from '@shared/formly/sphere-formly.module';
import { FullPageLoadingPlaceholderModule } from '@shared/full-page-loading-placeholder/full-page-loading-placeholder.module';
import { FunctionWrapperModule } from '@shared/pipes/function-wrapper/function-wrapper.module';
import { PopoverModule } from '@shared/popover/popover.module';
import { CoreServicesModule } from '@shared/services/core-services.module';
import { SphereMonacoModule } from '@shared/sphere-monaco/sphere-monaco.module';
import { SphereMonacoService } from '@shared/sphere-monaco/sphere-monaco.service';

import { LicenseManager } from 'ag-grid-enterprise';
import { AngularSplitModule } from 'angular-split';
import HighchartsMore from 'highcharts/highcharts-more';
import { MonacoEditorModule, NGX_MONACO_EDITOR_CONFIG } from 'ngx-monaco-editor-v2';
import { map, take } from 'rxjs/operators';
import * as Highcharts from 'highcharts/highstock';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import Indicators from 'highcharts/indicators/indicators';
import IndicatorsAll from 'highcharts/indicators/indicators-all';
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import DragPanes from 'highcharts/modules/drag-panes';
import PriceIndicator from 'highcharts/modules/price-indicator';
import StockTools from 'highcharts/modules/stock-tools';
import SolidGauge from 'highcharts/modules/solid-gauge';
import FullScreenModule from 'highcharts/modules/full-screen';
import DarkUnicaTheme from 'highcharts/themes/dark-unica';
import { firstValueFrom } from 'rxjs';
import { AppInsightsService } from './analytics/app-insights.service';
import { AboutModule } from './portal/about/about.module';
import { CookieNoticeModule } from './portal/cookie-notice/cookie-notice.module';
import { DashboardModule } from './portal/dashboard/dashboard.module';
import { BreadcrumbsModule } from './portal/navigation/breadcrumbs/breadcrumbs.module';
import { NavigationModule } from './portal/navigation/navigation.module';
import { SidebarNavModule } from './portal/navigation/sidebar-nav/sidebar-nav.module';
import { PrivacyPolicyComponent } from './portal/privacy-policy/privacy-policy.component';
import { SearchModule } from './portal/search';
import { SecurityUIModule } from './portal/security-ui/security-ui.module';
import * as GlobalUserSettingsActions from './portal/settings/+state/settings.actions';
import { GlobalSettingsModule } from './portal/settings/global-settings.module';
import { KeycloakService } from './security/keycloak/keycloak.service';
import { maxValidationMessage, minValidationMessage } from './workflow/components/job-configuration/job-input-form/job-input-form.service';
import { SystemSettingsModule } from '@app/portal/system-settings/system-settings.module';
import { timeSeriesFeature } from '@app/data-management/time-series/+state/time-series.reducer';
import { TimeSeriesEffects } from '@app/data-management/time-series/+state/time-series.effects';
import { ContextSnackbarModule } from './shared/context-snackbar/context-snackbar.module';
import { webSocketSymbolsFeatureKey, webSocketSymbolsReducer } from '@app/+shared-state/real-time-data/ws-symbol.reducer';
import { WsSymbolEffects } from '@app/+shared-state/real-time-data/ws-symbol.effects';

export const HIGHCHARTS_MODULES = [Indicators, IndicatorsAll, DragPanes, AnnotationsAdvanced, PriceIndicator, StockTools, FullScreenModule];

LicenseManager.setLicenseKey(environment.agGrid?.license);

/**
 * Highcharts theme and module loaders.
 */
HighchartsMore(Highcharts); // initialize additional chart types
SolidGauge(Highcharts); // initialize solid gauge module
DarkUnicaTheme(Highcharts); // initialize the theme
NoDataToDisplay(Highcharts); // initialize the module
StockTools(Highcharts); // initialize stock tools module

/* If environment is set to production, DEBUG logging is disabled */
if (environment.production) {
    console.debug = (value: any): void => null;
}

/**
 * Application initializer function that will load configuration and returns a Promise object.
 * @param http is the native Angular HttpClient service.
 * @param appConfigService is the application configuration service that controls global access to app configuration.
 * @param keycloak is the Keycloak service that needs to be initialized after configuration is loaded.
 * @param store is the global NGRX store.
 * @param analytics is the application analytics service.
 */
export function loadAppConfigFn(
    http: HttpClient,
    appConfigService: AppConfigService,
    keycloak: KeycloakService,
    store: Store,
    analytics: AnalyticsService
) {
    return () => {
        return firstValueFrom(
            http.get<AppConfiguration>('assets/app-config.json').pipe(
                take(1),
                map(appConfig => {
                    appConfigService.init(appConfig, APP_ROUTES);
                    return keycloak.init().then(authEvent => {
                        store.dispatch(GlobalUserSettingsActions.loadGlobalUserSettingGroup());
                        store.dispatch(GlobalUserSettingsActions.loadNotificationUserSettingGroup());
                        analytics.initialize({ trackUserLogInEvent: authEvent.userLogIn });
                    });
                })
            )
        );
    };
}

const initializeApp = () => {
    return () => loadAppConfigFn(inject(HttpClient), inject(AppConfigService), inject(KeycloakService), inject(Store), inject(AnalyticsService))();
};

export const provideAppInit = (): EnvironmentProviders => {
    return provideAppInitializer(initializeApp());
};

@NgModule({
    declarations: [SphereRootContainer, AppComponent, AppV2Component, PrivacyPolicyComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SecurityModule,
        AppRoutingModule,

        MatListModule,
        MatGridListModule,
        MatIconModule,
        MatSidenavModule,
        MatTableModule,
        MatTooltipModule,
        MatMenuModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatButtonModule,
        MatDividerModule,
        MatDialogModule,

        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,

        MonacoEditorModule.forRoot(),
        NavigationModule.forRoot(),
        CoreServicesModule.forRoot(),
        SharedApiServicesModule.forRoot(),

        /** Gryphon Modules **/
        AboutModule,
        SidebarNavModule,
        BreadcrumbsModule,
        DashboardModule,
        SearchModule,
        GlobalSettingsModule,
        GryphonAgGridModule,
        PopoverModule,
        CookieNoticeModule,
        ContextSnackbarModule,
        SettingsFullscreenDialogModule,
        StatefulPageModule,
        NotificationModule,
        FolderModule,

        StoreModule.forRoot(
            {
                [timeSeriesFeature.name]: timeSeriesFeature.reducer,
                [webSocketSymbolsFeatureKey]: webSocketSymbolsReducer,
                [symbolFeature.name]: symbolFeature.reducer,
                [curveSymbolFeature.name]: curveSymbolFeature.reducer,
                [userPrincipalFeature.name]: userPrincipalFeature.reducer,
                routerReducer
            },
            {
                metaReducers,
                runtimeChecks: {
                    strictActionImmutability: false,
                    strictActionSerializability: false,
                    strictActionWithinNgZone: false,
                    strictStateImmutability: false,
                    strictStateSerializability: false
                }
            }
        ),

        StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
        EffectsModule.forRoot([TimeSeriesEffects, SymbolEffects, CurveSymbolEffects, WsSymbolEffects, UserPrincipalEffects]),
        EntityDataModule.forRoot({}),

        /** Imported last as to avoid redirecting plugin routes to 404 **/
        CoreModule,
        /** Import after core module so that app config is loaded before oidc config**/
        SecurityUIModule,

        /** ServiceWorker **/
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),

        EnverusLoadingSvgModule,
        ReactiveFormsModule,
        SphereFormlyModule,
        FormlyModule.forRoot({
            extras: { lazyRender: true },
            validationMessages: [
                { name: 'required', message: 'This field is required' },
                { name: 'min', message: minValidationMessage },
                { name: 'max', message: maxValidationMessage }
            ],
            types: formlyTypes
        }),
        FormlyMaterialModule,
        FullPageLoadingPlaceholderModule,
        GlobalApiConfigurationModule,
        CurveBrowserDialogModule,
        NotificationSubscriptionsModule,
        CompanySettingsModule,
        MtxNativeDatetimeModule,
        MtxDatetimepickerModule,
        SystemSettingsModule,
        FunctionWrapperModule,
        PushPipe,
        HelpCenterButtonModule,
        SphereMonacoModule,
        MatToolbarModule,
        EnvironmentIndicatorModule,
        AppSwitcherButtonComponent,
        AngularSplitModule,
        CurveBuilderSharedStoreModule
    ],
    providers: [
        provideAnimationsAsync(),
        AppConfigService,
        provideAppInit(),
        AppInsightsService,
        { provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
        {
            provide: NGX_MONACO_EDITOR_CONFIG,
            useFactory: (sphereMonaco: SphereMonacoService) => {
                return {
                    onMonacoLoad: () => {
                        // This service instance controls the global editor configuration requirements
                        sphereMonaco.init();
                    }
                };
            },
            deps: [SphereMonacoService]
        }
    ],
    bootstrap: [SphereRootContainer]
})
export class AppModule {
    constructor(
        private eds: EntityDefinitionService,
        private entityDataService: EntityDataService,
        private companyDataService: CompanyDataService,
        private userDataService: UserDataService,
        private policyDataService: PolicyDataService,
        private roleDataService: RoleDataService
    ) {
        eds.registerMetadataMap(entityMetadata);
        entityDataService.registerServices({
            ReadCompany: companyDataService,
            ReadUser: userDataService,
            ReadRole: roleDataService,
            ReadPolicy: policyDataService
        });
        /* Default Highcharts configuration */
        Highcharts.setOptions({
            lang: {
                decimalPoint: '.',
                thousandsSep: ','
            },
            chart: {
                style: {
                    fontFamily: 'Roboto'
                }
            },
            credits: {
                enabled: false
            },
            global: {},
            stockTools: {
                gui: {
                    enabled: false
                }
            }
        });
        for (const module of HIGHCHARTS_MODULES) {
            module(Highcharts);
        }
    }
}
