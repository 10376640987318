<div class="breadcrumb__wrapper">
    <button mat-icon-button (click)="back()" [style.position]="'relative'" [style.margin-left.px]="-10" data-cy="portal-navigation_back">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <ng-container *ngFor="let segment of urlSegments; let i = index">
        <span
            *ngIf="!(i | wrapFn: hideSegment)"
            data-cy="breadcrumb-text"
            class="breadcrumb__segment"
            [ngClass]="{ 'breadcrumb__segment--last': i === urlSegments.length - 1 }"
        >
            <a
                class="breadcrumb__link"
                [routerLink]="i | wrapFn: getCommand"
                [ngClass]="{ 'breadcrumb__not-active': i === urlSegments.length - 1 || (i | wrapFn: getCommand)?.length === 0 }"
                >{{ i | wrapFn: getLabel }}</a
            >
            <span class="breadcrumb__divider" *ngIf="i < urlSegments.length - 1">/</span>
        </span>
    </ng-container>
</div>
