import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../service/app-config.service';

@Injectable()
export class DronaHttpInterceptor implements HttpInterceptor {
    private urlRedirectDictionary: Map<string, string>;

    constructor(private config: AppConfigService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let nextReq: HttpRequest<any> = req;

        if (req.url.startsWith('api/') || req.url.startsWith('/api/')) {
            this.initEndpointMap(); // this.urlRedirectDictionary will be undefined when we call this from OnInit

            const tokens = req.url.split('/');
            const path = tokens[0] === '' ? tokens[2] : tokens[1];
            const replacedPath = this.urlRedirectDictionary.get(path) || this.config.apiGatewayUrl?.replace(/\/$/, '');

            if (req.url.startsWith('api/')) {
                nextReq = req.clone({ url: `${replacedPath}/${req.url}` });
            } else if (req.url.startsWith('/api/')) {
                nextReq = req.clone({ url: `${replacedPath}${req.url}` });
            }
        }

        return next.handle(nextReq);
    }

    /**
     * This method expects a string in the following format
     * default=https://url.com,curve=https://curve.com,dhws=https://curve.url
     *  for testing we shall use default= http://localhost:4200
     */
    private initEndpointMap() {
        if (this.urlRedirectDictionary) {
            return this.urlRedirectDictionary;
        }

        console.debug(`Initializing endpoint map from ${this.config.apiServerUrl}`);
        this.urlRedirectDictionary = new Map<string, string>();

        const urlMaps = this.config.apiServerUrl.split(',');
        console.debug(`found ${urlMaps.length} url maps`);
        urlMaps.forEach(m => {
            console.debug(`splitting url map ${m}`);
            const parts = m.split('=');
            if (parts.length === 2) {
                console.debug(`Adding ${parts[0]} with value ${parts[1]}`);
                this.urlRedirectDictionary.set(parts[0], parts[1]);
            } else {
                console.error('Invalid urlMap entry found, should be in format /api/xxx=http://targetUrl/api/xxx');
            }
        });

        if (!this.urlRedirectDictionary.get('default')) {
            throw new Error(
                'apiServerUrl environment key is incorrectly configured, expecting: default=https://url.com,curve=https://curve.com,dhws=https://curve.url'
            );
        }
    }
}
