import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface GrantedAuthority {
    authority: string;
}

@Injectable()
export class PrincipalService {
    constructor(private http: HttpClient) {}

    /**
     * Get a mapped list of user granted authorities is string array format.
     */
    public getFlatUserGrantedAuthorities(): Observable<string[]> {
        return this.http.get<GrantedAuthority[]>(`/api/authorization/v1/principal`).pipe(map(authorities => authorities.map(a => a.authority)));
        //    return this.http.get<GrantedAuthority[]>(`/api/curve/v2/principal`).pipe(map(authorities => authorities.map(a => a.authority)));
    }
}
