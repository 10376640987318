import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { notificationsFeatureKey, reducer } from '@app/portal/notifications/+state/notifications/notification.reducer';
import { NotificationComponent } from '@app/portal/notifications/notification/notification.component';
import { EffectsModule } from '@ngrx/effects';
import { NotificationEffects } from '@app/portal/notifications/+state/notifications/notification.effects';
import { NotificationContainerComponent } from '@app/portal/notifications/notification/notification-container.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NotificationCenterComponent } from '@app/portal/notifications/notification-center/notification-center.component';
import { NotificationCenterToggleComponent } from '@app/portal/notifications/notification-center/notification-center-toggle.component';
import { MatBadgeModule } from '@angular/material/badge';
import { LetDirective, PushPipe } from '@ngrx/component';
import { NotificationCenterItemComponent } from '@app/portal/notifications/notification-center/notification-center-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { EventModule } from '@app/portal/notifications/event.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PulsingIndicatorModule } from '@app/workflow/shared/pulsing-indicator/pulsing-indicator.module';
import { WsStatusComponent } from '@app/portal/notifications/ws-status/ws-status.component';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(notificationsFeatureKey, reducer),
        EffectsModule.forFeature([NotificationEffects]),
        MatIconModule,
        RouterModule,
        MatButtonModule,
        MatBadgeModule,
        LetDirective,
        PushPipe,
        MatTooltipModule,
        MatRippleModule,
        MatDividerModule,
        EventModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        PulsingIndicatorModule
    ],
    declarations: [
        NotificationComponent,
        NotificationContainerComponent,
        NotificationCenterComponent,
        NotificationCenterToggleComponent,
        NotificationCenterItemComponent,
        WsStatusComponent
    ],
    exports: [
        NotificationComponent,
        NotificationContainerComponent,
        NotificationCenterComponent,
        NotificationCenterToggleComponent,
        WsStatusComponent
    ]
})
export class NotificationModule {}
