import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { systemSettingsFeature } from '@app/portal/system-settings/+state/system-settings.reducer';
import { SystemSettingsEffects } from '@app/portal/system-settings/+state/system-settings.effects';

@NgModule({
    imports: [StoreModule.forFeature(systemSettingsFeature), EffectsModule.forFeature([SystemSettingsEffects])],
    declarations: [],
    exports: []
})
export class SystemSettingsModule {}
