import { NgModule } from '@angular/core';
import { CompanySettingsEffects } from '@app/portal/company-settings/+state/company-settings.effects';
import { companySettingsFeature } from '@app/portal/company-settings/+state/company-settings.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
    imports: [StoreModule.forFeature(companySettingsFeature), EffectsModule.forFeature([CompanySettingsEffects])],
    declarations: [],
    exports: []
})
export class CompanySettingsModule {}
