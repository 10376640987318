import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, inject, signal } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent, MatCardHeader } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatListItem, MatListItemIcon, MatListItemMeta, MatListItemTitle, MatNavList } from '@angular/material/list';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { AuthorizationService } from '@app/security/authorization.service';
import { AppSwitcherService } from '@app/portal/app-switcher/app-switcher.service';
import { ExperimentalFeatureService } from '@app/portal/experimental-features/experimental-feature.service';
import { ExperimentalTagComponent } from '@app/portal/experimental-features/experimental-tag.component';
import { NavigationService } from '@app/portal/navigation/navigation.service';
import { trigger, transition, animate, keyframes, style, state } from '@angular/animations';

@Component({
    templateUrl: './app-switcher.component.html',
    styleUrl: './app-switcher.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatCard,
        MatCardContent,
        RouterLink,
        MatNavList,
        MatListItem,
        MatListItemTitle,
        MatListItemIcon,
        MatIcon,
        MatCardHeader,
        MatIconButton,
        MatListItemMeta,
        ExperimentalTagComponent
    ],
    standalone: true,
    animations: [
        trigger('grow', [
            state('visible', style({ opacity: 1, transform: 'scale(1)', transformOrigin: 'top left' })),
            state('hidden', style({ opacity: 0, transform: 'scale(0.1)', transformOrigin: 'top left' })),
            transition('hidden <=> visible', animate('150ms ease-in-out'))
        ])
    ]
})
export class AppSwitcherComponent implements AfterViewInit {
    private authorization = inject(AuthorizationService);
    private experimentalFeatures = inject(ExperimentalFeatureService);
    private nav = inject(NavigationService);
    private sanitizer = inject(DomSanitizer);

    private appSwitcher = inject(AppSwitcherService);

    public readonly apps = this.authorization.getAccessibleSphereApps(this.nav.apps);
    public readonly animationState = signal<'hidden' | 'visible'>('hidden');

    public readonly hasAlphaAccess = this.experimentalFeatures.hasAlphaFeatureAccess;
    public readonly hasBetaAccess = this.experimentalFeatures.hasBetaFeatureAccess;

    ngAfterViewInit() {
        this.animationState.set('visible');
    }

    getLogo(logoUri: string): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(`url("${logoUri}")`);
    }

    closeSelf() {
        this.animationState.set('hidden');
        setTimeout(() => {
            this.appSwitcher.closeAppSwitcher();
        }, 150);
    }
}
