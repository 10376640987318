<p>
    Enverus uses cookies to personalize our services and provide customized information to enhance your experience. By continuing to use the website,
    we understand that you accept their use.
</p>
<div class="cookie-notice-footer">
    <button mat-button color="primary" (click)="accept()" data-cy="accept-cookies">Ok, got it</button>
    <a href="{{ privacyPolicyUrl }}" target="_blank" rel="noreferrer"
        ><button mat-button color="primary">
            Learn more
        </button></a
    >
</div>
