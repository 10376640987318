import { ActionReducer, MetaReducer } from '@ngrx/store';
import { environment } from '@env/environment';

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (!environment.production) {
            console.groupCollapsed(action.type);
            console.debug(action);
            console.debug(state);
            console.groupEnd();
        }

        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<any>[] = [debug];
