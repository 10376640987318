<section class="notification__header">
    <div class="notification__icon" *ngIf="true; else inProgressIcon">
        <mat-icon class="{{ contextClass }}">{{ contextIcon }}</mat-icon>
    </div>
    <div class="notification__title-wrapper">
        <div class="notification__title" [title]="notification.title">{{ notification.title }}</div>
        <div class="notification__timestamp text-subtle">{{ formatTimestamp(notification.timestamp) }}</div>
    </div>
    <div class="notification__close">
        <mat-icon (click)="dismissVisible()">close</mat-icon>
    </div>
</section>
<section class="notification__body">
    <p class="notification__app text-subtle" *ngIf="notification.app">
        <small>{{ notification.app | uppercase }}</small>
    </p>
    <p class="notification__content">{{ notification.content }}</p>
</section>
<section class="notification__actions" *ngIf="notification.url">
    <a mat-button (click)="dismissVisible(); markAsRead()" [routerLink]="notification?.url">OPEN</a>
</section>
<section class="notification__progress" *ngIf="isInProgress">
    <mat-progress-bar mode="indeterminate" [style.height]="'1px !important'" color="accent"></mat-progress-bar>
</section>

<ng-template #inProgressIcon>
    <div class="notification__pulsing-icon">
        <sphere-pulsing-indicator color="accent" animation="pulse" size="medium"></sphere-pulsing-indicator>
    </div>
</ng-template>
