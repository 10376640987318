import { Injectable } from '@angular/core';
import { curveFormulaMonarchTokenizer } from '@curve-builder/curve-advanced-designer/formula-editor/monarch/curve-formula-tokens';
import { completionItemsMap, CurveRuleItems, globalCompletionItems } from '@shared/sphere-monaco/monaco-drools/drools-global-classes';
import { droolsCustomTheme, droolsMonarchTokenizer } from '@shared/sphere-monaco/monaco-drools/drools-tokens';
import { CancellationToken, editor, IRange, languages, Position } from 'monaco-editor';
import IModel = editor.IModel;
import CompletionItem = languages.CompletionItem;

@Injectable()
export class SphereMonacoService {
    public readonly droolsLanguage: languages.ILanguageExtensionPoint = {
        id: 'drools',
        aliases: ['drools', 'java-drools'],
        extensions: ['.java']
    };

    public readonly curveFormulaLanguage: languages.ILanguageExtensionPoint = {
        id: 'curveFormula',
        aliases: ['curve', 'formula', 'curve-formula']
    };

    constructor() {}

    init() {
        this.addDroolsSupport();
        this.addCurveFormulaSupport();
    }

    private addCurveFormulaSupport() {
        (window as any).monaco.languages.register(this.curveFormulaLanguage);
        (window as any).monaco.languages.setMonarchTokensProvider(this.curveFormulaLanguage.id, this.curveFormulaTokenizer);
    }

    private addDroolsSupport() {
        (window as any).monaco.editor.defineTheme('droolsTheme', droolsCustomTheme);
        (window as any).monaco.languages.register(this.droolsLanguage);
        (window as any).monaco.languages.setMonarchTokensProvider(this.droolsLanguage.id, this.droolsTokenizer);
        (window as any).monaco.languages.registerCompletionItemProvider(this.droolsLanguage.id, {
            triggerCharacters: ['.', ' '],
            provideCompletionItems: (
                model: editor.ITextModel,
                position: Position,
                context: languages.CompletionContext,
                token: CancellationToken
            ): languages.ProviderResult<languages.CompletionList> => {
                let _position;
                if (context.triggerCharacter === '.') {
                    _position = {
                        ...position,
                        column: position.column - 1
                    };
                } else {
                    _position = position;
                }
                const wordMatch = model.getWordUntilPosition(_position);
                const word = model.getWordUntilPosition(position);
                const range: IRange = {
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.startColumn,
                    endColumn: word.endColumn
                };
                const suggestions: languages.CompletionItem[] = [];
                if (Object.keys(completionItemsMap).includes(wordMatch.word)) {
                    suggestions.push(...completionItemsMap[wordMatch.word](range));
                } else {
                    suggestions.push(...globalCompletionItems(range));
                }
                if (this.isVariableOfType(model, wordMatch.word, 'ForwardContract')) {
                    return {
                        suggestions: CurveRuleItems(range).ForwardContract.methods as CompletionItem[],
                        incomplete: false
                    };
                }
                return {
                    suggestions,
                    incomplete: false
                };
            }
        });
    }

    private get droolsTokenizer() {
        return droolsMonarchTokenizer;
    }

    private get curveFormulaTokenizer() {
        return curveFormulaMonarchTokenizer;
    }

    private isVariableOfType(model: IModel, variableName: string, variableType: string) {
        return model.getValue().includes(`${variableType} ${variableName}`);
    }
}
