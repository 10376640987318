import { Component, Input, OnChanges, OnDestroy, SimpleChange } from '@angular/core';
import { Subscription } from 'rxjs';
import { ISearchResult, ISearchType } from '@app/shared/services/search.service';

@Component({
    selector: 'sphere-search-type',
    templateUrl: './search-type.component.html',
    styleUrls: ['./search-type.component.scss']
})
export class SearchTypeComponent implements OnDestroy, OnChanges {
    @Input() searchType: ISearchType;
    @Input() searchText: string;
    subscription: Subscription;
    searchResults: ISearchResult[];

    constructor() {}

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.searchResults = [];
        const searchText = changes['searchText'].currentValue;
        if (!changes['searchText'].isFirstChange()) {
            this.subscription = this.searchType.search(searchText).subscribe(r => {
                this.searchResults = r;
            });
        }
    }
}
