import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchResultComponent } from '../search-result/search-result.component';

@Component({
    selector: 'sphere-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent {
    @ViewChild('searchResult', { static: false }) searchResult: SearchResultComponent;
    searchText: string;

    search() {
        console.debug(`SearchFormComponent: Searching for ${this.searchText}`);
        this.searchResult.search(this.searchText);
    }
}
