import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SystemSettingsActions } from '@app/portal/system-settings/+state/system-settings.actions';

@Injectable({
    providedIn: 'root'
})
export class SystemSettingsService {
    constructor(private store: Store) {}

    loadTimezones() {
        this.store.dispatch(SystemSettingsActions.loadTimezones());
    }
}
