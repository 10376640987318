import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SphereNotification } from '@app/portal/notifications/+state/notifications/notification.reducer';
import { NotificationHelperService } from '@app/portal/notifications/notification/notification-helper.service';
import { SphereDateService } from '@app/portal/settings/helpers/sphere-date.service';
import { formatDistanceToNow } from 'date-fns';

@Component({
    selector: 'sphere-notification-center-item',
    templateUrl: './notification-center-item.component.html',
    styleUrls: ['./notification-center-item.component.scss']
})
export class NotificationCenterItemComponent {
    @Input() notification: SphereNotification;
    @Output() setReadStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private helper: NotificationHelperService, private date: SphereDateService) {}

    get fromNow(): string {
        return formatDistanceToNow(this.notification.timestamp, { addSuffix: true, includeSeconds: true });
    }

    get formattedTimestamp(): string {
        return this.date.dateFnsFormat(this.notification.timestamp, 'datetime');
    }

    get contextIcon(): string {
        return this.helper.getContextIcon(this.notification.context);
    }

    get contextClass(): string {
        return this.helper.getContextClass(this.notification.context);
    }

    get isInProgress(): boolean {
        return this.helper.isInProgress(this.notification.context);
    }
}
