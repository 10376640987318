import { Component, computed, inject, OnInit, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { WsSymbolService } from '@app/+shared-state/real-time-data/ws-symbol.service';
import { AuthorizationService } from '@app/security/authorization.service';
import { AppInsightsService } from '@app/analytics/app-insights.service';
import { CompanySettingsService } from '@app/portal/company-settings/company-settings.service';
import { CookieNoticeComponent } from '@app/portal/cookie-notice/cookie-notice.component';
import { NavigationService } from '@app/portal/navigation/navigation.service';
import { NotificationSubscriptionsService } from '@app/portal/notification-subscriptions/notification-subscriptions.service';
import { WsEventService } from '@app/portal/notifications/ws-event.service';
import { ServiceWorkerUpdateService } from '@app/portal/sw-update/sw-update.service';
import { SystemSettingsService } from '@app/portal/system-settings/system-settings.service';
import { patchState, signalState } from '@ngrx/signals';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AgGridThemeService } from '@shared/ag-grid/ag-grid-theme.service';
import { fadeInOutAnimation } from '@shared/animations/fade-in-out';
import { slideInOutHorizontalAnimation } from '@shared/animations/slide-in-out-horizontal.animation';
import { slideInOutVerticalAnimation } from '@shared/animations/slide-in-out-vertical.animation';
import localforage from 'localforage';
import { explicitEffect } from 'ngxtension/explicit-effect';
import { filter, take, tap } from 'rxjs/operators';

export type SphereUIVersion = 'v1' | 'v2';

export type SphereRootShellState = {
    collapsedSidenav: boolean;
    routerNavigationLoading: boolean;
    displayBottomBar: boolean;
    uiVersion: SphereUIVersion;
};

@Component({
    selector: 'sphere-app-root',
    template: `
        @switch (state().uiVersion) {
            @case ('v2') {
                <ng-container [ngTemplateOutlet]="v2" />
            }
            @case ('v1') {
                <ng-container [ngTemplateOutlet]="v1" />
            }
            @default {
                <ng-container [ngTemplateOutlet]="v1" />
            }
        }

        <ng-template #v1>
            <sphere-app-v1
                [isShell]="isShell()"
                [currentApp]="currentApp()"
                [noPaddingRoute]="noPaddingRoute()"
                [collapsedSidenav]="state().collapsedSidenav"
                [routerNavigationLoading]="state().routerNavigationLoading"
                (toggleSidenavState)="toggleSidenavState()"
            />
        </ng-template>
        <ng-template #v2>
            <sphere-app-v2
                [isShell]="isShell()"
                [currentApp]="currentApp()"
                [noPaddingRoute]="noPaddingRoute()"
                [routerNavigationLoading]="state().routerNavigationLoading"
            />
        </ng-template>
    `,
    animations: [slideInOutVerticalAnimation, slideInOutHorizontalAnimation, fadeInOutAnimation],
    standalone: false
})
export class SphereRootContainer implements OnInit {
    private readonly storage = inject(StorageMap);
    private readonly wsRealTime = inject(WsSymbolService);
    public state = signalState<SphereRootShellState>({
        collapsedSidenav: false,
        routerNavigationLoading: false,
        displayBottomBar: true,
        uiVersion: 'v1'
    });

    public currentApp = this.navigationService.currentApp;
    public isShell = this.navigationService.isShell;

    public noPaddingRoute = computed(() => {
        return this.navigationService.activatedRouteData()?.noPadding;
    });

    constructor(
        swUpdate: ServiceWorkerUpdateService,
        wsEvents: WsEventService,
        private navigationService: NavigationService,
        router: Router,
        private appInsightsService: AppInsightsService,
        private bottomSheet: MatBottomSheet,
        private notificationSubscriptions: NotificationSubscriptionsService,
        private companySettings: CompanySettingsService,
        private systemSettings: SystemSettingsService,
        renderer: Renderer2,
        grantedAuthorities: AuthorizationService
    ) {
        wsEvents.init();
        this.wsRealTime.init();
        swUpdate.checkForUpdates();
        router.events
            .pipe(
                tap(event => {
                    // TODO: every time we update URL changes, queryParams too, this is being fired, we should NOT rely on this.
                    // checkRouterEvent()

                    // Due to animation delay, the timeout wraps the check function to it is run on the next cycle
                    // of the VM, this way, the value is not changed before the lifecycle hook can perform the next check
                    // thus avoiding a NG0100: ExpressionChangedAfterItHasBeenCheckedError error.
                    setTimeout(() => {
                        this.checkRouterEvent(event);
                    }, 0);
                })
            )
            .subscribe();
        this.notificationSubscriptions.loadAllSubscriptions();
        this.companySettings.loadAllWellKnownCompanySettings();
        this.systemSettings.loadTimezones();

        this.storage
            .get('portal:collapsedSidenav', { type: 'boolean' })
            .pipe(
                take(1),
                filter(value => value !== undefined)
            )
            .subscribe(collapsedSidenav => {
                patchState(this.state, {
                    collapsedSidenav
                });
            });

        this.storage
            .watch<SphereUIVersion>('portal:sphere-ui-version', { type: 'string', enum: ['v1', 'v2'] })
            .pipe(
                takeUntilDestroyed(),
                filter(value => value !== undefined)
            )
            .subscribe(uiVersion => {
                patchState(this.state, {
                    uiVersion
                });
            });

        this.storage.watch('portal:theme', { type: 'string' }).subscribe(theme => {
            renderer.removeClass(document.body, 'sphere-theme__light');
            renderer.removeClass(document.body, 'sphere-theme__dark');
            let themeClass = `sphere-theme__dark`;
            if (!!theme) {
                themeClass = `sphere-theme__${theme}`;
            }
            renderer.addClass(document.body, themeClass);
        });

        this.storage.watch('portal:theme-density', { type: 'string' }).subscribe(density => {
            renderer.removeClass(document.body, 'sphere-density__compact');
            renderer.removeClass(document.body, 'sphere-density__comfortable');
            let densityClass = `sphere-density__comfortable`;
            if (!!density) {
                densityClass = `sphere-density__${density}`;
            }
            renderer.addClass(document.body, densityClass);
        });

        explicitEffect([this.state.collapsedSidenav], ([collapsedSidenav]) => {
            this.storage.set('portal:collapsedSidenav', collapsedSidenav, { type: 'boolean' }).subscribe();
        });

        this.tableThemeChangeListener();

        grantedAuthorities.init();
    }

    ngOnInit(): void {
        this.checkPrivacyPolicyConsent();
        this.appInsightsService.logEvent('Application Loaded.');
    }

    private checkRouterEvent(routerEvent: Event): void {
        if (routerEvent instanceof NavigationStart) {
            patchState(this.state, {
                routerNavigationLoading: true
            });
        }

        if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
            patchState(this.state, {
                routerNavigationLoading: false
            });
        }
    }

    async checkPrivacyPolicyConsent(): Promise<void> {
        try {
            const value = await localforage.getItem('privacy-policy');
            if (!value) {
                this.bottomSheet.open(CookieNoticeComponent);
            }
        } catch (err) {
            console.debug(`localForage error ${err}`);
        }
    }

    toggleSidenavState() {
        patchState(this.state, state => ({
            collapsedSidenav: !state.collapsedSidenav
        }));
    }

    setSidenavState(collapsedSidenav: boolean) {
        patchState(this.state, {
            collapsedSidenav
        });
    }

    /**
     * Table theme update listener
     */
    private agGridThemes = inject(AgGridThemeService);
    tableThemeChangeListener() {
        this.agGridThemes.initialize();
    }
}
